import React, { useEffect, useReducer, useCallback, Suspense } from 'react';
import { Helmet } from "react-helmet";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import {useParams, useNavigate, useSearchParams} from "react-router-dom";
import { toast } from "react-toastify";

import authenticationStore from "../../../stores/AuthenticationStore.ts";
import CompanyNetwork from "../../../config/Network/CompanyNetwork.ts";
import CgpNetwork from "../../../config/Network/CgpNetwork.ts";

import OwnerChoice from "./Steps/OwnerChoice";
import CompanyForm from "./Steps/CompanyInfos"; // Unified CompanyForm Component
import Knowledge from "../../KycSurvey/Steps/Knowledge";
import CompanyUploads from "./Steps/CompanyUpload";
import CompanyRevenus from "./Steps/CompanyRevenus";
import RepresentativeForm from "./Steps/Representative/index";

import Statement from "../../KycSurvey/Steps/Statements";
import FormLoader from "../../../components/Loaders/KYC";

import OSButton from "../../../components/Button";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import '../../KycSurvey/style.scss';
import ShareholderChoice from "./Steps/ShareHolders/ShareHolderChoice";

const initialState = {
	company: JSON.parse(sessionStorage.getItem('Company')) || {},
	user: {},
	loading: true,
	activeStep: parseInt(sessionStorage.getItem('CompanySurveyStep')) || 0,
	stepName: '',
};

// Reducer Function
const reducer = (state, action) => {
	switch (action.type) {
		case 'SET_COMPANY':
			return { ...state, company: action.payload, loading: false };
		case 'SET_USER':
			return { ...state, user: action.payload };
		case 'SET_LOADING':
			return { ...state, loading: action.payload };
		case 'SET_STEP':
			return {
				...state,
				activeStep: action.payload,
				stepName: action.stepName || state.stepName,
			};
		default:
			return state;
	}
};

// Main Component
const CompanySurvey = ({ editing }) => {
	const { clientId } = useParams();
	const isCgp = window.location.href.includes("cgp");
	const navigate = useNavigate();
	const [searchParams, setSearchParams] = useSearchParams();
	const [state, dispatch] = useReducer(reducer, initialState);
	const localCompany = state.company
	const isProfessional = editing
		? localCompany?.investment_profile === 'professional'
		: searchParams.get('professionalCategory') === 'professional';

	const getSteps = (isProfessional) => {
		let baseSteps = [
			{ id: 0, title: "Informations de l'entreprise" },
			{ id: 1, title: "Représentant légal" },
			{ id: 2, title: "Dirigeants" },
			{ id: 3, title: "Bénéficiaire" },
			{ id: 4, title: "Connaissances et objectifs" },
			{ id: 5, title: "Situation Financière" },
			{ id: 6, title: "Justificatifs de l'entreprise" },
			{ id: 7, title: "Déclarations" },
		];
		
		if (isProfessional) {
			// Supprimer les étapes avec l'ID 3 et 4.
			baseSteps = baseSteps.filter(step => step.id !== 4 && step.id !== 5);
			
			// Réajuster les ID pour chaque étape restante.
			baseSteps = baseSteps.map((step, index) => ({
				...step,
				id: index, // Réattribution des ID séquentiellement.
			}));
		}
		
		return baseSteps;
	};
	const steps = getSteps(isProfessional);
	
	// Fetch Company Data
	const fetchCompanyData = useCallback(async () => {
		dispatch({ type: 'SET_LOADING', payload: true });
		try {
			let response;
			if (state.company?.id) {
				response = state.company;
			} else if (isCgp) {
				response = await CgpNetwork.getClient(clientId, "Company");
			} else {
				response = await CompanyNetwork.getCompany(localCompany?.id || clientId);
				// Fetch user data
				const userResponse = await authenticationStore.loadUserData();
				dispatch({ type: 'SET_USER', payload: userResponse });
			}
			dispatch({ type: 'SET_COMPANY', payload: response });
		} catch (error) {
			toast.error("Une erreur est survenue, veuillez nous contacter");
		} finally {
			dispatch({ type: 'SET_LOADING', payload: false });
		}
	}, [clientId, localCompany]);
	
	// Initialize data
	useEffect(() => {
		if (editing || (!editing && state.activeStep > 0)) {
			fetchCompanyData();
		} else if (!editing && state.activeStep === 0) {
			// Explicitly set loading to false in create mode, step 0
			dispatch({ type: 'SET_LOADING', payload: false });
		}
	}, [state.activeStep, fetchCompanyData]);
	
	
	// Update Step Name
	useEffect(() => {
		const stepTitle = steps[state.activeStep]?.title || '';
		dispatch({ type: 'SET_STEP', payload: state.activeStep, stepName: stepTitle });
		sessionStorage.setItem('CompanySurveyStep', state.activeStep.toString());
	}, [state.activeStep]);
	
	// Handle Step Navigation
	const handleStep = useCallback(
		(forward = true, updatedCompany = null) => {
			if (updatedCompany) {
				dispatch({ type: 'SET_COMPANY', payload: updatedCompany }); // Update state directly
			}
			const newStep = forward ? state.activeStep + 1 : state.activeStep - 1;
			dispatch({ type: 'SET_STEP', payload: newStep });
		},
		[state.activeStep]
	);
	
	// Render Content Based on Steps
	const StepContent = () => {
		if (state.loading) {
			return <FormLoader style={{ minHeight: "80vh", marginTop: 64 }} />;
		}
		
		
		const props = { handleStep, user: state.user, isCgp, company: state.company };
		// Récupérer les étapes dynamiques
		const steps = getSteps(isProfessional);
		
		// Créer une variable pour la carte des composants (liste des étapes avec composants associés)
		const stepsMap = {
			"Informations de l'entreprise": <CompanyForm {...props} mode={editing ? 'edit' : 'create'} isProfessional={isProfessional} />,
			"Représentant légal": <RepresentativeForm {...props} />,
			"Dirigeants": <OwnerChoice {...props} />,
			"Bénéficiaire": <ShareholderChoice {...props} />,
			"Connaissances et objectifs": <Knowledge {...props} page="companyForm" />,
			"Situation Financière": <CompanyRevenus {...props} />,
			"Justificatifs de l'entreprise": <CompanyUploads {...props} />,
			"Déclarations": <Statement {...props} page="companyForm" />
		};
		
		// Trouver l'étape active actuelle à partir du tableau dynamique
		const activeStep = steps[state.activeStep];
		
		if (!activeStep) {
			// Rediriger si l'étape active n'existe pas (par exemple, si supprimée pour `isProfessional`)
			navigate(isCgp ? '/cgp/clients' : '/overview');
			return null;
		}
		
		// Retourner le composant correspondant à l'étape active basée sur son titre
		return stepsMap[activeStep.title] || null;
	};
	
	// Navigation Actions
	const handleBackNavigation = () => {
		if (editing && state.company?.id) {
			return {
				title: `Revenir au profil de ${state.company.legal_name || ""}`.trim(),
				path: `/cgp/clients/${state.company.id}`,
				state: { listableType: "Company" },
			};
		}
		
		if (state.user?.id) {
			return {
				title: "Revenir à la page précédente",
				path: -1, // Indicates going back dynamically
			};
		}
		
		return {
			title: "Revenir à la liste des clients",
			path: "/cgp/clients",
		};
	};
	
	const handleDirectStepChange = (step) => dispatch({ type: 'SET_STEP', payload: step });
	
	return (
		<div className="user-survey-container">
			<Helmet>
				<title>Openstone | Informations client</title>
			</Helmet>
			
			{ isCgp && (
			<OSButton
				title={handleBackNavigation().title}
				onClick={() => {
					const { path, state } = handleBackNavigation();
					if (path === -1) {
						navigate(-1); // Go back dynamically
					} else {
						navigate(path, { state });
					}
				}}
				variant="no_border"
				size="medium"
				fit
				icon={<ArrowBackIcon />}
			/>
			)}
			
			<div className="top-survey-container">
				<h4>{editing ? "Édition d'une personne morale" : "Création d'une personne morale"}</h4>
				{/*isCgp && (
					<OSButton
						icon={<DownloadIcon />}
						fit
						variant="no_border"
						size="small"
						title="Télécharger le questionnaire"
						link="/path/to/pdf"
					/>
				)*/}
			</div>
			
			<Stepper activeStep={state.activeStep} alternativeLabel>
				{steps.map((step) => (
					<Step key={step.id} onClick={() => editing && handleDirectStepChange(step.id)}>
						<StepLabel>{step.title}</StepLabel>
					</Step>
				))}
			</Stepper>
			
			<Suspense fallback={<div>Chargement...</div>}>
				<StepContent />
			</Suspense>
		</div>
	);
};

export default CompanySurvey;
