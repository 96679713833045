import React, { useState } from 'react';
import { useFormik } from 'formik';
import { toast } from "react-toastify";
import moment from "moment";

import CgpNetwork from "../../../../config/Network/CgpNetwork.ts";
import AuthenticationNetwork from "../../../../config/Network/AuthenticationNetwork.ts";

import sortedCountries from "../../../../config/countries";
import OSButton from "../../../../components/Button";
import OsCard from "../../../../components/card/basic";
import {getPPValidationSchema} from "../../../../components/Survey/GeneralInformations/validationSchemas";
import SpecialStatusSection from "../../../../components/Survey/SpecialStatuses/SpecialStatusSection";
import LocationInfos from "../../../../components/Survey/LocationInfos/LocationInfos";
import WorkInfos from "../../../../components/Survey/WorkInfos/WorkInfos";
import GeneralInfos from "../../../../components/Survey/GeneralInformations/GeneralInfos";
import {handleSurveyContinue} from "../../../../components/Survey/SurveyUtils/CheckContinue";

const UserForm = ({ mode = 'create', user = {}, handleStep, page, isCgp, company, isProfessional }) => {
	const [submitting, setSubmitting] = useState(false);
	const investmentProfile = mode === "create"
		? (isProfessional ? 'professional' : 'non-professional')
		: 'non-professional';
	
	const validationSchema = React.useMemo(() => {
		let schema = getPPValidationSchema();
		if (!isCgp) {
			schema = schema.omit(['email', 'phoneNumber']); // Retirer les validations pour email et phoneNumber
		}
		return schema;
	}, [isCgp]);
	
	const initialValues = {
		...(!isCgp ? {} : { email: user.email || '', phoneNumber: user.phone_number || '' }),
		firstName: user.first_name || '',
		lastName: user.last_name || '',
		usageName: user.usage_name || '',
		nifCode: user.nif_code || '',
		gender: user.gender === "male" ? 0 : 1,
		nationality: user.address_country || '',
		countryBirth: user.country_of_birth || '',
		birthDate: user.date_of_birth ? moment(user.date_of_birth).format('YYYY-MM-DD') : '',
		cityOfBirth: user.city_of_birth || '',
		departmentOfBirth: user.departement_of_birth || '',
		work: user.work || '',
		workCategory: user.work_category || '',
		workSector: user.work_sector || '',
		address: user.address_line_1 || '',
		zipCode: user.address_zip_code || '',
		city: user.address_city || '',
		fiscalCountry: user.fiscal_country || '',
		investorProfile: user.investment_profile || '',
		usPerson: user.us_person ? "true" : "false",
		ppe: user.ppe ? "true" : "false",
		ppeFunction: user.ppe_function || '',
		ppeCountry: user.ppe_country || '',
		ppeAroundMe: user.ppe_around_me ? "true" : "false",
		ppeAroundMeFirstName: user?.ppe_around_me_infos?.firstName || '',
		ppeAroundMeLastName: user?.ppe_around_me_infos?.lastName || '',
		ppeAroundMeFunction: user?.ppe_around_me_infos?.function || '',
		ppeAroundMeCountry: user?.ppe_around_me_infos?.country || '',
	};
	
	
	
	// Formik Initialization
	const formik = useFormik({
		initialValues: initialValues,
		validationSchema: validationSchema,
		onSubmit: async (values) => {
			setSubmitting(true);
			const valuesToSubmit = {
				investment_profile: investmentProfile,
				...(!isCgp ? {} : { email: values.email, phone_number: values.phoneNumber }),
				first_name: values.firstName || '',
				last_name: values.lastName || '',
				usage_name: values.usageName || '',
				gender: values.gender,
				nif_code: values.nifCode,
				fiscal_country: values.fiscalCountry,
				address_country: values.nationality || '',
				country_of_birth: values.countryBirth || '',
				date_of_birth: values.birthDate || '',
				city_of_birth: values.cityOfBirth || '',
				departement_of_birth: values.departmentOfBirth || '',
				work: values.work || '',
				work_category: values.workCategory || '',
				work_sector: values.workSector || '',
				address_line_1: values.address || '',
				address_zip_code: values.zipCode || '',
				address_city: values.city || '',
				us_person: values.usPerson === "true",
				ppe: values.ppe === "true",
				ppe_function: values.ppeFunction || '',
				ppe_country: values.ppeCountry || '',
				ppe_around_me: values.ppeAroundMe === "true",
				ppe_around_me_infos: {
					firstName: values.ppeAroundMeFirstName,
					lastName: values.ppeAroundMeLastName,
					function: values.ppeAroundMeFunction,
					country: values.ppeAroundMeCountry,
				} || {},
			};
			
			
			try {
				let response;
				if (mode === 'edit') {
					response = isCgp
						? await CgpNetwork.updateRecord(valuesToSubmit, user.id, 'User')
						: await AuthenticationNetwork.editUser(valuesToSubmit);
				} else {
					response = await CgpNetwork.createRecord({ ...valuesToSubmit, record_type: 'User' });
					sessionStorage.setItem('User', JSON.stringify(response.id));
				}
				if (response.message) {
					toast.error(response.message);
				} else if (response) {
					toast.success('Vos informations ont bien été enregistrées');
					handleStep(true);
				}
			} catch (error) {
				toast.error('Erreur lors de l’enregistrement');
			} finally {
				setSubmitting(false);
			}
		},
	});
	
	return (
		<form className="os-user-survey-form" onSubmit={formik.handleSubmit}>
			<div style={{marginBottom: 32, marginTop: 24}}>
				<h5 className="os-h5">Informations</h5>
				<span className="os-caption error">Tous les champs sont obligatoires.</span>
			</div>
			<OsCard title="Informations civiles">
				<GeneralInfos formik={formik} sortedCountries={sortedCountries} isCgp={isCgp} />
			</OsCard>
			
			{/* INFOS PROFESSIONNELLES */}
			{(page !== 'kyc' && !company) && (
				<OsCard title="Informations professionnelles" spaced="up">
					<WorkInfos formik={formik} sortedCountries={sortedCountries} page="userSurvey" />
				</OsCard>
			)}
			
			{/* SITUATION FISCALE */}
			<OsCard title="Adresse fiscale" spaced="up">
				<LocationInfos formik={formik} sortedCountries={sortedCountries} page="userSurvey" />
			</OsCard>
			
			{(page !== 'kyc' && !company) && (
				<OsCard title="Statuts particuliers" spaced="up">
					<SpecialStatusSection formik={formik} sortedCountries={sortedCountries} page="userSurvey" />
				</OsCard>
			)}
			
			<div className="user-survey-btn-container" style={{marginTop: 32}}>
				{Object.keys(formik.errors).length !== 0 && <span className="error">Vous devez répondre à toutes les questions pour pouvoir continuer</span>}
				<OSButton variant="primary" size="large" fit disabled={submitting} title="Continuer" type="submit" onClick={() => handleSurveyContinue(formik)} />
			</div>
		</form>
	);
};

export default UserForm;
