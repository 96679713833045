import { makeAutoObservable } from 'mobx';

import AuthenticationNetwork from "../config/Network/AuthenticationNetwork.ts";
import CgpNetwork from "../config/Network/CgpNetwork.ts";

const TOKEN_KEY = 'UserAuthToken'
const CGP_TOKEN_KEY = 'CgpUserAuthToken'


class AuthenticationStore {
  user = null;
  cgp = null;
  token: string | null = null;

  get isAuthenticated() {
    return Boolean(this.token);
  }

  constructor() {
    makeAutoObservable(this);
  }

  async loadUserData() {
    const user = await AuthenticationNetwork.loadUserData();
    this.user = user;
    return user;
  }

  async loadUserFromStorage() {
    try {
      const token = sessionStorage.getItem(TOKEN_KEY);
      if (token !== null) {
        this.token = token;
        await this.loadUserData();
      }
    } catch (e) {
    }
  }

  setAuthToken(token: string | null, save: boolean = false) {
    this.token = token;
    token !== null
      ? sessionStorage.setItem(TOKEN_KEY, token)
      : sessionStorage.removeItem(TOKEN_KEY);
  }
  setUserData(data: any) {
    this.user = data;
    this.setAuthToken(data.auth_token, true);
    sessionStorage.setItem('user', JSON.stringify(data));
    const company = sessionStorage.getItem('Company');
    company === null && sessionStorage.setItem('defaultProfile', 'true');
  }

  async loadCgpUserData() {
    const cgp = await CgpNetwork.getMe();
    this.cgp = cgp;
    return cgp;
  }

  setCgpAuthToken(token: string | null, save: boolean = false) {
    this.token = token;
    token !== null
      ? sessionStorage.setItem(CGP_TOKEN_KEY, token)
      : sessionStorage.removeItem(CGP_TOKEN_KEY);
  }

  setCgpData(data: any) {
    this.cgp = data;
    this.setCgpAuthToken(data.auth_token, true);
    sessionStorage.setItem('CgpUserAuthToken', data.auth_token);
  }

  async requestOTP(phoneNumber: string, userId: number) {
    return await AuthenticationNetwork.requestOTP(phoneNumber, userId);
  }

  async requestOTPWeb(phoneNumber: string, forType: string) {
    return await AuthenticationNetwork.requestOTPWeb(phoneNumber, forType);
  }

  async validateOTP(phoneNumber: string, otp: string, authService: string, userId: Number, forType: string) {
    const response = await AuthenticationNetwork.validateOTP(phoneNumber, otp, authService, userId, forType);
    response && (forType === 'cgp' ? this.setCgpData(response) : this.setUserData(response))

    return response;
  }

  async completeSocialAuth(email: string, firstname: string, lastname: string, authService: string) {
    const response = await AuthenticationNetwork.completeSocialAuth(email, firstname, lastname, authService);
    if (response) {
      this.setUserData(response);
    }

    return response;
  }

  async editUser(data: any) {
    const response = await AuthenticationNetwork.editUser(data);
    if (response) {
      this.user = response;
    }

    return response;
  }

  async changeEmail(data: any) {
    const response = await AuthenticationNetwork.changeEmail(data);

    return response;
  }

  async signOut() {
    this.setAuthToken(null, true);
  }

  async cgpSignOut() {
    this.setCgpAuthToken(null, true);
  }

  get hasMissingData() {
    return this.user?.first_connection;
  }
  get hasMissingPhone() {
    return !this.user?.phone_valid;
  }

  get referralCode() {
    return this.user?.user_invitation?.code || null;
  }

  get identityVerified() {
    return (
      this.user?.kyc_verification_status === 'verified' // || this.hasCompletedVerification
    );
  }

  get residentialVerified() {
    return this.user?.residential_verification_status === 'verified'
  }

  get residentialUnnecessary() {
    return this.user?.residential_verification_status === 'unnecessary'
  }

  get canUpdateInfo() {
    return !this.identityVerified;
  }

  get canUpdateAddress() {
    return !this.residentialVerified;
  }

  get canDeposit() {
    return (this.user?.can_deposit || false) && this.identityVerified
  }

  get accountVerified() {
    return this.user?.account_status === 'verified'
  }

  get contractsSigned() {
    return (
      this.user?.terms_of_service_singed_at &&
      this.user?.privacy_policy_singed_at &&
      this.user?.synthetic_information_document_singed_at
    );
  }

  get maximumAllowedDeposit() {
    return this.user?.remaining_allowed_deposit_amount || 10000
  }

  get completedStepsCount() {
    return [
      1, // account created
      this.user?.email ? 1 : 0,
      this.contractsSigned ? 1 : 0,
      (
        this.identityVerified &&
        (this.residentialVerified || this.residentialUnnecessary)
      ) ? 1 : 0,
      this.accountVerified ? 1 : 0,
    ].reduce((a, b) => a + b, 0)
  }

  async signUp(provider: string, firstName: string, lastName: string, authService: string, code: string, source: string) {
    const response = await AuthenticationNetwork.signUp(provider, firstName, lastName, authService, code, source);
    response.user && this.setUserData(response.user);

    return response;
  }

  async cgpSignUp(provider: string, firstName: string, lastName: string, companyName: string, siren: string, legalFunction: string, oriasId: string, gender: string, legalType: string, invitationToken: string) {
    const response = await AuthenticationNetwork.cgpSignUp(provider, firstName, lastName, companyName, siren, legalFunction, oriasId, gender, legalType, invitationToken);
    response.cgp_user && this.setCgpData(response.cgp_user);

    return response;
  }
}

const authenticationStore = new AuthenticationStore();

export default authenticationStore;
