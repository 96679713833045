import React, {useState} from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';
import TextField from '@material-ui/core/TextField';
import { toast } from "react-toastify";

import authenticationStore from "../../../stores/AuthenticationStore.ts";
import OsSelect from "../../../components/Form/Fields/OsSelect";
import OsRadio from "../../../components/Form/Fields/OsRadio";

import './style.scss';

const CgpSignUpForm = ({ setPhone, inviteData }) => {
	const [loading, setLoading] = useState(false);
	
	const validationSchema = yup.object().shape({
		companyName: yup
			.string()
			.required('Le nom de la société est requis'),
		
		gender: yup
			.string()
			.required('La civilité est requise') // Champ obligatoire
			.oneOf(['male', 'female'], 'Sélectionnez une civilité valide'),
		
		legalType: yup
			.string()
			.when([], {
				is: () => !inviteData?.token,
				then: yup.string().required('Ce champ est requis'),
				otherwise: yup.string().nullable(),
			}),
		
		legalFunction: yup
			.string()
			.required('Votre fonction est requise'),
		
		siren: yup
			.string()
			.length(9, 'Le SIREN doit comporter 9 chiffres')
			.required('Le SIREN est requis'),
		
		firstName: yup
			.string()
			.required('Votre prénom est requis'),
		
		lastName: yup
			.string()
			.required('Votre nom de famille est requis'),
		
		email: yup
			.string()
			.email('L\'adresse e-mail est invalide')
			.required('L\'adresse e-mail est requise'),
		
		oriasId: yup
			.string()
			.length(8, 'L\'identifiant ORIAS doit comporter 8 caractères')
			.required('L\'identifiant ORIAS est requis'),
		
		loss: yup
			.boolean()
			.oneOf([true], 'Vous devez accepter cette condition'), // Les cases doivent être cochées
		difficulty: yup
			.boolean()
			.oneOf([true], 'Vous devez accepter cette condition'),
	});
	
	const formik = useFormik({
		initialValues: {
			legalType: inviteData?.token ? 'pm' : '',
			gender: inviteData?.gender || '',
			companyName: inviteData?.companyName || '',
			legalFunction: '',
			siren: inviteData?.companySiren || '',
			firstName: inviteData?.firstName || '',
			lastName: inviteData?.lastName || '',
			email: inviteData?.email || '',
			oriasId: inviteData?.companyOrias || '',
			difficulty: false,
			loss: false,
		},
		validationSchema: validationSchema,
		enableReinitialize: true,
		onSubmit: async (values) => {
			setLoading(true);
			try {
				const response = await authenticationStore.cgpSignUp(
					values.email,
					values.firstName,
					values.lastName,
					values.companyName,
					values.siren.toString(),
					values.legalFunction,
					values.oriasId,
					values.gender,
					values.legalType,
					inviteData?.token
				);
				if (response.status === 200 || response.cgp_user) {
					toast.success('Votre inscription a bien été prise en compte !', {
						toastId: 'signUpSuccessToast'
					});
					setPhone(true);
				} else if (response.status === 422 || response.error || response.message) {
					toast.error(response.message || 'Une société avec ce SIREN est déjà inscrite sur Openstone.\n Veuillez contacter la personne en charge ou notre équipe à l\'adresse partenaires@openstone.com', { toastId: 'signUpErrorToast' });
				}
			} catch (error) {
				toast.error('An error occurred during submission.');
			} finally {
				setLoading(false);
			}
		},
	});
	
	return (
		<>
			<form className="user-signup-form" onSubmit={(e) => {
				formik.handleSubmit(e);
			}}>
			{!inviteData.token && (
					<>
						<p className="os-subtitle2">Vous êtes:</p>
						<div className="user-signup-container-names">
							<OsRadio
								formik={formik}
								name="legalType"
								value="pm"
								label="Une société"
							/>
							<OsRadio
								formik={formik}
								name="legalType"
								value="ei"
								label="Un entrepreneur individuel"
							/>
						</div>
					</>
				)}
				
				<p className="os-subtitle2">Vos informations</p>
				<div className="user-signup-container">
					<OsSelect
						formik={formik}
						name="gender"
						label="Civilité"
						options={[
							{ value: 'male', label: 'Monsieur' },
							{ value: 'female', label: 'Madame' },
						]}
					/>
					{formik.touched.gender && formik.errors.gender && (
						<span className="error">{formik.errors.gender}</span>
					)}
					
					<div className="user-signup-container-names">
						<TextField
							fullWidth
							id="firstName"
							name="firstName"
							label="Prénom *"
							value={formik.values.firstName}
							onChange={formik.handleChange}
							error={formik.touched.firstName && Boolean(formik.errors.firstName)}
							helperText={formik.touched.firstName && formik.errors.firstName}
							variant={"outlined"}
						/>
						<TextField
							fullWidth
							id="lastName"
							name="lastName"
							label="Nom de famille *"
							value={formik.values.lastName}
							onChange={formik.handleChange}
							error={formik.touched.lastName && Boolean(formik.errors.lastName)}
							helperText={formik.touched.lastName && formik.errors.lastName}
							variant={"outlined"}
						/>
					</div>
					<TextField
						fullWidth
						id="companyName"
						name="companyName"
						label="Nom de votre société *"
						value={formik.values.companyName}
						onChange={formik.handleChange}
						error={formik.touched.companyName && Boolean(formik.errors.companyName)}
						helperText={formik.touched.companyName && formik.errors.companyName}
						variant={"outlined"}
						disabled={Boolean(inviteData.companyName)}
						className={Boolean(inviteData.companyName) ? "disabled-text-field" : ""}
					/>
					<TextField
						fullWidth
						type="number"
						id="siren"
						name="siren"
						label="SIREN de votre société *"
						value={formik.values.siren}
						onChange={formik.handleChange}
						error={formik.touched.siren && Boolean(formik.errors.siren)}
						helperText={formik.touched.siren && formik.errors.siren}
						variant={"outlined"}
						disabled={Boolean(inviteData.companySiren)}
						className={Boolean(inviteData.companySiren) ? "disabled-text-field" : ""}
						InputProps={{
							onWheel: (e) => e.target.blur()
						}}
					/>
					<TextField
						fullWidth
						id="legalFunction"
						name="legalFunction"
						label="Votre fonction au sein de la société *"
						value={formik.values.legalFunction}
						onChange={formik.handleChange}
						error={formik.touched.legalFunction && Boolean(formik.errors.legalFunction)}
						helperText={formik.touched.legalFunction && formik.errors.legalFunction}
						variant={"outlined"}
					/>
					<TextField
						fullWidth
						id="email"
						name="email"
						label="Adresse e-mail *"
						value={formik.values.email}
						onChange={formik.handleChange}
						error={formik.touched.email && Boolean(formik.errors.email)}
						helperText={formik.touched.email && formik.errors.email}
						variant={"outlined"}
						disabled={Boolean(inviteData.email)}
						className={Boolean(inviteData.email) ? "disabled-text-field" : ""}
					/>
					<TextField
						fullWidth
						id="oriasId"
						name="oriasId"
						label="Numéro d'enregistrement ORIAS (CIF) *"
						value={formik.values.oriasId}
						onChange={formik.handleChange}
						error={formik.touched.oriasId && Boolean(formik.errors.oriasId)}
						helperText={formik.touched.oriasId && formik.errors.oriasId}
						variant={"outlined"}
						disabled={Boolean(inviteData.companyOrias)}
						className={Boolean(inviteData.companyOrias) ? "disabled-text-field" : ""}
					/>
					<div className="os-flex gap-8" style={{alignItems: 'center'}}>
						<TextField
							type="checkbox"
							id="difficulty"
							defaultChecked={formik.values.difficulty}
							name="difficulty"
							value={formik.values.difficulty}
							onChange={formik.handleChange}
							error={formik.touched.difficulty && Boolean(formik.errors.difficulty)}
						/>
						<label htmlFor="difficulty" className="os-caption">J'ai conscience du fait que mon client aura des difficultés à revendre ses titres avant l’échéance</label>
					</div>
					{formik.touched.difficulty && formik.errors.difficulty && (
						<span className="error">{formik.errors.difficulty}</span>
					)}
					<div className="os-flex gap-8" style={{alignItems: 'center'}}>
						<TextField
							type="checkbox"
							id="loss"
							defaultChecked={formik.values.loss}
							name="loss"
							value={formik.values.loss}
							onChange={formik.handleChange}
							error={formik.touched.loss && Boolean(formik.errors.loss)}
						/>
						<label htmlFor="loss" className="os-caption">J'ai conscience du fait que mon client peut perdre éventuellement la totalité de son investissement</label>
					</div>
					{formik.touched.loss && formik.errors.loss && (
						<span className="error">{formik.errors.loss}</span>
					)}
				</div>
				
				<div className="user-signup-btn-container">
					<button
						type="submit"
						className="user-signup-btn"
						disabled={loading}
					>
						Continuer
					</button>
				</div>
			</form>
		</>
	);
};

export default CgpSignUpForm;
