import React, {useEffect, useState} from 'react';
import {FormControl, InputLabel, MenuItem, Select} from "@mui/material";

import DocumentNetwork from "../../../config/Network/DocumentNetwork.ts";

import EmptyContent from "../../../components/empty-content/EmptyContent";
import DocumentTable from "../../../components/Table/DocumentTable";

import {ReactComponent as NoDocs} from "../../../assets/Illustrations/no-documents.svg";
import TransactionsLoader from "../../../components/Loaders/Transactions";

import './style.scss';
import OSButton from "../../../components/Button";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

const BsTransactions = ({page}) => {
  const [loadingBs, setLoadingBs] = useState(true);
  const [bsDocs, setBsDocs] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const defaultProfile = useState(JSON.parse(sessionStorage.getItem('defaultProfile')))[0];

  const getBs = async () => {
    setLoadingBs(true);
    const response = defaultProfile === true ? await DocumentNetwork.my_bs(selectedProduct) : await DocumentNetwork.my_company_bs(selectedProduct);
    if (response) {
      setBsDocs(response);
      setLoadingBs(false);
    }
  };
  function renderTransactions() {
    if (loadingBs) {
      return [1, 2, 3, 4].map(l => <TransactionsLoader key={l}/>);
    } else if (!loadingBs && document !== undefined) {
      if (bsDocs.bs_docs !== [] && bsDocs.bs_docs.length > 0) {
        return <DocumentTable title="" tableData={page === 'preview' ? bsDocs.bs_docs.slice(0,9) : bsDocs.bs_docs} subheader=''
                              tableLabels={[
                                { id: 'title', label: 'Nom du produit' },
                                { id: 'created_at', label: 'Date' },
                                { id: 'amount', label: 'Montant' },
                                { id: 'status', label: 'Statut' },
                                { id: 'bulletin', label: 'Bulletin' },
                              ]} />
      } else {
        return (
          <div style={{width: '100%', display: 'flex', justifyContent: 'center'}}>
            <EmptyContent illustration={<NoDocs/>} text="Aucun bulletin à afficher" cta={{
              title: "Voir les offres",
              link: "/opportunites",
              variant: "primary-full",
              size: "small"
            }}/>
          </div>
        )
      }
    }
  }

  useEffect(() => {
    getBs();
  }, [selectedProduct])

  return (
    <div className={page !== 'preview' && 'my-documents-container'}>
      {page !== 'preview' && (
        <div className="back-to-docs" onClick={() => window.location.replace('/documents')}>
          <ArrowBackIcon /> Revenir aux documents
        </div>
      )}
      <h4 className="os-h4" style={{marginTop: 24}}>Mes bulletins de souscription</h4>
      <p className="os-body2">Vos bulletins signés pour chacune de vos souscriptions</p>
      {page !== 'preview' && (
        <div className="opportunities-form">
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">Par produit</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={selectedProduct || null}
              label="Opportunities"
              onChange={event => setSelectedProduct(event.target.value)}>
              <MenuItem value={null}>Tous les produits</MenuItem>
              <MenuItem value={'livretp'}>Livret P.</MenuItem>
              {!loadingBs && bsDocs.products.map((p, index) => (
                <MenuItem value={p.product_id} key={p.product_id * index}>{p.product_name}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
      )}
      <div className="my-transactions-container">
        {renderTransactions()}
      </div>
      {(page === 'preview' && (!loadingBs && bsDocs?.bs_docs.length > 9)) && (
        <div className="see-more">
          <OSButton size="large" variant="secondary" title="Voir plus de bulletins" link="/documents/bulletins/souscriptions" fit />
        </div>
      )}
    </div>
  );
};

export default BsTransactions;
