import React, { useState } from 'react';
import {useFormik} from 'formik';
import * as yup from 'yup';
import TextField from '@material-ui/core/TextField';
import {toast} from "react-toastify";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import CompanyNetwork from "../../../../config/Network/CompanyNetwork.ts";
import CgpNetwork from "../../../../config/Network/CgpNetwork.ts";

import OSButton from "../../../../components/Button";
import OsCard from "../../../../components/card/basic";
import {InputAdornment} from "@mui/material";

const CompanyRevenus = ({handleStep, page, isCgp, company}) => {
	const [submitting, setSubmitting] = useState(false);
	const financials = company?.financial_situation;
	
	const validationSchema = yup.object({
		businessRevenueAmount: yup.string().required('Requis'),
		businessRealEstateWealthAmount: yup.string().required('Requis'),
		businessNonRealEstateWealthAmount: yup.string().required('Requis'),
	})

	const formik = useFormik({
		initialValues: {
			businessRevenueAmount: financials?.businessRevenueAmount || '',
			businessRealEstateWealthAmount: financials?.businessRealEstateWealthAmount || '',
			businessNonRealEstateWealthAmount: financials?.businessNonRealEstateWealthAmount || '',
			//iban: company ? company.iban : user.iban
		},
		validationSchema: validationSchema,
		onSubmit: async (values) => {
			setSubmitting(true);
			const datasToSubmit = {
				financial_situation : {
					businessRevenueAmount: values.businessRevenueAmount,
					businessRealEstateWealthAmount: values.businessRealEstateWealthAmount,
					businessNonRealEstateWealthAmount: values.businessNonRealEstateWealthAmount,
				}
			};
			async function handleRequest() {
				if (isCgp) {
					await CgpNetwork.updateRecord(datasToSubmit, company.id, 'Company')
				} else {
					await CompanyNetwork.updateCompany(company.id, datasToSubmit)
				}
			}
			const response = handleRequest();
			if (response) {
				setSubmitting(false);
				toast.success('Votre situation financière a bien été enregistrée', {
					toastId: 'completeInfosSuccessToast'
				});
				window.scrollTo(0, 0);
				handleStep(true);
			} else {
				toast.error("Une erreur est survenue lors de l'enregistrement de vos informations",{
					toastId: 'completeInfosErrorToast'
				});
			}
		},
	});
	
	return (
		<div>
			<form className="os-user-survey-form" onSubmit={formik.handleSubmit}>
				<div style={{marginBottom: 32, marginTop: 24}}>
					<h5 className="os-h5">Situation financière</h5>
					<span className="os-caption error">Tous les champs sont obligatoires.</span>
				</div>
				<OsCard title="Revenus" spaced="up">
					<div className="os-flex__column">
						<div className="by-2">
							<p className="user-survey-question">Quel le chiffre d’affaires annuel ?</p>
							<TextField
								fullWidth
								id="businessRevenueAmount"
								name="businessRevenueAmount"
								label="Montant :"
								value={formik.values.businessRevenueAmount}
								onChange={formik.handleChange}
								error={formik.touched.businessRevenueAmount && Boolean(formik.errors.businessRevenueAmount)}
								helperText={formik.touched.businessRevenueAmount && formik.errors.businessRevenueAmount}
								variant={"outlined"}
								type="number"
								InputProps={{
									endAdornment: <InputAdornment position="end">€</InputAdornment>,
									onWheel: (e) => e.target.blur()
								}}
							/>
						</div>
						<div className="by-2">
							<p className="user-survey-question">Quelle est la tranche de patrimoine immobilier de la personne morale (net d’endettement)?</p>
							<TextField
								fullWidth
								id="businessRealEstateWealthAmount"
								name="businessRealEstateWealthAmount"
								label="Montant :"
								value={formik.values.businessRealEstateWealthAmount}
								onChange={formik.handleChange}
								error={formik.touched.businessRealEstateWealthAmount && Boolean(formik.errors.businessRealEstateWealthAmount)}
								helperText={formik.touched.businessRealEstateWealthAmount && formik.errors.businessRealEstateWealthAmount}
								variant={"outlined"}
								type="number"
								InputProps={{
									endAdornment: <InputAdornment position="end">€</InputAdornment>,
									onWheel: (e) => e.target.blur()
								}}
							/>
						</div>
						
						<div className="by-2">
							<p className="user-survey-question">Quelle est la tranche de patrimoine hors immobilier de la personne morale (net d’endettement) ?</p>
							<TextField
								fullWidth
								id="businessNonRealEstateWealthAmount"
								name="businessNonRealEstateWealthAmount"
								label="Montant :"
								value={formik.values.businessNonRealEstateWealthAmount}
								onChange={formik.handleChange}
								error={formik.touched.businessNonRealEstateWealthAmount && Boolean(formik.errors.businessNonRealEstateWealthAmount)}
								helperText={formik.touched.businessNonRealEstateWealthAmount && formik.errors.businessNonRealEstateWealthAmount}
								variant={"outlined"}
								type="number"
								InputProps={{
									endAdornment: <InputAdornment position="end">€</InputAdornment>,
									onWheel: (e) => e.target.blur()
								}}
							/>
						</div>
					</div>
				</OsCard>
				
				<div className="user-survey-btn-container" style={{marginTop: 32}}>
					<OSButton onClick={() => handleStep(false)} variant="no_border" title="Revenir à l'étape précédente" fit size="medium" icon={<ArrowBackIcon />}/>
					<button type="submit" className="user-survey-btn" disabled={submitting}>Continuer</button>
				</div>
				<div className="user-survey-btn-container">
					{Object.keys(formik.errors).length !== 0 && <span className="error">Vous devez répondre à toutes les questions pour pouvoir continuer</span>}
				</div>
			</form>
		</div>
	);
};

export default CompanyRevenus;
