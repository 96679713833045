import React from 'react';
import TextField from '@material-ui/core/TextField';
import OsSelect from "../../Form/Fields/OsSelect";
import OsRadio from "../../Form/Fields/OsRadio";
import { ppeFunction } from "../../../pages/KycSurvey/Steps/choices";

const SpecialStatusSection = ({ formik, sortedCountries, page }) => {
	return (
		<>
			<p className="os-body1">Êtes-vous une US Person ou avez-vous l’intention de l’être ?</p>
			<div className="os-flex__column gap-16" style={{ marginBottom: 40 }}>
				<div className="full-input radio-inputs">
					<div className="user-survey-questions-container">
						<OsRadio formik={formik} name="usPerson" value="true" label="Oui" />
						<OsRadio formik={formik} name="usPerson" value="false" label="Non" />
					</div>
					<span className="error">{formik.touched.usPerson && formik.errors.usPerson}</span>
				</div>
				
				<p className="os-body1">
					Exercez-vous (ou avez-vous exercé), depuis moins d’un an, des fonctions politiques, juridictionnelle ou administrative pour le compte d’un État ou d’une institution internationale publique créée par un traité (1)
				</p>
				<div className="user-survey-questions-container radio-inputs">
					<OsRadio formik={formik} name="ppe" value="true" label="Oui" />
					<OsRadio formik={formik} name="ppe" value="false" label="Non" />
				</div>
				<span className="error">{formik.touched.ppe && formik.errors.ppe}</span>
				
				{formik.values.ppe === "true" && (
					<>
						<div className="user-survey-questions-container">
							<div>
								<OsSelect
									formik={formik}
									name="ppeFunction"
									label="Fonction"
									options={ppeFunction.map((ppef) => ({
										value: ppef.value,
										label: ppef.value,
									}))}
								/>
								<span className="error">{formik.touched.ppeFunction && formik.errors.ppeFunction}</span>
							</div>
							<div>
								<OsSelect
									formik={formik}
									name="ppeCountry"
									label="Pays"
									options={sortedCountries.map((country) => ({
										value: country.name,
										label: country.name,
									}))}
								/>
								<span className="error">{formik.touched.ppeCountry && formik.errors.ppeCountry}</span>
							</div>
						</div>
						<span className="os-caption">
							(1) Les fonctions politiques, juridictionnelles ou administratives concernées sont celles définies à l’article R. 561-18 I du Comofi
						</span>
					</>
				)}
				
				<p className="os-body1">
					Si une personne de la famille (2) ou de l’entourage (3) du bénéficiaire effectif est une personne politiquement exposée, répondez aux questions ci-dessous.
				</p>
				<div className="user-survey-questions-container radio-inputs">
					<OsRadio formik={formik} name="ppeAroundMe" value="true" label="Oui" />
					<OsRadio formik={formik} name="ppeAroundMe" value="false" label="Non" />
				</div>
				<span className="error">{formik.touched.ppeAroundMe && formik.errors.ppeAroundMe}</span>
				
				{formik.values.ppeAroundMe === "true" && (
					<>
						<div className="user-survey-questions-container">
							<TextField
								fullWidth
								id="ppeAroundMeFirstName"
								name="ppeAroundMeFirstName"
								label="Prénom"
								value={formik.values.ppeAroundMeFirstName}
								onChange={formik.handleChange}
								error={formik.touched.ppeAroundMeFirstName && Boolean(formik.errors.ppeAroundMeFirstName)}
								helperText={formik.touched.ppeAroundMeFirstName && formik.errors.ppeAroundMeFirstName}
								variant="outlined"
							/>
							<TextField
								fullWidth
								id="ppeAroundMeLastName"
								name="ppeAroundMeLastName"
								label="Nom de famille"
								value={formik.values.ppeAroundMeLastName}
								onChange={formik.handleChange}
								error={formik.touched.ppeAroundMeLastName && Boolean(formik.errors.ppeAroundMeLastName)}
								helperText={formik.touched.ppeAroundMeLastName && formik.errors.ppeAroundMeLastName}
								variant="outlined"
							/>
						</div>
						<div className="user-survey-questions-container">
							<div>
								<OsSelect
									formik={formik}
									name="ppeAroundMeFunction"
									label="Fonction"
									options={ppeFunction.map((ppef) => ({
										value: ppef.value,
										label: ppef.value,
									}))}
								/>
								<span className="error">{formik.touched.ppeAroundMeFunction && formik.errors.ppeAroundMeFunction}</span>
							</div>
							<div>
								<OsSelect
									formik={formik}
									name="ppeAroundMeCountry"
									label="Pays"
									options={sortedCountries.map((country) => ({
										value: country.name,
										label: country.name,
									}))}
								/>
								<span className="error">{formik.touched.ppeAroundMeCountry && formik.errors.ppeAroundMeCountry}</span>
							</div>
						</div>
						<span className="os-caption">
							(2) Les personnes considérées comme des personnes connues pour être des membres directs de votre famille sont définies à l’article R. 561-18 II du Comofi.
						</span>
						<span className="os-caption">
							(3) Les personnes qui vous sont étroitement associées sont définies à l’article R.561-18 III du Comofi
						</span>
					</>
				)}
			</div>
		</>
	);
};

export default SpecialStatusSection;
