import React, {useEffect, useState} from 'react';
import {useLocation} from "react-router";
import {useNavigate} from "react-router-dom";
import {useFormik} from "formik";
import { MenuItem} from "@mui/material";
import TextField from "@material-ui/core/TextField";
import Pagination from '@mui/material/Pagination';
import * as yup from "yup";
import { CSVLink } from "react-csv";

import TransactionsLoader from "../../components/Loaders/Transactions";
import PerPage from "../../components/PerPage";
import renderTransactionsTable from "../../components/User/renderTransactionsTable";

import InvestmentNetwork from "../../config/Network/InvestmentNetwork.ts";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import TuneIcon from '@mui/icons-material/Tune';
import CloseIcon from '@mui/icons-material/Close';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import OSButton from "../../components/Button";
import './style.scss';
import axios from "axios";

const ProductTransactions = () => {
  const { state } = useLocation();
  const defaultProfile = state?.cgp ? (state.clientType !== 'Company') : JSON.parse(sessionStorage.getItem('defaultProfile'));
  const object = defaultProfile ? JSON.parse(sessionStorage.getItem('user')) : JSON.parse(sessionStorage.getItem('company'));
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [transactions, setTransactions] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const [submitting, setSubmitting] = useState(false)
  const [showFilter, setShowFilter] = useState(false);
  const LivretpPage = window.location.href.includes('livret-p');
  const [resetFilter, setResetFilter] = useState(false);
  const [filterValues, setFilterValues] = useState(null);
  const date = new Date();
  date.setDate(date.getDate() - 30);
  const thirtyDaysAgo = date.toLocaleDateString();

  const fetchInvestments = async ({transactionStatus, transactionType, date, startDate, endDate}) => {
    setLoading(true);
    setResetFilter(false);
		
    const response = await InvestmentNetwork.list(
      state?.clientId,
      defaultProfile ? 'User' : 'Company',
      LivretpPage ? null : state?.id,
      LivretpPage ? 'livretp' : state?.name,
      transactionStatus,
      transactionType,
      date,
      startDate,
      endDate,
      page,
      perPage
    );
		
    if (response) {
      setTransactions(response);
      setLoading(false);
    }
  }

  const fetchPdf = async () => {
    setSubmitting(true)
    try {
      const response = await axios.get(`https://api-v2.livretp.fr/user_transactions?uuid=${object?.uuid}&page=1&per_page=10`, {
        headers: {
          'Accept': 'application/pdf'
        },
        responseType: 'blob',
      });

      if (response.data) {
        const blob = new Blob([response.data], { type: 'application/pdf' });
        const downloadUrl = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = downloadUrl;
        link.setAttribute('download', 'LivretP_reporting.pdf'); // Name of the downloaded file
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link); // Remove the link when done
        window.URL.revokeObjectURL(downloadUrl); // Clean up the URL object
      }
    } catch (error) {
      console.error('Error fetching PDF:', error);
    }
    setSubmitting(false);
  };

  useEffect(() => {
    resetFilter && setFilterValues(null);
    if (filterValues !== null) {
      fetchInvestments(filterValues);
    } else {
      fetchInvestments({transactionStatus: 'all'}).then(() => {
				setLoading(false); // Explicitly reset loading to ensure rendering updates
			});
    }
  }, [resetFilter, page, perPage])

  const renderPage = () => {
    if (loading) {
			return [...Array(perPage)].map((_, index) => <TransactionsLoader key={index}/>);
    } else if (!loading) {
      return renderTransactionsTable(loading, transactions?.investments, state?.cgp && 'cgp', {name: "Livret P.", category_slug: "livret-p", slug: "livretp"}, "Full")
    }
  }

  const validationSchema = yup.object({})

  const formik = useFormik({
    initialValues: {
      status: '',
      date: '',
      startDate: '',
      endDate: '',
      type: '',
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      setSubmitting(true)
      const valuesToSubmit = {transactionStatus: values.status === '' ? 'all' : values.status, transactionType: values.type, date: values.date, startDate: values.startDate, endDate: values.endDate}
      setFilterValues(valuesToSubmit)
      await fetchInvestments(valuesToSubmit);
      setSubmitting(false);
    }
  });
	
	function handleChange(event, value) {
		setPage(value); // Update page number
	}

  const headers = [
    { label: "Type de la transaction", key: "transaction_type" },
    { label: "Statut", key: "status" },
    { label: "Montant", key: "amount" },
    { label: "Date de la transaction", key: "created_at"}
  ];

  return (
		<div key={`${page}-${perPage}`} className="transactions-page">
			<OSButton variant="primary-full" fit size="medium" title={`Revenir à ${state?.cgp ? "l'investissement" : "mon investissement"} ${LivretpPage ? 'Livret P' : state?.name}` } onClick={() => navigate(-1)} icon={<ArrowBackIcon />}/>
      <h4 className="os-h4">{state?.cgp ? 'Transactions' : 'Mes transactions'} {LivretpPage ? 'Livret P.' : state?.name}</h4>
      <div className="filters-container">
        <div className="filters-btns">
          <OSButton onClick={() => setShowFilter(!showFilter)} variant="secondary" fit size="medium" title={!showFilter ? 'Voir les filtres' : 'Fermer les filtres'} icon={!showFilter ? <TuneIcon/> : <CloseIcon />}/>
          {(LivretpPage && defaultProfile && object?.uuid) ? (
            <OSButton onClick={() => fetchPdf()} variant="primary" size="medium" title="Obtenir un état des comptes" fit icon={<FileDownloadIcon />} submitting={submitting}/>
          ) : (
            <div>
              {(!loading && transactions.investments.length > 0) && <CSVLink data={transactions.export_transactions.sort((a, b) => new Date(b.created_at) - new Date(a.created_at))}
                                                                             headers={headers} encoding="utf-8" bom="true" filename={"export_transactions.csv"}
                                                                             className="filter-btn filter-btn-selected">
                <FileDownloadIcon />
                <span className="os-body1">Exporter en CSV</span>
              </CSVLink>}
            </div>
          )}
        </div>
        {showFilter && (
          <div className="transactions-filters">
            <form onSubmit={formik.handleSubmit}>
              <div className="os-flex gap-32">
                <TextField
                  fullWidth
                  id="status"
                  name="status"
                  select
                  label="Statut de la transaction"
                  value={formik.values.status}
                  onChange={formik.handleChange}
                  error={formik.touched.status && Boolean(formik.errors.status)}
                  helperText={formik.touched.status && formik.errors.status}
                  variant={"outlined"}
                >
                  <MenuItem value={'success'}>Validé</MenuItem>
                  <MenuItem value={'pending'}>En attente</MenuItem>
                </TextField>
                {LivretpPage && (
                  <TextField
                    fullWidth
                    id="type"
                    name="type"
                    select
                    label="Type de transaction"
                    value={formik.values.type}
                    onChange={formik.handleChange}
                    error={formik.touched.type && Boolean(formik.errors.type)}
                    helperText={formik.touched.type && formik.errors.type}
                    variant={"outlined"}
                  >
                    <MenuItem value={'top_up'}>Dépôt</MenuItem>
                    <MenuItem value={'cgp_entry_fees'}>Droits d'entrée</MenuItem>
                    <MenuItem value={'withdraw'}>Retrait</MenuItem>
                    <MenuItem value={'interest_from_base_rate'}>Intérêts taux de base</MenuItem>
                    <MenuItem value={'interest_from_boosts'}>Intérêts taux boosté</MenuItem>
                    {defaultProfile && <MenuItem value={'referral_bonus'}>Prime parrain</MenuItem>}
                    {defaultProfile && <MenuItem value={'referred_bonus'}>Prime filleuil</MenuItem>}
                    <MenuItem value={'gift_in_euro'}>Cadeaux</MenuItem>
                    <MenuItem value={'taxes'}>Prélèvement et impôts</MenuItem>
                  </TextField>
                )}
                <TextField
                  fullWidth
                  id="date"
                  name="date"
                  select
                  label="Période"
                  value={formik.values.date}
                  onChange={formik.handleChange}
                  error={formik.touched.date && Boolean(formik.errors.date)}
                  helperText={formik.touched.date && formik.errors.date}
                  variant={"outlined"}
                >
                  <MenuItem value={'last_30_days'}>30 derniers jours ({thirtyDaysAgo} - {new Date().toLocaleDateString()})</MenuItem>
                  <MenuItem value={'current_year'}>Année en cours</MenuItem>
                  <MenuItem value={'last_year'}>Année précédente</MenuItem>
                  <MenuItem value={'custom'}>Personnalisée</MenuItem>
                </TextField>
              </div>
              {formik.values.date === 'custom' && (
                <>
                  <p className="os-subtitle2">Période personnalisée</p>
                  <div className="os-flex gap-32">
                    <TextField
                      fullWidth
                      id="startDate"
                      name="startDate"
                      type="date"
                      label="Date de début"
                      value={formik.values.startDate}
                      onChange={formik.handleChange}
                      error={formik.touched.startDate && Boolean(formik.errors.startDate)}
                      helperText={formik.touched.startDate && formik.errors.startDate}
                      variant={"outlined"}
                      InputProps={{inputProps: { min: "2022-01-01", max: "2999-05-04"} }}
                      InputLabelProps={{ shrink: true }}
                    />
                    <TextField
                      fullWidth
                      id="endDate"
                      name="endDate"
                      type="date"
                      label="Date de fin"
                      value={formik.values.endDate}
                      onChange={formik.handleChange}
                      error={formik.touched.endDate && Boolean(formik.errors.endDate)}
                      helperText={formik.touched.endDate && formik.errors.endDate}
                      variant={"outlined"}
                      InputProps={{inputProps: { min: "2022-01-01", max: "2999-05-04"} }}
                      InputLabelProps={{ shrink: true }}
                    />
                  </div>
                </>
              )}
              <div className="user-survey-btn-container" style={{marginTop: 24}}>
                <p className="os-body2" style={{cursor: "pointer"}} onClick={() => setResetFilter(true)}>Réinitialiser les filtres</p>
                <button disabled={submitting} type="submit" className="user-survey-btn">Continuer</button>
              </div>
            </form>
          </div>
        )}
      </div>
      {renderPage()}
      <div className="os-flex pagination-container gap-16 transactions-filters">
        {(!loading && transactions.total_pages > 1) ? <Pagination count={!loading ? transactions.total_pages : 10} shape="rounded" page={page} onChange={handleChange}/> : <div/>}
        <PerPage perPage={perPage} setPerPage={setPerPage} />
      </div>
    </div>
  )
};

export default ProductTransactions;
