import React, {useEffect, useState} from 'react';
import ImageUpload from "../../../../components/ImageUpload";
import {CircularProgress} from "@mui/material";
import OsCard from "../../../../components/card/basic";
import Divider from "@mui/material/Divider";
import OSButton from "../../../../components/Button";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Pdf from "../../../../components/Document/pdf";

const CompanyUploads = ({handleStep, company}) => {
  const [submitting, setSubmitting] = useState(false);
  const [upload, setUpload] = useState(false);
  const [nextStep, setNextStep] = useState(false);
  const [iDCount, setIDCount] = useState(0);
  const [kbisCount, setKbisCount] = useState(0);
  const [statusCount, setStatusCount] = useState(0);
  const [ribCount, setRibCount] = useState(0);

  useEffect(() => {
    if (nextStep) {
      window.scrollTo(0, 0);
      handleStep(true);
    }
  }, [nextStep])

  function validUpload() {
    (iDCount > 0 && kbisCount > 0 && ribCount > 0 && statusCount > 0) && setUpload(true);
  }

  function renderButton() {
    if (iDCount === 0 || kbisCount === 0 || ribCount === 0 || statusCount === 0) {
      return <button disabled={true} className="submit-btn">Envoyer les documents</button>
    } else if (submitting) {
      return <button disabled={submitting} className="submit-btn">
        <CircularProgress color="inherit" />
        Documents en cours d'envoi
      </button>
    } else {
      return <button disabled={!(iDCount > 0 && kbisCount > 0 && ribCount > 0 && statusCount > 0)} className="submit-btn" onClick={() => validUpload()}>Envoyer les documents</button>
    }
  }

  return (
		<>
			<div style={{marginBottom: 32, marginTop: 24}}>
				<h5 className="os-h5">Justificatifs de l'entreprise</h5>
				<span className="os-caption error">Tous les champs sont obligatoires.</span>
			</div>
			<OsCard spaced="up">
				<div className="os-grid two-grid gap-24" style={{alignItems: "flex-start"}}>
					<div>
						<p className="os-subtitle1">Votre pièce d'identité</p>
						<ImageUpload userSurveyCheck={setIDCount} upload={upload} submitting={submitting} setSubmitting={setSubmitting} func={setNextStep} type="companyUpload" documentType="kyc"  record={company}/>
					</div>
					
					<div>
						<p className="os-subtitle1">Kbis de moins de 3 mois</p>
						<ImageUpload userSurveyCheck={setKbisCount} upload={upload} submitting={submitting} setSubmitting={setSubmitting} func={setNextStep} type="companyUpload" documentType="kbis"  record={company}/>
					</div>
					<div>
						<p className="os-subtitle1">Statuts certifiés conformes signés et datés</p>
						<ImageUpload userSurveyCheck={setStatusCount} upload={upload} submitting={submitting} setSubmitting={setSubmitting} func={setNextStep} type="companyUpload" documentType="status" record={company}/>
					</div>
					
					<div>
						<p className="os-subtitle1">RIB de l'entreprise - Relevé d'identité bancaire</p>
						<ImageUpload userSurveyCheck={setRibCount} upload={upload} submitting={submitting} setSubmitting={setSubmitting} func={setNextStep} type="companyUpload" documentType="rib" record={company}/>
					</div>
				</div>
				
				<Divider />
				
				<div className="info-callout" style={{marginTop: 24}}>
					<h6 className="os-h6">
						Documents complémentaires
					</h6>
					<p className="os-body1">Pour faciliter et accélérer le processus de souscription, nous vous invitons à ajouter dès à présent les documents nécessaires, tels que la justification de provenance des fonds, afin de garantir un traitement plus rapide de votre dossier.</p>
				</div>
				<div className="os-grid two-grid gap-24" style={{alignItems: "flex-start"}}>
					<div>
						<p className="os-subtitle1">Provenance des fonds (optionnel)</p>
						<ImageUpload upload={upload} submitting={submitting} setSubmitting={setSubmitting} func={setNextStep} type="companyUpload" documentType="sourceOfFunds" record={company}/>
					</div>
					<div>
						<p className="os-body1">Template téléchargeable</p>
						<Pdf
							page="opportunity"
							document={{name: "Provenance des fonds", attachment: { url: "https://drive.google.com/file/d/1KYnlyXP2jyn4Dtqkvsx0td1gWg5JplIf/view?usp=sharing"}}}
							key={`documents-sourceOfFunds`}/>
					</div>
					<div>
						<p className="os-subtitle1">Document supplémentaire (optionnel)</p>
						<ImageUpload upload={upload} submitting={submitting} setSubmitting={setSubmitting} func={setNextStep} type="companyUpload" documentType="other" record={company}/>
					</div>
				</div>
				
				
				<div className="os-flex gap-8" style={{justifyContent: 'flex-end'}}>
					<OSButton title="Revenir à l'étape précédente" onClick={() => handleStep(false)} fit size="large" variant="no_border" icon={<ArrowBackIcon />}/>
					{renderButton()}
				</div>
			</OsCard>
		</>
  );
};

export default CompanyUploads;
