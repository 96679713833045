import React, {useEffect, useState} from 'react';
import ImageUpload from "../../../../components/ImageUpload";
import {CircularProgress} from "@mui/material";
import OsCard from "../../../../components/card/basic";
import Divider from "@mui/material/Divider";

const MoreUploads = ({client, setRa, setRib, clientType}) => {
	const [submitting, setSubmitting] = useState(false);
	const [upload, setUpload] = useState(false);
	const [nextStep, setNextStep] = useState(false);
	const [raCount, setRaCount] = useState(0);
	const [ribCount, setRibCount] = useState(0);
	const type = clientType === 'Company' ? 'companyUpload' : 'userUpload';
	
	useEffect(() => {
		if (nextStep) {
			setRa("submitted");
			setRib("submitted");
			window.scrollTo(0, 0);
		}
	}, [nextStep])
	
	function renderButton() {
		if (raCount === 0 || ribCount === 0) {
			return <button disabled={true} className="submit-btn">Envoyer les documents</button>
		} else if (submitting) {
			return <button disabled={submitting} className="submit-btn">
				<CircularProgress color="inherit" />
				Documents en cours d'envoi
			</button>
		} else {
			return <button disabled={!(raCount > 0 && ribCount > 0)} className="submit-btn" onClick={() => setUpload(true)}>Envoyer les documents</button>
		}
	}
	
	return (
		<>
			<div style={{marginBottom: 32, marginTop: 24}}>
				<h5 className="os-h5">Justificatifs</h5>
			</div>
			<OsCard title="" spaced="up">
				<div className="os-grid one-grid gap-24" style={{alignItems: "flex-start"}}>
					<div>
						<p className="os-subtitle2">Rapport d'adéquation signé</p>
						<ImageUpload userSurveyCheck={setRaCount} upload={upload} submitting={submitting} setSubmitting={setSubmitting} func={setNextStep} documentType="ra" record={client} type={type} />
					</div>
					<div>
						<p className="os-subtitle2">RIB - Relevé d'identité bancaire</p>
						<ImageUpload userSurveyCheck={setRibCount} upload={upload} submitting={submitting} setSubmitting={setSubmitting} func={setNextStep} documentType="rib" record={client} type={type} />
					</div>
				</div>
				
				<Divider />
				
				<div className="info-callout" style={{marginTop: 24}}>
					<h6 className="os-h6">Documents complémentaires</h6>
				</div>
				<div className="os-grid one-grid gap-24" style={{alignItems: "flex-start"}}>
					<div>
						<p className="os-subtitle1">Document supplémentaire (optionnel)</p>
						<ImageUpload upload={upload} submitting={submitting} setSubmitting={setSubmitting} func={setNextStep} documentType="other" record={client} type={type} />
					</div>
				</div>
				
				<div className="user-survey-btn-container">
					{renderButton()}
				</div>
			</OsCard>
		</>
	);
};

export default MoreUploads;
