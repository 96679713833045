import React from 'react';

import { addAdditionalClasses } from "../../utils/utils";

import './style.scss';
import OSButton from "../Button";
import getBadge from "../Badge/getBadge";

/**
 * A card component that always has a title and optionally a link.
 *
 * @param {string} title - The title to display on the card.
 * @param {string} [link] - The optional link to be displayed on the card.
 * @param {string} linkTitle - The optional link title to be displayed on the card.
 * @param {ReactNode} children - The content to be displayed inside the card.
 */
const OsCard = ({title, link, linkTitle, children, onClick, conditionForLink, badges, bg, conditionForBadges, shadows, icon, spaced}) => {
  const classNames = addAdditionalClasses('os-card', [
    bg && `os-card--bg-${bg}`,
    shadows && `os-card--shadows-${shadows}`,
		spaced && `os-card--spaced-${spaced}`
  ]);

  return (
    <div className={classNames}>
      {(title || badges || (conditionForLink && link)) && (
        <div className="os-flex os-card-btns" style={{alignItems: "center", justifyContent: "space-between", marginBottom: 16}}>
          <div className="os-flex gap-16 os-card-title-container">
            {title && <p className="os-subtitle1">{title}</p>}
            <div className="os-flex gap-8 badges-container">
              {((badges && badges.length > 0) && conditionForBadges) && badges.map(b => getBadge(b.status, 'signable', `${b.number} ${b.text}`))}
            </div>
          </div>
          {conditionForLink && (
            (link || onClick) &&
            <OSButton size="medium" variant="no_border" fit link={link} title={linkTitle} onClick={onClick} icon={icon}/>
          )}
        </div>
      )}
      {children}
    </div>
  );
};

OsCard.defaultProps = {
  children: null,
};

export default OsCard;
