import * as yup from "yup";
import {LocationInfosSchema} from "../LocationInfos/LocationInfosSchema";
import {WorkInfosSchema} from "../WorkInfos/WorkInfosSchema";

const getPPValidationSchema = (page) => {
	let baseSchema = yup.object({
		email: yup.string().email('Format invalide').required('Requis'),
		phoneNumber: page !== 'companySurvey'
			? yup.string().required('Requis')
			: yup.string().notRequired(),
		firstName: yup.string().required('Requis'),
		lastName: yup.string().required('Requis'),
		gender: yup.string().required('Requis'),
		nationality: yup.string().required('Requis'),
		countryBirth: yup.string().required('Requis'),
		fiscalCountry: yup.string().required('Requis'),
		cityOfBirth: yup.string().required('Requis'),
		departmentOfBirth: yup.string().required('Requis').max(99, "Max 2 chiffres"),
		birthDate: yup.date()
			.max(new Date(Date.now() - 567648000000), "Vous devez être majeur")
			.typeError("Requis")
			.required("Requis"),
		usPerson: yup.string()
			.oneOf(['true', 'false'], 'Sélection requise')
			.required('Sélection requise'),
		ppe: yup.string()
			.oneOf(['true', 'false'], 'Sélection requise')
			.required('Sélection requise'),
		ppeFunction: yup
			.string()
			.when('ppe', {
				is: 'true',
				then: yup.string().required('Requis'),
			}),
		ppeCountry: yup
			.string()
			.when('ppe', {
				is: 'true',
				then: yup.string().required('Requis'),
			}),
		
		ppeAroundMe: yup.string()
			.oneOf(['true', 'false'], 'Sélection requise')
			.required('Sélection requise'),
		ppeAroundMeFirstName: yup
			.string()
			.when('ppeAroundMe', {
				is: 'true',
				then: yup.string().required('Requis'),
			}),
		ppeAroundMeLastName: yup
			.string()
			.when('ppeAroundMe', {
				is: 'true',
				then: yup.string().required('Requis'),
			}),
		ppeAroundMeFunction: yup
			.string()
			.when('ppeAroundMe', {
				is: 'true',
				then: yup.string().required('Requis'),
			}),
		ppeAroundMeCountry: yup
			.string()
			.when('PPEAroundMe', {
				is: 'true',
				then: yup.string().required('Requis'),
			}),
	});
	
	if (page !== 'kyc') {
		baseSchema = baseSchema.concat(LocationInfosSchema(page));
		if (page !== 'companySurvey') {
			baseSchema = baseSchema.concat(WorkInfosSchema(page));
		}
	}
	
	return baseSchema
};

const getPMValidationSchema = () => {
	return yup.object({
		legalName: yup.string().required('Requis'),
		businessType: yup.string().required('Requis'),
		siren: yup.string().required('Requis').min(9, "9 caractères").max(9, "9 caractères"),
		createdAt: yup.string().required('Requis'),
	});
}


const getShareHolderValidationSchema = () => {
	let ShareHolderBaseSchema = yup.object({
		firstName: yup.string().required('Requis'),
		lastName: yup.string().required('Requis'),
		gender: yup.string().required('Requis'),
		nationality: yup.string().required('Requis'),
		countryBirth: yup.string().required('Requis'),
		cityOfBirth: yup.string().required('Requis'),
		departmentOfBirth: yup.string().required('Requis').max(99, "Max 2 chiffres"),
		birthDate: yup.date()
			.max(new Date(Date.now() - 567648000000), "Vous devez être majeur")
			.typeError("Requis")
			.required("Requis"),
		usPerson: yup.string()
			.oneOf(['true', 'false'], 'Sélection requise')
			.required('Sélection requise'),
		ppe: yup.string()
			.oneOf(['true', 'false'], 'Sélection requise')
			.required('Sélection requise'),
		ppeFunction: yup
			.string()
			.when('ppe', {
				is: 'true',
				then: yup.string().required('Requis'),
			}),
		ppeCountry: yup
			.string()
			.when('ppe', {
				is: 'true',
				then: yup.string().required('Requis'),
			}),
		
		ppeAroundMe: yup.string()
			.oneOf(['true', 'false'], 'Sélection requise')
			.required('Sélection requise'),
		ppeAroundMeFirstName: yup
			.string()
			.when('ppeAroundMe', {
				is: 'true',
				then: yup.string().required('Requis'),
			}),
		ppeAroundMeLastName: yup
			.string()
			.when('ppeAroundMe', {
				is: 'true',
				then: yup.string().required('Requis'),
			}),
		ppeAroundMeFunction: yup
			.string()
			.when('ppeAroundMe', {
				is: 'true',
				then: yup.string().required('Requis'),
			}),
		ppeAroundMeCountry: yup
			.string()
			.when('PPEAroundMe', {
				is: 'true',
				then: yup.string().required('Requis'),
			}),
	});
	return ShareHolderBaseSchema
};

export {getPPValidationSchema, getPMValidationSchema, getShareHolderValidationSchema};
