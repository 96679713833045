import React from 'react';
import { Link, useLocation } from 'react-router-dom';

function NavItem({ children, ...rest }) {
	const location = useLocation();
	
	const isActive = () => {
		// Si pas de destination, retourner false
		if (!rest?.to) return false;
		
		// Nettoyer les URL en supprimant les paramètres de requête
		const currentPath = location.pathname.split('?')[0];
		const targetPath = rest.to.split('?')[0];
		
		// Vérifier si l'URL courante commence par l'URL cible
		return currentPath.startsWith(targetPath);
	};
	
	return (
		<Link
			to={rest?.to}
			className={`links-container__link ${isActive() ? 'links-container__link__active' : ''}`}
			{...rest}
		>
			{children}
		</Link>
	);
}

export default NavItem;
