import React from 'react';
import TextField from '@material-ui/core/TextField';
import OsSelect from "../../Form/Fields/OsSelect";

const LocationInfos = ({ formik, sortedCountries, page }) => {
	return (
		<div className="user-survey-questions-container" style={{ rowGap: 16 }}>
			<TextField
				fullWidth
				id="address"
				name="address"
				label="Adresse"
				value={formik.values.address}
				onChange={formik.handleChange}
				error={formik.touched.address && Boolean(formik.errors.address)}
				helperText={formik.touched.address && formik.errors.address}
				variant="outlined"
				className="full-input"
			/>
			<TextField
				fullWidth
				id="zipCode"
				name="zipCode"
				type="number"
				label="Code postal"
				value={formik.values.zipCode}
				onChange={formik.handleChange}
				error={formik.touched.zipCode && Boolean(formik.errors.zipCode)}
				helperText={formik.touched.zipCode && formik.errors.zipCode}
				variant="outlined"
			/>
			<TextField
				fullWidth
				id="city"
				name="city"
				label="Ville"
				value={formik.values.city}
				onChange={formik.handleChange}
				error={formik.touched.city && Boolean(formik.errors.city)}
				helperText={formik.touched.city && formik.errors.city}
				variant="outlined"
			/>
			<div>
				<OsSelect
					formik={formik}
					name="fiscalCountry"
					label="Pays de résidence fiscale"
					options={sortedCountries.sort().map(country => ({
						value: country.name,
						label: country.name,
					}))}
				/>
				<span className="error">{formik.touched.fiscalCountry && formik.errors.fiscalCountry}</span>
			</div>
			
			{page !== "companySurvey" && (
				<TextField
					fullWidth
					id="nifCode"
					name="nifCode"
					label="Code NIF"
					value={formik.values.nifCode}
					onChange={formik.handleChange}
					error={formik.touched.nifCode && Boolean(formik.errors.nifCode)}
					helperText={formik.touched.nifCode && formik.errors.nifCode}
					variant="outlined"
					className="full-input"
				/>
			)}
		</div>
	);
};

export default LocationInfos;
