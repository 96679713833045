import React, {useEffect, useState} from 'react';
import {useNavigate} from "react-router-dom";

import authenticationStore from "../../../stores/AuthenticationStore.ts";

import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import OsCard from "../../../components/card/basic";
import ImageUpload from "../../../components/ImageUpload";
import OSButton from "../../../components/Button";

import {CircularProgress} from "@mui/material";

import './style.scss';

const CgpSignUpUploads = () => {
	const [user, setUser] = useState(null);
	const [loading, setLoading] = useState(true);
	const [upload, setUpload] = useState(false);
	const [nextStep, setNextStep] = useState(false);
	const [submitting, setSubmitting] = useState(false);
	const [iDCount, setIDCount] = useState(0);
	const [kbisCount, setKbisCount] = useState(0);
	const [statusCount, setStatusCount] = useState(0);
	const [oriasCount, setOriasCount] = useState(0);
	const [rcCount, setRcCount] = useState(0);
	const navigate = useNavigate();
	const notAdminUser = user?.master_ct?.status === "signed" || user?.role === "cgp"
	
	async function fetchUser() {
		await authenticationStore.loadCgpUserData().then(response => {
			setUser(response);
			setLoading(false);
		});
	}
	
	useEffect(() => {
		fetchUser();
	}, []);
	
	useEffect(() => {
		if (nextStep) {
			navigate('/cgp/verification')
		}
	}, [nextStep])
	
	function validUpload() {
		if (notAdminUser) {
			(iDCount > 0) && setUpload(true);
		} else {
			(iDCount > 0 && kbisCount > 0 && oriasCount > 0 && statusCount > 0) && setUpload(true);
		}
	}
	
	function renderButton() {
		if (notAdminUser ? (iDCount === 0) : (iDCount === 0 || kbisCount === 0 || oriasCount === 0 || statusCount === 0 || rcCount === 0)) {
			return <button disabled={true} className="submit-btn">Envoyer les documents</button>
		} else if (submitting) {
			return <button disabled={submitting} className="submit-btn">
				<CircularProgress color="inherit" />
				Documents en cours d'envoi
			</button>
		} else {
			return <OSButton variant="primary" size="large" title="Envoyer les documents" disabled={notAdminUser ? !(iDCount > 0) : !(iDCount > 0 && kbisCount > 0 && oriasCount > 0 && statusCount > 0)} className="submit-btn" onClick={() => validUpload()} />
		}
	}
	
	useEffect(() => {
		if (!loading && user?.kyc_verification_status === "verified") {
			return window.location.replace('/cgp/dashboard');
		}
	}, [user]);
	
	return (
		<div className="auth-container verification-page">
			<div className="os-flex__column ">
				<VerifiedUserIcon />
				<h3 className="os-h3">Vos justificatifs</h3>
				<p className="os-body1 verification-content" style={{marginBottom: 24}}>Afin de continuer, nous avons besoin de vérifier votre identité</p>
				<OsCard>
					{(user?.cgp_company && !notAdminUser) && (
						<>
							<p className="os-subtitle2">Extrait KBIS de moins de 3 mois</p>
							<ImageUpload userSurveyCheck={setKbisCount} upload={upload} submitting={submitting} setSubmitting={setSubmitting} func={setNextStep} type="cgpUpload" documentType="kbis" record={user} />
							
							<p className="os-subtitle2">Copie des derniers statuts à jour</p>
							<ImageUpload userSurveyCheck={setStatusCount} upload={upload} submitting={submitting} setSubmitting={setSubmitting} func={setNextStep} type="cgpUpload" documentType="status" record={user} />
							
							<p className="os-subtitle2">Copie du Certificat ORIAS pour l’année en cours</p>
							<ImageUpload userSurveyCheck={setOriasCount} upload={upload} submitting={submitting} setSubmitting={setSubmitting} func={setNextStep} type="cgpUpload" documentType="orias" record={user} />
							
							<p className="os-subtitle2">Copie de l’Attestation d’Assurance RCPRO pour l’année en cours</p>
							<ImageUpload userSurveyCheck={setRcCount} upload={upload} submitting={submitting} setSubmitting={setSubmitting} func={setNextStep} type="cgpUpload" documentType="rc_pro" record={user} />
						</>
					)}
					<p className="os-subtitle2">Copie de la pièce d’identité du/des gérant(s)</p>
					<ImageUpload userSurveyCheck={setIDCount} upload={upload} submitting={submitting} setSubmitting={setSubmitting} func={setNextStep} type="cgpUpload" documentType="kyc" record={user}/>
					
					<div className="os-flex__column" style={{marginTop: 24, width: "100%", alignItems: "flex-end"}}>
						{renderButton()}
					</div>
				</OsCard>
			</div>
		</div>
	);
};

export default CgpSignUpUploads;
