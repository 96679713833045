import * as yup from 'yup';

export const WorkInfosSchema = (page) => yup.object({
	work: page !== 'CompanySurvey'
		? yup.string().required('Requis')
		: yup.string().notRequired(),
	workCategory: page !== 'CompanySurvey'
		? yup.string().required('Requis')
		: yup.string().notRequired(),
	workSector: page !== 'CompanySurvey'
		? yup.string().required('Requis')
		: yup.string().notRequired(),
});
