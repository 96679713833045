const workCategories = [
  {label: 'Artisans', value: 'Artisans'},
  {label: 'Agriculteurs exploitants', value: 'Agriculteurs exploitants'},
  {label: "Chef d'entreprise", value: "Chef d'entreprise"},
  {label: 'Commerçants et assimilés', value: 'Commerçants et assimilés'},
  {label: 'Cadres et prof. intellectuelles du secteur privé', value: 'Cadres et prof. intellectuelles du secteur privé'},
  {label: 'Employé du secteur privé', value: 'Employé du secteur privé'},
  {label: 'Ouvrier, contremaîtres, agent de maîtrise du secteur privé', value: 'Ouvrier, contremaîtres, agent de maîtrise du secteur privé'},
  {label: 'Employés et agents de la fonction publique', value: 'Employés et agents de la fonction publique'},
  {label: 'Professions indépendantes', value: 'Professions indépendantes'},
  {label: 'Professions libérales', value: 'Professions libérales'},
  {label: 'Professions libérales médicales', value: 'Professions libérales médicales'},
  {label: 'Élèves, étudiants, apprentis', value: 'Élèves, étudiants, apprentis'},
  {label: 'Chômeurs', value: 'Chômeurs'},
  {label: 'Retraités', value: 'Retraités'},
  {label: 'Autres personnes sans activité professionnelle', value: 'Autres personnes sans activité professionnelle'},
  {label: "Métiers de l'industrie du pétrole", value: "Métiers de l'industrie du pétrole"},
  {label: 'Métiers en lien avec le commerce d\'objets culturels', value: 'Métiers en lien avec le commerce d\'objets culturels'},
  {label: 'Professions juridiques', value: 'Professions juridiques'},
];

const pacsRegime = [
  {label: 'Indivision', value: 'Indivision'},
  {label: 'Séparation des biens', value: 'Séparation des biens'},
];
const marriedRegime = [
  {label: 'Communauté universelle', value: 'Communauté universelle'},
  {label: 'Séparation des biens', value: 'Séparation des biens'},
  {label: 'Communauté réduite aux acquêts', value: 'Communauté réduite aux acquêts'},
  {label: 'Participation aux acquêts', value: 'Participation aux acquêts'},
];

const ppeFunction = [
  {value: 'Ambassadeur'},
  {value: 'Consul général ou consul de carrière'},
  {value: "Chef d'état, chef de gouvernement, membre d'un gouvernment national ou de la Comission Européenne"},
  {value: "Dirigeant d'une institution générale publique"},
  {value: "Dirigeant ou membre de l'organe de direction d'une banque centrale"},
  {value: "Membre d'un organe d'administration, de direction ou de surveillance d'une entreprise publique"},
  {value: "Membre d'une assemblé parlementaire nationale"},
  {value: "Membre d'une assemblé du Parlement européen"},
  {value: "Membre d'une cour des comptes"},
  {value: "Membre d'une cour suprême, d'une cour constitutionelle ou d'une autre haute juridiction"},
  {value: "Officier général ou officier supérieur assurant le commandement d'une armée"},
];

const within = [
  {value: "Très court terme (inférieur à 1 an)"},
  {value: "Court terme (entre 1 et 3 ans)"},
  {value: "Moyen terme (entre 3 et 5 ans)"},
  {value: "Long terme (supérieur à 5 ans)"},
];

const goals = [
  {value: "Constituer un capital"},
  {value: "Percevoir des revenus complémentaires"},
  {value: "Valoriser un capital"},
  {value: "Optimiser ma fiscalité"},
  {value: "Diversifier mes actifs"},
  {value: "Préparer ma retraite"},
  {value: "Transmettre mon capital"},
];

const risks = [
	{value: "Vous ne pouvez financièrement supporter aucune perte"},
	{value: "Vous pouvez supporter des pertes financières limitées (moins de 10%)"},
	{value: "Vous pouvez supporter des pertes financières significatives (entre 10% et 25%)"},
	{value: "Vous pouvez supporter des pertes financières très significatives (entre 25% et 50%)"},
	{value: "Vous pouvez supporter des pertes financières jusqu’à concurrence du montant investi"},
	{value: "Vous pouvez supporter des pertes financières au-delà du montant investi"},
];

const engagementType = [
  {value: 'Emprunt immoblier'},
  {value: 'Emprunt consommation'},
  {value: 'Autre emprunt'},
];

const sourceOfFunds = [
  {value: 'Salaire'},
  {value: 'Épargne'},
  {value: 'Intérêts ou dividendes'},
  {value: 'Gain aux jeux'},
  {value: 'Indemnisation'},
  {value: 'Héritage'},
  {value: "Vente d'actifs"},
  {value: 'Rachat de contrat'},
  {value: 'Donation'},
  {value: 'Crédit'},
  {value: "Remploi d'actifs financiers"},
  {value: "Remploi d'actifs immobiliers"},
  {value: 'Autre'},
];

const businessTypes = [
  {label: 'SA', value: 'SA'},
  {label: 'SAS', value: 'SAS'},
  {label: 'SASU', value: 'SASU'},
  {label: 'SARL', value: 'SARL'},
  {label: 'SCI', value: 'SCI'},
  {label: 'SC', value: 'SC'},
  {label: 'ME (Micro-Entreprise)', value: 'ME'},
  {label: 'EURL', value: 'EURL'},
  {label: 'SNC', value: 'SNC'},
  {label: 'Autre', value: 'Autre'},
]

const kycTypes = [
	{ label: "Document d'identité", value: "identity" },
	{ label: "Justificatif de domicile", value: "residency" },
	{ label: "RIB - Relevé d'identité bancaire", value: "rib" },
	{ label: "Provenance des fonds", value: "sourceOfFunds" },
	{ label: "Autre document", value: "other" },
];

const companyKycTypes = [
	{ label: "Document d'identité", value: "identity" },
	{ label: "Justificatif de domicile", value: "residency" },
	{ label: "RIB - Relevé d'identité bancaire", value: "rib" },
	{ label: "Provenance des fonds", value: "sourceOfFunds" },
	{ label: "Certificat ORIAS", value: "orias" },
	{ label: "RC Professionnelle", value: "rc_pro" },
	{ label: "Statuts de la société", value: "status" },
	{ label: "Extrait KBIS", value: "kbis" },
	{ label: "Autre document", value: "other" },
];


const environmentPercentage = [
	{value: '>5%'},
	{value: '>25%'},
	{value: '>50%'},
	{value: 'Aucun'},
]

const businessRevenue = [
	{ value: 'Inférieure à 40 000€' },
	{ value: 'Entre 40 001 et 120 000€' },
	{ value: 'Entre 120 001 et 250 000€' },
	{ value: 'Entre 250 001 et 500 000€' },
	{ value: 'Supérieure à 500 000€' },
];

const businessRealEstateWealth = [
	{ value: 'Inférieure à 100 000€' },
	{ value: 'Entre 100 001 et 500 000€' },
	{ value: 'Entre 500 001 et 1 000 000€' },
	{ value: 'Entre 1 000 001 et 2 000 000€' },
	{ value: 'Supérieure à 2 000 000€' },
];

const businessNonRealEstateWealth = [
	{ value: 'Inférieure à 50 000€' },
	{ value: 'Entre 50 001 et 100 000€' },
	{ value: 'Entre 100 001 et 300 000€' },
	{ value: 'Entre 300 001 et 500 000€' },
	{ value: 'Supérieure à 500 000€' },
];

const investmentFrequencies = [
	{ value: 'Aucune opération' },
	{ value: 'Moins de 3 opérations' },
	{ value: 'Entre 3 et 10 opérations' },
	{ value: 'Plus de 10 opérations' },
];

const investmentMethods = [
	{ value: 'Directement' },
	{ value: 'Via des intermédiaires (ex : OPC, banque, assurance, CGP)' },
];

const financialMarketEntities = [
	{ value: "Une entité qui est tenue d’être agréée ou réglementée pour opérer sur les marchés financiers" },
	{
		value: "Une grande entreprise réunissant deux des critères suivants, au niveau individuel",
		subCriteria: [
			"Total du bilan : 20 000 000 EUR",
			"Chiffre d’affaires net : 40 000 000 EUR",
			"Capitaux propres : 2 000 000 EUR"
		]
	},
	{ value: "Un gouvernement national et régional, y compris organismes publics qui gèrent la dette publique au niveau national ou régional, une banque centrale, une institution internationale et supranationale comme la Banque mondiale, le FMI, la BCE et les autres organisations internationales analogues" },
	{ value: "Un investisseur institutionnel dont l’activité principale consiste à investir dans des instruments financiers, notamment les entités s’occupant de la titrisation d’actifs ou d’autres opérations de financement" },
	{ value: "Aucun des précédents choix" }
];

export {
	workCategories, pacsRegime, ppeFunction, marriedRegime, within, goals,
	engagementType, sourceOfFunds, risks, businessTypes, environmentPercentage,
	businessRevenue, businessRealEstateWealth, businessNonRealEstateWealth, investmentFrequencies,
	investmentMethods, financialMarketEntities, kycTypes, companyKycTypes
};
