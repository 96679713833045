import React from "react";
import TextField from "@mui/material/TextField";

const OsDate = ({ formik, name, label, check18 = false }) => {
	return (
		<TextField
			fullWidth
			id={name}
			name={name}
			type="date"
			label={label}
			value={formik.values[name]}
			onChange={formik.handleChange}
			error={formik.touched[name] && Boolean(formik.errors[name])}
			helperText={formik.touched[name] && formik.errors[name]}
			variant="outlined"
			InputLabelProps={{ shrink: true }}
		/>
	);
};

export default OsDate;
