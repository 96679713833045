import React, {useEffect, useState} from 'react';
import * as yup from "yup";
import {useFormik} from "formik";
import {CSVLink} from "react-csv";
import {useNavigate, useLocation} from "react-router-dom";

import CgpNetwork from "../../../config/Network/CgpNetwork.ts";

import Pagination from "@mui/material/Pagination";
import TextField from "@material-ui/core/TextField";
import {MenuItem} from "@mui/material";
import FileDownloadIcon from "@mui/icons-material/FileDownload";

import ClientsTable from "../../../components/Table/Cgp/ClientsTable";
import EmptyContent from "../../../components/empty-content/EmptyContent";
import {ReactComponent as NoData} from "../../../assets/Illustrations/no-transactions.svg";
import PerPage from "../../../components/PerPage";
import OSButton from "../../../components/Button";
import TableLoader from "../../../components/Loaders/Table/TableLoader";
//import Images from "../../../assets/images";
//import OsCard from "../../../components/card/basic";
import OsModal from "../../../components/Modals";
import ClientCreation from "../../ClientCreation";
import AddIcon from "@mui/icons-material/Add";

import TuneIcon from "@mui/icons-material/Tune";
import CloseIcon from "@mui/icons-material/Close";

import './style.scss';

{/*const ressourceLinks = [
	{id: 1, image: Images.cgp.ressources.first, title: "Maîtrisez Openstone : Le Guide Essentiel pour les CGP", content: "Ce guide vous fournira toutes les informations nécessaires pour naviguer facilement sur la plateforme Openstone et tirer le meilleur parti de ses fonctionnalités pour optimiser la gestion de vos clients.", buttonText: "Consulter", buttonLink: "https://openstone.com/blog/maitrisez-openstone-le-guide-essentiel-pour-les-cgp"},
	{id: 2, image: Images.cgp.ressources.second,title: "Openstone : Votre Portail Vers la Gestion Client Simplifié", content: "Des fonds ultra-performants, généralement ouverts aux investisseurs institutionnels, sont désormais accessibles dès 10 000 euros pour vos clients.", buttonText: "Consulter", buttonLink: "https://openstone.com/blog/openstone-votre-portail-vers-la-gestion-client-simplifie"},
]*/}

const ClientsGestion = ({ cgpUser, workspace }) => {
	const { state } = useLocation();
	const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [clients, setClients] = useState([]);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [meta, setMeta] = useState(null);
  const [recordsCount, setRecordsCount] = useState(null);
  const [submitting, setSubmitting] = useState(false);
	const [filterValues, setFilterValues] = useState(null);
	const [createClientModal, setCreateClientModal] = useState(false);
	const [showFilter, setShowFilter] = useState(false);
  const [resetFilter, setResetFilter] = useState(false);
  const date = new Date();
  date.setDate(date.getDate() - 30);
  const thirtyDaysAgo = date.toLocaleDateString();
	const today = date.toISOString().split("T")[0];

  async function fetchList(filters) {
		setLoading(true);
    const response = await CgpNetwork.getDefaultList(page, perPage, filters, workspace !== 'cgp');
    if (response) {
      response.records ? setClients(response.records) : setClients([]);
      setMeta(response.meta);
      setRecordsCount(response.records_count);
      setLoading(false);
    }
  }
	
	useEffect(() => {
		// Check if cgpUserId is present in the state, if yes add it as a filter
		let initialFilters = [];
		if (state?.cgpUserId) {
			initialFilters.push({ field: 'cgp_user_id', value: state.cgpUserId });
		}
		
		fetchList(initialFilters);
		sessionStorage.removeItem('User');
		sessionStorage.removeItem('UserSurveyStep');
		sessionStorage.removeItem('CompanySurveyStep');
		sessionStorage.removeItem('Company');
	}, [page, perPage, state?.cgpUserId]);

  function handleChange(e) {
    setPage(e.target.textContent);
  }
	
	const renderClients = () => {
		let component;
		const tableLabels = [
			{ id: 'id', label: 'Client' },
			{ id: 'solde', label: 'Solde' },
			{ id: 'account_status', label: 'Typologie' },
			{ id: 'survey_status', label: 'Statut KYC' },
			{ id: 'investor_type', label: "Type d'investisseur" },
			{ id: 'actions', label: 'Actions' },
		];
		
		if (workspace !== 'cgp') {
			tableLabels.splice(1, 0, { id: 'advisor', label: 'Conseiller en charge' });
		}
		
		if (loading) {
			component = <TableLoader />;
		} else if (!loading && clients.length > 0) {
			component = (
				<ClientsTable
					tableData={clients}
					tableLabels={tableLabels}
					workspace={workspace}
				/>
			);
		} else if (!loading && clients.length === 0) {
			component = <EmptyContent illustration={<NoData />} text={filterValues ? "Aucun client ne correspond à ces filtres" : "Vous n’avez pas encore ajouté de client"} />;
		}
		return component;
	};

  const validationSchema = yup.object({})
	
	const formik = useFormik({
		initialValues: {
			recordOwner: state?.cgpUserId || 'all',
			recordType: 'all',
			createdAt: '',
			startDate: '',
			endDate: '',
		},
		validationSchema: validationSchema,
		onSubmit: async (values) => {
			setSubmitting(true);
			const filters = [
				{ field: 'record_type', value: values.recordType },
				{ field: 'created_at', value: values.createdAt }
			];
			
			if (workspace !== 'cgp') {
				filters.push({ field: 'cgp_user_id', value: values.recordOwner });
			}
			
			setFilterValues(filters);
			await fetchList(filters);
			setSubmitting(false);
		},
	});

  const ClientsFilters = () => {
    return (
      <div className="transactions-filters">
        <form onSubmit={formik.handleSubmit}>
          <div className="os-flex gap-32">
						{workspace !== 'cgp' && (
							<TextField
								fullWidth
								id="recordOwner"
								name="recordOwner"
								select
								label="Conseiller en charge"
								value={formik.values.recordOwner}
								onChange={formik.handleChange}
								error={formik.touched.recordOwner && Boolean(formik.errors.recordOwner)}
								helperText={formik.touched.recordOwner && formik.errors.recordOwner}
								variant={"outlined"}>
								<MenuItem value={'all'}>Tous</MenuItem>
								{cgpUser?.cgp_company?.cgp_users?.map((user) => (
									<MenuItem key={user.id} value={user.id}>
										{user.full_name}
									</MenuItem>
								))}
							</TextField>
						)}
            <TextField
              fullWidth
              id="recordType"
              name="recordType"
              select
              label="Typologie du client"
              value={formik.values.recordType}
              onChange={formik.handleChange}
              error={formik.touched.recordType && Boolean(formik.errors.recordType)}
              helperText={formik.touched.recordType && formik.errors.recordType}
              variant={"outlined"}>
              <MenuItem value={'all'}>Tous</MenuItem>
              <MenuItem value={'user'}>Personnes physiques</MenuItem>
              <MenuItem value={'company'}>Personnes morales</MenuItem>
            </TextField>
            <TextField
              fullWidth
              id="createdAt"
              name="createdAt"
              select
              label="Date de création"
              value={formik.values.createdAt}
              onChange={formik.handleChange}
              error={formik.touched.createdAat && Boolean(formik.errors.createdAat)}
              helperText={formik.touched.createdAat && formik.errors.createdAat}
              variant={"outlined"}
            >
              <MenuItem value={'1'}>Hier</MenuItem>
              <MenuItem value={'30'}>30 derniers jours ({thirtyDaysAgo} - {new Date().toLocaleDateString()})</MenuItem>
              <MenuItem value={'180'}>6 derniers mois</MenuItem>
              <MenuItem value={'custom'}>Personnalisée</MenuItem>
            </TextField>
          </div>
          {formik.values.createdAt === 'custom' && (
            <>
              <p className="os-subtitle2">Période personnalisée</p>
              <div className="os-flex gap-32">
                <TextField
                  fullWidth
                  id="startDate"
                  name="startDate"
                  type="date"
                  label="Date de début"
                  value={formik.values.startDate}
                  onChange={formik.handleChange}
                  error={formik.touched.startDate && Boolean(formik.errors.startDate)}
                  helperText={formik.touched.startDate && formik.errors.startDate}
                  variant={"outlined"}
                  InputProps={{inputProps: { min: "2022-01-01", max: today} }}
                  InputLabelProps={{ shrink: true }}
                />
                <TextField
                  fullWidth
                  id="endDate"
                  name="endDate"
                  type="date"
                  label="Date de fin"
                  value={formik.values.endDate}
                  onChange={formik.handleChange}
                  error={formik.touched.endDate && Boolean(formik.errors.endDate)}
                  helperText={formik.touched.endDate && formik.errors.endDate}
                  variant={"outlined"}
                  InputProps={{inputProps: { min: "2022-01-01", max: today} }}
                  InputLabelProps={{ shrink: true }}
                />
              </div>
            </>
          )}
          <div className="os-flex gap-16 modal-btns-container" style={{marginTop: 24}}>
            <OSButton onClick={() => {
							formik.resetForm()
							formik.handleSubmit();
						}} variant="no_border" fit size="" title="Réinitialiser les filtres"/>
						<OSButton
							variant="secondary"
							size="large"
							onClick={formik.handleSubmit}
							title="Rechercher"
							fit
							submitting={submitting}
						/>
					</div>
        </form>
      </div>
    )
  }

  const headers = [
    { label: "Client", key: "name" },
    { label: "Statut KYC", key: "kyc_verification_status" },
  ];

  return (
      <div className="clients-container">
        <div className="os-flex clients-top-container">
          <h5 className="os-h5">Gestion clientèle - {workspace === 'cgp' ? 'Tous mes clients' : 'Tous les clients du cabinet'}</h5>
          <div className="os-flex gap-16" style={{alignItems: "center"}}>
						<OSButton onClick={() => setShowFilter(!showFilter)} variant="primary-full" fit size="large" title={!showFilter ? 'Voir les filtres' : 'Fermer les filtres'} icon={!showFilter ? <TuneIcon/> : <CloseIcon />}/>
						{/*<OSButton variant="primary-full" size="large" title="Importer un fichier" coming fit/>*/}
            <OSButton title="Ajouter un client" variant="primary" size="large" onClick={() => setCreateClientModal(true) } fit icon={<AddIcon />}/>
          </div>
        </div>
				{showFilter && (
					<div className="filters-container">
						<div className="filters-btns">
							<div>
								{false && <CSVLink data={clients} headers={headers} encoding="utf-8" bom="true" filename={"export_transactions.csv"} className="filter-btn filter-btn-selected">
									<FileDownloadIcon />
									<p className="os-body2">Exporter en CSV</p>
								</CSVLink>}
							</div>
						</div>
						<ClientsFilters />
					</div>
				)}
        <p className="os-body1">{recordsCount} résultats</p>
        {renderClients()}
        <div className="os-flex pagination-container gap-16 transactions-filters">
          {((!loading && meta?.total_pages > 1)) ? <Pagination count={!loading ? meta.total_pages : 10} shape="rounded" page={parseInt(page)} onChange={handleChange}/> : <div/>}
          <PerPage perPage={perPage} setPerPage={setPerPage} />
        </div>
				{/*<p className="os-subtitle1" style={{marginTop: 48}}>Nos ressources pour vous </p>
        <div className="os-flex gap-32 ressources-container">
          {ressourceLinks.map((cl, index) =>
              <OsCard>
                <img className="ressources-img" src={cl.image} alt=""/>
                <p className="os-caption">{cl.content}</p>
                <div className="os-flex" style={{justifyContent: "flex-end"}}>
                  <OSButton title={cl.buttonText} link={cl.buttonLink} fit size="small" variant="no_border" target />
                </div>
              </OsCard>
          )}
        </div>*/}
				<OsModal isWhite={false} title="Ajout d'un client" isShowing={createClientModal} hide={() => setCreateClientModal(false)} >
					<ClientCreation />
				</OsModal>
      </div>
  );
};

export default ClientsGestion;
