import React, {useState} from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';
import TextField from '@material-ui/core/TextField';
import {toast} from "react-toastify";

import authenticationStore from "../../../stores/AuthenticationStore.ts";

import './style.scss';
import OsCheckbox from "../../../components/Form/Fields/OsCheckbox";

const AuthSignupForm = ({setPhone, page, collaborator}) => {
  const [loading, setLoading] = useState(false);
  const validationSchema = yup.object({
    firstName: yup.string().required('Requis'),
    lastName: yup.string().required('Requis'),
    email: yup.string().email('Format invalide').required('Requis'),
	  cguAcceptance: yup.array().min(1, 'Vous devez accepter la politique de confidentialité afin de continuer'),
  });

  const formik = useFormik({
    initialValues: {
      firstName: '',
      lastName: '',
      email: '',
      code: '',
	    cguAcceptance: []
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      setLoading(true);
      const response = await authenticationStore.signUp(values.email, values.firstName, values.lastName, 'Web_email', values.code, collaborator === null  ? 'organic' : collaborator);
      if (response.user) {
        setLoading(false);
        toast.success('Votre inscription a bien été prise en compte !', {
          toastId: 'signUpSuccessToast'
        });
        setPhone(true);
      } else if (response.error) {
        toast.error(response.error,{toastId: 'signUpErrorToast'});
      }
    },
  });

  return (
    <>
      <form className="user-signup-form" onSubmit={formik.handleSubmit}>
        <div className="user-signup-container">
          <div className="user-signup-container-names">
            <TextField
              fullWidth
              id="firstName"
              name="firstName"
              label="Prénom *"
              value={formik.values.firstName}
              onChange={formik.handleChange}
              error={formik.touched.firstName && Boolean(formik.errors.firstName)}
              helperText={formik.touched.firstName && formik.errors.firstName}
              variant={"outlined"}
            />
            <TextField
              fullWidth
              id="lastName"
              name="lastName"
              label="Nom de famille *"
              value={formik.values.lastName}
              onChange={formik.handleChange}
              error={formik.touched.lastName && Boolean(formik.errors.lastName)}
              helperText={formik.touched.lastName && formik.errors.lastName}
              variant={"outlined"}
            />
          </div>
          <TextField
            fullWidth
            id="email"
            name="email"
            label="Adresse e-mail *"
            value={formik.values.email}
            onChange={formik.handleChange}
            error={formik.touched.email && Boolean(formik.errors.email)}
            helperText={formik.touched.email && formik.errors.email}
            variant={"outlined"}
          />
          {(page !== 'collaboration' && collaborator === null) && (
            <TextField
              fullWidth
              id="code"
              name="code"
              label="Code de parrainage ?"
              value={formik.values.code}
              onChange={formik.handleChange}
              error={formik.touched.code && Boolean(formik.errors.code)}
              helperText={formik.touched.code && formik.errors.code}
              variant={"outlined"}
            />
          )}
        </div>
	      
	      <div style={{marginBottom: 16}}>
		      <OsCheckbox
			      key={'cguAcceptance'}
			      formik={formik}
			      name="cguAcceptance"
			      value={true}
			      label="J'ai pris connaissance et accepte la politique de confidentialité"
		      />
		      <a href="https://fr.openstone.com/privacy-terms-updated-12-april-2024" className="signup-link">Lien vers la politique de confidentialité</a>
		      <p className="error"> {formik.touched.cguAcceptance && formik.errors.cguAcceptance}</p>
	      </div>

        <div className="user-signup-btn-container">
          <button type="submit" className="user-signup-btn" disabled={loading}>Continuer</button>
        </div>
      </form>
    </>
  );
};

export default AuthSignupForm;
