import React from 'react';
import TextField from '@material-ui/core/TextField';
import OsSelect from "../../Form/Fields/OsSelect";
import OsDate from "../../Form/Fields/OsDate";

const GeneralInfos = ({ formik, sortedCountries, page, isCgp }) => {
	return (
		<>
			<div className="user-survey-questions-container" style={{rowGap: 16}}>
				<OsSelect
					formik={formik}
					name="gender"
					label="Civilité"
					options={[
						{ value: 0, label: 'Monsieur' },
						{ value: 1, label: 'Madame' },
					]}
				/>
				<TextField
					fullWidth
					id="firstName"
					name="firstName"
					label="Prénom"
					value={formik.values.firstName}
					onChange={formik.handleChange}
					error={formik.touched.firstName && Boolean(formik.errors.firstName)}
					helperText={formik.touched.firstName && formik.errors.firstName}
					variant="outlined"
				/>
				<TextField
					fullWidth
					id="lastName"
					name="lastName"
					label="Nom de famille"
					value={formik.values.lastName}
					onChange={formik.handleChange}
					error={formik.touched.lastName && Boolean(formik.errors.lastName)}
					helperText={formik.touched.lastName && formik.errors.lastName}
					variant="outlined"
				/>
				<TextField
					fullWidth
					id="usageName"
					name="usageName"
					label="Nom d'usage"
					value={formik.values.usageName}
					onChange={formik.handleChange}
					error={formik.touched.usageName && Boolean(formik.errors.usageName)}
					helperText={formik.touched.usageName && formik.errors.usageName}
					variant="outlined"
				/>
				<OsDate formik={formik} name="birthDate" label="Date de naissance" check18 />
				<TextField
					fullWidth
					id="cityOfBirth"
					name="cityOfBirth"
					label="Ville de naissance"
					value={formik.values.cityOfBirth}
					onChange={formik.handleChange}
					error={formik.touched.cityOfBirth && Boolean(formik.errors.cityOfBirth)}
					helperText={formik.touched.cityOfBirth && formik.errors.cityOfBirth}
					variant="outlined"
				/>
				<TextField
					fullWidth
					id="departmentOfBirth"
					name="departmentOfBirth"
					type="number"
					label="Département de naissance (Étranger: 99)"
					value={formik.values.departmentOfBirth}
					onChange={(e) => {
						const value = e.target.value;
						if (value === '' || (/^\d{0,2}$/.test(value) && Number(value) <= 99)) {
							formik.handleChange(e);
						}
					}}
					error={formik.touched.departmentOfBirth && Boolean(formik.errors.departmentOfBirth)}
					helperText={formik.touched.departmentOfBirth && formik.errors.departmentOfBirth}
					variant="outlined"
					inputProps={{ maxLength: 2 }}
				/>
				<div>
					<OsSelect
						formik={formik}
						name="countryBirth"
						label="Pays de naissance"
						options={sortedCountries.sort().map(country => ({
							value: country.name,
							label: country.name,
						}))}
					/>
					<span className="error">{formik.touched.countryBirth && formik.errors.countryBirth}</span>
				</div>
				<div>
					<OsSelect
						formik={formik}
						name="nationality"
						label="Nationalité"
						options={sortedCountries.map(country => ({
							value: country.name,
							label: country.name,
						}))}
					/>
					<span className="error">{formik.touched.nationality && formik.errors.nationality}</span>
				</div>
				{isCgp && (
					<>
						<TextField
							fullWidth
							id="email"
							name="email"
							label="E-mail"
							value={formik.values.email}
							onChange={formik.handleChange}
							error={formik.touched.email && Boolean(formik.errors.email)}
							helperText={formik.touched.email && formik.errors.email}
							variant="outlined"
						/>
						
						{page !== "companySurvey" && (
							<TextField
								fullWidth
								id="phoneNumber"
								name="phoneNumber"
								label="Téléphone"
								value={formik.values.phoneNumber}
								onChange={formik.handleChange}
								error={formik.touched.phoneNumber && Boolean(formik.errors.phoneNumber)}
								helperText={formik.touched.phoneNumber && formik.errors.phoneNumber}
								variant="outlined"
							/>
						)}
					</>
				)}
			</div>
		</>
	);
};

export default GeneralInfos;
