import React, {useEffect, useState} from 'react';
import {useSearchParams} from "react-router-dom";
import {Helmet} from "react-helmet";

import authenticationStore from "../../../stores/AuthenticationStore.ts";
import CompanyNetwork from "../../../config/Network/CompanyNetwork.ts";

import AccountVerification from "../../../components/accountVerification";
import OsModal from "../../../components/Modals";
import ChoosePaymentType from "../../../components/ChoosePaimentType";
import ProductCardRecap from "../../Opportunities/Finalise/ProductCardRecap";
import Card from './Card';

import './style.scss';
import Callback from "./Payment/BankPayment/callback";

const product = {
  name: 'Livret P.',
  rate: "Jusqu'à 6",
  horizon: 'Court terme',
  minimum_investment: '100',
  cover_picture: {
    large: 'https://mylivretp.s3.eu-west-3.amazonaws.com/uploads/picture/attachment/268/b6099231-5486-4c0d-8f29-005aa84311b3.png?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIASOCHRAYVMZFG2FMS/20241021/eu-west-3/s3/aws4_request&X-Amz-Date=20241021T191009Z&X-Amz-Expires=604800&X-Amz-SignedHeaders=host&X-Amz-Signature=fb23c053525cdeacbbc51676a2bbb6592313e690a3a4065208c873a8fe98158c'
  }
}

const Index = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [isShowingVericiation, setIsShowingVericiation] = useState(false);
  const defaultProfile = useState(JSON.parse(sessionStorage.getItem('defaultProfile')))[0];
  const [company, setCompany] = useState(JSON.parse(sessionStorage.getItem('company')));
  const [object, setObject] = useState('');
  const [loading, setLoading] = useState(false);
  const [paiementType, setPaiementType] = useState('');

  function rollback(bool) {
    bool && searchParams.delete('status');
  }

  async function fetchCompany() {
    await CompanyNetwork.getCompany(company.id).then(response => {
      setObject(response);
      setLoading(false);
      if (response?.kyc_status !== 'verified') {
        setIsShowingVericiation(true);
      } else {
        setIsShowingVericiation(false)
      }
    });
  }

  useEffect(() => {
    if (defaultProfile) {
      authenticationStore.loadUserData().then(response => {
        setObject(response);
        setLoading(false);
        if (response?.kyc_verification_status !== 'verified') {
          setIsShowingVericiation(true);
        } else {
          setIsShowingVericiation(false);
        }
      });
    } else {
      fetchCompany();
    }
  }, []);

  useEffect(() => {
    searchParams.get('paiementType') === 'card' && setPaiementType('card');
  }, [searchParams])

  function checkStep() {
    if (searchParams.get('status')) {
      return <Callback rollback={rollback}/>
    } else {
      return paiementType === 'card' ? <Card /> : <ChoosePaymentType page="livretp" object={object} user={object}/>
    }
  }

  return (
    <>
      <Helmet>
        <title>Livret P. | Dépôt</title>
      </Helmet>
      <div className="deposit-page-container">
        <div className="deposit-container">
          {checkStep()}
        </div>
        <div className="deposit-product-recap">
          <ProductCardRecap product={product} selectedPromotion={""} currentBalance={object?.euro_balance} minimumInvestment={product.minimum_investment}/>
        </div>
      </div>
      <OsModal title="Vérifier votre idendité pour activer votre compte" isShowing={isShowingVericiation} cantClose>
        <AccountVerification profile={defaultProfile ? 'user' : 'company'}/>
      </OsModal>
    </>
  );
};

export default Index;
