import React, {useState} from 'react';
import { useFormik } from 'formik';
import { toast } from "react-toastify";
import moment from "moment";

import CgpNetwork from "../../../../../config/Network/CgpNetwork.ts";
import CompanyNetwork from "../../../../../config/Network/CompanyNetwork.ts";

import sortedCountries from "../../../../../config/countries";
import OSButton from "../../../../../components/Button";
import OsCard from "../../../../../components/card/basic";
import GeneralInfos from "../../../../../components/Survey/GeneralInformations/GeneralInfos";
import LocationInfos from "../../../../../components/Survey/LocationInfos/LocationInfos";
import SpecialStatusSection from "../../../../../components/Survey/SpecialStatuses/SpecialStatusSection";
import {handleSurveyContinue} from "../../../../../components/Survey/SurveyUtils/CheckContinue";
import {getPPValidationSchema} from "../../../../../components/Survey/GeneralInformations/validationSchemas";

const RepresentativeForm = ({ mode = 'create', handleStep, page, isCgp, company }) => {
	const [submitting, setSubmitting] = useState(false);
	const user = company
	
	// Formik Initialization
	const formik = useFormik({
		initialValues: {
			email: user.email || '',
			firstName: user.first_name || '',
			lastName: user.last_name || '',
			usageName: user.usage_name || '',
			gender: user.gender === "male" ? 0 : 1,
			nationality: user.address_country || '',
			countryBirth: user.country_of_birth || '',
			birthDate: user.date_of_birth ? moment(user.date_of_birth).format('YYYY-MM-DD') : '',
			cityOfBirth: user.city_of_birth || '',
			departmentOfBirth: user.departement_of_birth || '',
			address: user.address_line_1 || '',
			zipCode: user.address_zip_code || '',
			city: user.address_city || '',
			fiscalCountry: user.fiscal_country || '',
			usPerson: user.us_person ? "true" : "false",
			ppe: user.ppe ? "true" : "false",
			ppeFunction: user.ppe_function || '',
			ppeCountry: user.ppe_country || '',
			ppeAroundMe: user.ppe_around_me ? "true" : "false",
			ppeAroundMeFirstName: user?.ppe_around_me_infos?.firstName || '',
			ppeAroundMeLastName: user?.ppe_around_me_infos?.lastName || '',
			ppeAroundMeFunction: user?.ppe_around_me_infos?.function || '',
			ppeAroundMeCountry: user?.ppe_around_me_infos?.country || '',
		},
		validationSchema: getPPValidationSchema("companySurvey"),
		onSubmit: async (values) => {
			setSubmitting(true);
			const valuesToSubmit = {
				email: values.email,
				first_name: values.firstName || '',
				last_name: values.lastName || '',
				usage_name: values.usageName || '',
				gender: values.gender,
				fiscal_country: values.fiscalCountry,
				address_country: values.nationality || '',
				country_of_birth: values.countryBirth || '',
				date_of_birth: values.birthDate || '',
				city_of_birth: values.cityOfBirth || '',
				departement_of_birth: values.departmentOfBirth || '',
				address_line_1: values.address || '',
				address_zip_code: values.zipCode || '',
				address_city: values.city || '',
				us_person: values.usPerson === "true",
				ppe: values.ppe === "true",
				ppe_function: values.ppeFunction || '',
				ppe_country: values.ppeCountry || '',
				ppe_around_me: values.ppeAroundMe === "true",
				ppe_around_me_infos: {
					firstName: values.ppeAroundMeFirstName,
					lastName: values.ppeAroundMeLastName,
					function: values.ppeAroundMeFunction,
					country: values.ppeAroundMeCountry,
				} || {},
			};
			
			async function handleRequest() {
				if (isCgp) {
					await CgpNetwork.updateRecord(valuesToSubmit, company.id, 'Company')
				} else {
					await CompanyNetwork.updateCompany(company.id, valuesToSubmit)
				}
			}
			const response = handleRequest();
			if (response) {
				setSubmitting(false);
				toast.success('Les informations du dirigeant ont bien été enregistrées', {
					toastId: 'completeInfosSuccessToast'
				});
				window.scrollTo(0, 0);
				handleStep(true);
			} else {
				toast.error("Une erreur est survenue lors de l'enregistrement des informations",{
					toastId: 'completeInfosErrorToast'
				});
			}
		},
	});
	
	return (
		<form className="os-user-survey-form" onSubmit={formik.handleSubmit}>
			<div style={{marginBottom: 32, marginTop: 24}}>
				<h5 className="os-h5">Informations du représentant légal</h5>
				<span className="os-caption error">Tous les champs sont obligatoires.</span>
			</div>
			<OsCard title="Informations civiles">
				<GeneralInfos formik={formik} sortedCountries={sortedCountries} page="companySurvey" isCgp={isCgp} />
			</OsCard>
			
			{/* SITUATION FISCALE */}
			<OsCard title="Adresse fiscale" spaced="up">
				<LocationInfos formik={formik} sortedCountries={sortedCountries} page="companySurvey" isCgp={isCgp} />
			</OsCard>
			
			
			<OsCard title="Statuts particuliers" spaced="up">
				<SpecialStatusSection formik={formik} sortedCountries={sortedCountries} />
			</OsCard>

			<div className="user-survey-btn-container" style={{marginTop: 32}}>
				{Object.keys(formik.errors).length !== 0 && <span className="error">Vous devez répondre à toutes les questions pour pouvoir continuer</span>}
				<OSButton variant="primary" size="large" fit disabled={submitting} title="Continuer" type="submit" onClick={() => handleSurveyContinue(formik)} />
			</div>
		</form>
	);
};

export default RepresentativeForm;

