import { toast } from 'react-toastify';

export const handleSurveyContinue = async (formik) => {
	const errors = await formik.validateForm(); // Validate all fields
	formik.setTouched(
		Object.keys(formik.values).reduce((acc, key) => ({ ...acc, [key]: true }), {})
	); // Mark all fields as touched
	
	if (Object.keys(errors).length > 0) {
		toast.error("Veuillez corriger les erreurs dans le formulaire.");
	} else {
		formik.handleSubmit(); // Proceed with submission
	}
};
