import React, { Suspense, useEffect, useReducer, useCallback } from 'react';
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import { toast } from "react-toastify";
import { useLocation } from "react-router";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";

import AuthenticationNetwork from "../../config/Network/AuthenticationNetwork.ts";
import CgpNetwork from "../../config/Network/CgpNetwork.ts";

import UserForm from "./Steps/PersonnalInfos";
import Knowledge from "./Steps/Knowledge";
import Revenus from "./Steps/Revenus";
import Uploads from "./Steps/Uploads";
import Statement from "./Steps/Statements";
import FormLoader from "../../components/Loaders/KYC";

import OSButton from "../../components/Button";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

import './style.scss';

// Initial State
const initialState = {
	user: {},
	loading: true,
	activeStep: parseInt(sessionStorage.getItem('UserSurveyStep')) || 0,
	stepName: '',
};

// Reducer function
const reducer = (state, action) => {
	switch (action.type) {
		case 'SET_USER':
			return { ...state, user: action.payload, loading: false };
		case 'SET_LOADING':
			return { ...state, loading: action.payload };
		case 'SET_STEP':
			return {
				...state,
				activeStep: action.payload,
				stepName: action.stepName || state.stepName,
			};
		default:
			return state;
	}
};

// Steps for survey
const getSteps = (isProfessional) => {
	let baseSteps = [
		{ id: 0, title: "Informations" },
		{ id: 1, title: "Connaissances et objectifs" },
		{ id: 2, title: "Situation financière" },
		{ id: 3, title: "Justificatifs" },
		{ id: 4, title: "Déclarations" },
	];
	
	if (isProfessional) {
		// Add or modify steps for professionals
		// baseSteps.splice(2, 0, { id: 2, title: "Informations Professionnelles" }); Insert step at index 2
		baseSteps = baseSteps.filter(step => step.id !== 1 && step.id !== 2);
	}
	
	return baseSteps;
};

// Main Component
const UserSurvey = ({ editing }) => {
	const [state, dispatch] = useReducer(reducer, initialState);
	//const [searchParams, setSearchParams] = useSearchParams();
	const navigate = useNavigate();
	const location = useLocation();
	const redirectionPath = location.pathname.substring(0, location.pathname.lastIndexOf('/'));
	const isCgp = window.location.href.includes("cgp");
	const localUser = isCgp ? JSON.parse(sessionStorage.getItem('User')) : state.user;
	const isProfessional = false;
	
	// Determine if the user is professional from query params
	{/*editing
	? localUser?.investment_profile === 'professional'
	: searchParams.get('professionalCategory') === 'professional';*/}
	
	// Dynamic steps based on query params
	const steps = getSteps(isProfessional);
	
	// Fetch or edit user data based on context
	const fetchUserData = useCallback(async () => {
		dispatch({ type: 'SET_LOADING', payload: true });
		
		try {
			const response = isCgp && (editing || state.activeStep > 0)
				? await CgpNetwork.editClient(localUser, 'User')
				: (!isCgp && await AuthenticationNetwork.getUserForSurvey())
			
			dispatch({ type: 'SET_USER', payload: response });
		} catch (error) {
			toast.error("Une erreur est survenue, veuillez nous contacter");
			dispatch({ type: 'SET_LOADING', payload: false });
		}
	}, [editing, isCgp, state.activeStep]);
	
	// Initialize data
	useEffect(() => {
		fetchUserData();
	}, [state.activeStep, fetchUserData]);
	
	// Update step name
	useEffect(() => {
		const stepTitle = steps[state.activeStep]?.title || '';
		dispatch({ type: 'SET_STEP', payload: state.activeStep, stepName: stepTitle });
		sessionStorage.setItem('UserSurveyStep', state.activeStep.toString());
	}, [state.activeStep]);
	
	// Handle step navigation
	const handleStep = useCallback(
		(forward = true) => {
			const newStep = forward ? state.activeStep + 1 : state.activeStep - 1;
			dispatch({ type: 'SET_STEP', payload: newStep });
		},
		[state.activeStep]
	);
	
	const StepContent = () => {
		if (state.loading) return <FormLoader style={{minHeight: "80vh", marginTop: 64}}/>;
		
		const props = { handleStep, user: state.user, isCgp, page: "userForm" };
		
		// Dynamic mapping between step IDs and components
		const stepComponents = {
			"Informations": <UserForm {...props} mode={editing ? 'edit' : 'create'} isProfessional />,
			"Connaissances et objectifs": <Knowledge {...props} />,
			"Situation financière": <Revenus {...props} />,
			"Informations professionnelles": <Knowledge {...props} />, // Example: reusing Knowledge for professionals
			"Justificatifs": <Uploads {...props} />,
			"Déclarations": <Statement {...props} />,
		};
		
		// Get current step title dynamically
		const currentStep = steps[state.activeStep]?.title;
		
		// Render component or fallback to default navigation
		return stepComponents[currentStep] || navigate(isCgp ? '/cgp/clients' : `${redirectionPath}?user_survey=completed`);
	};
	
	const handleBackNavigation = () => {
		if (editing && state.user?.id) {
			return {
				title: `Revenir au profil de ${state.user.first_name || ""} ${state.user.last_name || ""}`.trim(),
				path: `/cgp/clients/${state.user.id}`,
				state: { listableType: "User" },
			};
		}
		
		if (state.user?.id) {
			return {
				title: "Revenir à la page précédente",
				path: -1, // Indicates going back dynamically
			};
		}
		
		return {
			title: "Revenir à la liste des clients",
			path: "/cgp/clients",
		};
	};
	
	
	const handleDirectStepChange = (step) => dispatch({ type: 'SET_STEP', payload: step });
	
	return (
		<div className="user-survey-container">
			<Helmet>
				<title>Openstone | Questionnaire client</title>
			</Helmet>
			
			{isCgp && (
				<OSButton
					title={handleBackNavigation().title}
					onClick={() => {
						const { path, state } = handleBackNavigation();
						if (path === -1) {
							navigate(-1); // Go back dynamically
						} else {
							navigate(path, { state });
						}
					}}
					variant="no_border"
					size="medium"
					fit
					icon={<ArrowBackIcon />}
				/>
			)}
			
			<div className="top-survey-container">
				<h4>{isCgp ? 'Questionnaire client' : 'Questionnaire'}</h4>
				{/*false && (
					<OSButton
						icon={<DownloadIcon />}
						fit
						variant="no_border"
						size="small"
						title="Télécharger le questionnaire"
						link="#"
					/>
				)*/}
			</div>
			
			<Stepper activeStep={state.activeStep} alternativeLabel>
				{steps.map((step, index) => (
					<Step key={index} onClick={() => editing && handleDirectStepChange(index)}>
						<StepLabel>{step.title}</StepLabel>
					</Step>
				))}
			</Stepper>
			
			<Suspense fallback={<div>Chargement...</div>}>
				<StepContent />
			</Suspense>
		</div>
	);
};

export default UserSurvey;
