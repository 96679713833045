import React, {useEffect, useState} from 'react';
import {useNavigate} from "react-router-dom";

import CgpNetwork from "../../../../config/Network/CgpNetwork.ts";

import {Autocomplete, TextField} from "@mui/material";
import DropdownButton from "../../../Button/Dropdown";

const AutocompleteClient = ({record, updateRecord}) => {
  const [clients, setClients] = useState([]);
  const navigate = useNavigate();
	const workspace = sessionStorage.getItem('activeWorkspace') || 'cgp';

  async function fetchList(filters) {
    const response = await CgpNetwork.getDefaultList(1, 100, filters, workspace);
    if (response?.records?.length > 0) {
      setClients(response.records)
    } else {
      setClients([]);
      updateRecord('empty')
    }
  }

  useEffect(() => {
    fetchList();
  }, []);

  return (
    <div className="choose-client-container">
      {clients?.length > 0 ? (
        <>
          <p className="os-subtitle2" style={{margin: "6px 0"}}>Choisir un client pour souscrire au produit :</p>
          <Autocomplete
            fullWidth
            disablePortal
            id="client-search"
            noOptionsText="Aucune correspondance"
            defaultValue={record}
            options={clients}
            getOptionLabel={(option) => (option.record && option.record.name) ? option.record.name : ''}
            renderInput={(params) => <TextField {...params} label="Choisir un client" />}
            onChange={(event, newValue) => {
              updateRecord(newValue)
            }}
          />
        </>
      ) : (
        <>
          <p className="os-subtitle2 ">Ajouter un client afin de pouvoir réaliser une souscription</p>
          <DropdownButton title="Ajouter un client" variant="primary" size="large"
              items={[
                {label: 'Personne physique', onClick: () => navigate('/cgp/clients/creation/personne-physique')},
                {label: 'Personne morale', onClick: () => navigate('/cgp/clients/creation/personne-morale')}
							]}
          />
        </>
      )}
    </div>
  );
};

export default AutocompleteClient;
