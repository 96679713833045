import React, {useEffect, useState} from 'react';
import {useSearchParams} from "react-router-dom";
import {Helmet} from "react-helmet";

import ProductNework from "../../config/Network/ProductNework.ts";

import OpportunityCardLoader from "../../components/Loaders/Opportunities/OpportunityCardLoader";
import EmptyContent from "../../components/empty-content/EmptyContent";
import OpportunityCard from "../../components/Product/OpportunityCard";
import LargeOpportunityCard from "../../components/Product/OpportunityCard/large";

import {ReactComponent as NoData} from "../../assets/Illustrations/ic_tree_view.svg";

import './style.scss';

const Opportunities = () => {
  //const lastPath = window.location.href.substring(window.location.href.lastIndexOf('/') + 1);
  const [searchParams, setSearchParams] = useSearchParams();
  const [opportunities, setOpportunities] = useState([]);
  const [loading, setLoading] = useState(true);
	const isCgp = useState(sessionStorage.getItem('CgpUserAuthToken') !== null)[0];
  //const [selectedOpportunity, setSelectedOpportunity] = useState(null);
  //const [setSelectedDistribution] = useState((lastPath !== 'opportunites' && searchParams.get('distribution')) || null);
  //const [setSelectedMinimum] = useState((lastPath !== 'opportunites' && searchParams.get('minimum')) || null);
  const [refresh, setRefresh] = useState(false);
	
  const fetchOpportunities = async () => {
		setLoading(true);
		
		// Filter out 'userType' if it exists in searchParams
		const params = {};
		if (searchParams.get('category_name')) {
			params.category_name = searchParams.get('category_name');
		}
		
		const response = await ProductNework.getProducts(params.category_name || null);
		if (response) {
			setOpportunities(response);
			setLoading(false);
		}
  }

  useEffect(() => {
		setSearchParams({userType: isCgp ? 'cgp' : 'part'})
		fetchOpportunities();
  }, [searchParams])

  useEffect(() => {
    refresh && fetchOpportunities()
  }, [refresh])
	
	const renderLargeOpportunity = () => {
		if (loading) {
			return (
				<div className="opportunity-loading-card" key="loader-opportunity-1">
					<OpportunityCardLoader />
				</div>
			);
		} else if (!loading && opportunities.length > 0) {
			const openOpportunity = opportunities.find(opportunity => opportunity.position === 'top');
			if (openOpportunity) {
				return <LargeOpportunityCard category={openOpportunity} opportunity={openOpportunity} needRefresh={setRefresh} />;
			}
		}
		return null;
	}
	
	const renderOpportunities = () => {
		if (loading) {
			return [1, 2, 3, 4, 5, 6].map(i => (
				<div className="opportunity-loading-card" key={`loader-opportunity-${i}`}>
					<OpportunityCardLoader />
				</div>
			));
		} else if (!loading && opportunities.length === 0) {
			return (
				<EmptyContent
					illustration={<NoData />}
					text="Nous n'avons pas encore de produits dans cette catégorie"
				/>
			);
		} else if (!loading && opportunities.length > 0) {
			const nonTopOpportunities = opportunities.filter(o => o.position !== 'top');
			const sortedOpportunities = nonTopOpportunities.sort((a, b) => {
				// Vérifier si le nom commence par "OS"
				const aStartsWithOS = a.name.startsWith('OS');
				const bStartsWithOS = b.name.startsWith('OS');
				
				if (aStartsWithOS && !bStartsWithOS) return -1;
				if (!aStartsWithOS && bStartsWithOS) return 1;
				
				// Si les deux commencent par "OS" ou aucun ne commence par "OS",
				// trier alphabétiquement
				return a.name.localeCompare(b.name);
			});
			
			return sortedOpportunities.reverse().map(o => (
				<OpportunityCard
					category={o}
					opportunity={o}
					key={`${o.name}-${o.id}`}
					needRefresh={setRefresh}
				/>
			));
		}
	}
	
    return (
      <>
        <Helmet>
          <title>Opportunités | Openstone</title>
        </Helmet>
        <div className="opportunities-page-container">
          <h4 className="os-h4">Nos offres d'investissement</h4>
	        <div className="os-grid two-grid gap-24 opportunities-container">
						{renderLargeOpportunity()}
	          {renderOpportunities()}
	        </div>
        </div>
      </>
    );
};

export default Opportunities;
