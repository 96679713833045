import React from 'react';
import { FormControlLabel, Radio } from '@material-ui/core';

const OsRadio = ({ formik, name, value, label }) => {
  // Check if the radio button should be checked
  const isChecked = formik.values[name] === value;

  // Handle change event
  const handleChange = (event) => {
		formik.setFieldValue(name, event.target.value);
		formik.setFieldTouched(name, true); // Mark field as touched
  };

	const labelStyle = {
		backgroundColor: '#F2F5F8',
		padding: "8px 16px 8px 4px",
		borderRadius: 2,
		width: '100%',
		border: isChecked ? "1px solid #4D878F" : "1px solid #DFE3E8",
		boxSizing: "border-box",
		cursor: 'pointer',
		marginLeft: 0
	};
	
	return (
    <>
      <FormControlLabel
        control={
          <Radio
            checked={isChecked}
            onChange={handleChange}
            value={value}
            name={name}
            style={{ color: '#4D878F', cursor: 'pointer' }}
          />
        }
        label={label}
        style={labelStyle}
      />
    </>
  );
};

export default OsRadio;
