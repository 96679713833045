import React, { useEffect } from 'react';
import { useFormik } from 'formik';
import TextField from '@material-ui/core/TextField';

import OsSelect from "../../../../../components/Form/Fields/OsSelect";
import { ppeFunction } from "../../../../KycSurvey/Steps/choices";

import sortedCountries from "../../../../../config/countries";
import OsRadio from "../../../../../components/Form/Fields/OsRadio";
import OsDate from "../../../../../components/Form/Fields/OsDate";

// Fonction utilitaire pour initialiser les valeurs des actionnaires
const getInitialValues = (shareHolder = {}) => {
	const data = shareHolder.formData || {};
	
	return {
		gender: data.gender ?? '',
		firstName: data.firstName ?? '',
		lastName: data.lastName ?? '',
		birthDate: data.birthDate ?? '',
		cityOfBirth: data.cityOfBirth ?? '',
		departmentOfBirth: data.departmentOfBirth?.toString() ?? '',
		countryBirth: data.countryBirth ?? '',
		nationality: data.nationality ?? '',
		ppe: data.ppe === "true" ? "true" : "false",
		ppeFunction: data.ppeFunction ?? '',
		ppeCountry: data.ppeCountry ?? '',
		ppeAroundMe: data.ppeAroundMe === "true" ? "true" : "false",
		ppeAroundMeFirstName: data.ppeAroundMeFirstName ?? '',
		ppeAroundMeLastName: data.ppeAroundMeLastName ?? '',
		ppeAroundMeFunction: data.ppeAroundMeFunction ?? '',
		ppeAroundMeCountry: data.ppeAroundMeCountry ?? '',
		usageName: data.usageName ?? '',
		usPerson: data.usPerson === "true" ? "true" : "false",
	}
};

const ShareHolderForm = ({
													 shareHolder = {},
													 errors = {},
													 onFormChange
												 }) => {
	// Initialisation de Formik
	const formik = useFormik({
		initialValues: getInitialValues(shareHolder),
		enableReinitialize: true,
		onSubmit: () => {}, // Pas de soumission directe ici, gérée par le parent
	});
	
	// Synchronisation avec le parent lors des changements de formulaire
	useEffect(() => {
		onFormChange(formik.values);
	}, [formik.values, onFormChange]);
	
	// Réinitialise le formulaire uniquement si les props `shareHolder` changent
	useEffect(() => {
		formik.resetForm({ values: getInitialValues(shareHolder) });
	}, [shareHolder]);
	
	return (
		<form className="os-user-survey-form" onSubmit={formik.handleSubmit}>
			<p className="os-subtitle1" style={{marginBottom: 24}}>Informations personelles</p>
			<div className="user-survey-questions-container" style={{rowGap: 16}}>
				<OsSelect
					formik={formik}
					name="gender"
					label="Civilité"
					options={[
						{ value: 0, label: 'Monsieur' },
						{ value: 1, label: 'Madame' },
					]}
				/>
				<TextField
					fullWidth
					id="firstName"
					name="firstName"
					label="Prénom"
					value={formik.values.firstName}
					onChange={formik.handleChange}
					error={errors.firstName}
					helperText={errors.firstName}
					variant="outlined"
				/>
				<TextField
					fullWidth
					id="lastName"
					name="lastName"
					label="Nom de famille"
					value={formik.values.lastName}
					onChange={formik.handleChange}
					error={errors.lastName}
					helperText={errors.lastName}
					variant="outlined"
				/>
				<TextField
					fullWidth
					id="usageName"
					name="usageName"
					label="Nom d'usage"
					value={formik.values.usageName}
					onChange={formik.handleChange}
					error={errors.usageName}
					helperText={errors.usageName}
					variant="outlined"
				/>
				
				<OsDate formik={formik} name="birthDate" label="Date de naissance" check18 />
				
				<TextField
					fullWidth
					id="cityOfBirth"
					name="cityOfBirth"
					label="Ville de naissance"
					value={formik.values.cityOfBirth}
					onChange={formik.handleChange}
					error={errors.cityOfBirth}
					helperText={errors.cityOfBirth}
					variant="outlined"
				/>
				<TextField
					fullWidth
					id="departmentOfBirth"
					name="departmentOfBirth"
					type="number"
					label="Département de naissance (Étranger: 99)"
					value={formik.values.departmentOfBirth}
					onChange={(e) => {
						const value = e.target.value;
						if (value === '' || (/^\d{0,2}$/.test(value) && Number(value) <= 99)) {
							formik.handleChange(e);
						}
					}}
					error={errors.departmentOfBirth}
					helperText={errors.departmentOfBirth}
					variant="outlined"
					inputProps={{ maxLength: 2 }}
				/>
				<div>
					<OsSelect
						formik={formik}
						name="countryBirth"
						label="Pays de naissance"
						options={sortedCountries.sort().map(country => ({
							value: country.name,
							label: country.name,
						}))}
					/>
					<span className="error">{errors.countryBirth}</span>
				</div>
				<div>
					<OsSelect
						formik={formik}
						name="nationality"
						label="Nationalité"
						options={sortedCountries.map(country => ({
							value: country.name,
							label: country.name,
						}))}
					/>
					<span className="error">{errors.nationality}</span>
				</div>
			</div>
			
			{/* Profil invest */}
			<>
				<p className="os-subtitle1" style={{marginBottom: 24}}>Statuts particuliers</p>
				<p className="os-body1">Etes-vous une US Person ou avez-vous l’intention de l’être ?</p>
				<div className="os-flex__column gap-16" style={{marginBottom: 40}}>
					<div className="full-input radio-inputs" style={{marginBottom: "0 !important"}}>
						<div className="user-survey-questions-container" style={{marginBottom: "0 !important"}}>
							<OsRadio
								formik={formik}
								name="usPerson"
								value="true"
								label="Oui"
							/>
							<OsRadio
								formik={formik}
								name="usPerson"
								value="false"
								label="Non"
							/>
						</div>
						<span className="error">{errors.usPerson}</span>
					</div>
					<p className="os-body1">Exercez-vous (ou avez-vous exercé), depuis moins d’un an, des fonctions politiques, juridictionnelle ou administrative pour le compte d’un État ou d’une institution internationale publique créée par un traité (1)</p>
					<div className="user-survey-questions-container radio-inputs">
						<OsRadio
							formik={formik}
							name="ppe"
							value="true"
							label="Oui"
						/>
						<OsRadio
							formik={formik}
							name="ppe"
							value="false"
							label="Non"
						/>
					</div>
					<span className="error">{errors.ppe}</span>
					{
						formik.values.ppe === "true" && (
							<>
								<div className="user-survey-questions-container">
									<div>
										<OsSelect
											formik={formik}
											name="ppeFunction"
											label="Fonction"
											options={ppeFunction.map(ppef => ({
												value: ppef.value,
												label: ppef.value,
											}))}
										/>
										<span className="error">{errors.ppeFunction}</span>
									</div>
									<div>
										<OsSelect
											formik={formik}
											name="ppeCountry"
											label="Pays"
											options={sortedCountries.map(country => ({
												value: country.name,
												label: country.name,
											}))}
										/>
										<span className="error">{errors.ppeFunction}</span>
									</div>
								</div>
								<span className="os-caption">(1) Les fonctions politiques, juridictionnelles ou administratives concernées sont celles définies à l’article R. 561-18 I du Comofi</span>
							</>
						)
					}
				</div>
				
				<p className="os-body1">Si une personne de la famille (2) ou de l’entourage (3) du bénéficiaire effectif est une personne politiquement exposée, répondez aux questions ci-dessous.</p>
				<div className="user-survey-questions-container radio-inputs">
					<OsRadio
						formik={formik}
						name="ppeAroundMe"
						value="true"
						label="Oui"
					/>
					<OsRadio
						formik={formik}
						name="ppeAroundMe"
						value="false"
						label="Non"
					/>
				</div>
				<span className="error">{errors.ppeAroundMe}</span>
				{
					formik.values.ppeAroundMe === "true" && (
						<>
							<div className="user-survey-questions-container">
								<TextField
									fullWidth
									id="ppeAroundMeFirstName"
									name="ppeAroundMeFirstName"
									label="Prénom"
									value={formik.values.ppeAroundMeFirstName}
									onChange={formik.handleChange}
									error={errors.ppeAroundMeFirstName}
									helperText={errors.ppeAroundMeFirstName}
									variant="outlined"
								/>
								<TextField
									fullWidth
									id="ppeAroundMeLastName"
									name="ppeAroundMeLastName"
									label="Nom de famille"
									value={formik.values.ppeAroundMeLastName}
									onChange={formik.handleChange}
									error={errors.ppeAroundMeLastName}
									helperText={errors.ppeAroundMeLastName}
									variant="outlined"
								/>
							</div>
							<div className="user-survey-questions-container">
								<div>
									<OsSelect
										formik={formik}
										name="ppeAroundMeFunction"
										label="Fonction"
										options={ppeFunction.map(ppef => ({
											value: ppef.value,
											label: ppef.value,
										}))}
									/>
									<span className="error">{errors.ppeAroundMeFunction}</span>
								</div>
								<div>
									<OsSelect
										formik={formik}
										name="ppeAroundMeCountry"
										label="Pays"
										options={sortedCountries.map(country => ({
											value: country.name,
											label: country.name,
										}))}
									/>
									<span className="error">{errors.ppeAroundMeCountry}</span>
								</div>
							</div>
							<span className="os-caption">(1) Les fonctions politiques, juridictionnelles ou administratives concernées sont celles définies à l’article R. 561-18 I du Comofi</span>
						</>
					)
				}
			</>
			<div className="user-survey-btn-container" style={{marginTop: 32}}>
				{Object.keys(formik.errors).length !== 0 && <span className="error">Vous devez répondre à toutes les questions pour pouvoir continuer</span>}
			</div>
		</form>
	);
};

export default ShareHolderForm;
