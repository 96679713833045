import React from 'react';
import Lottie from "react-lottie";
import Animations from "../../../assets/Animations";
import OSButton from "../../../components/Button";

import './style.scss';

const Success = () => {
  const productUrl = sessionStorage.getItem('productUrl');
  const defaultOptions = {
    loop: false,
    autoplay: true,
    animationData: Animations.success_confetti,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice"
    }
  };

  return (
    <div className="success-container">
      <div className="success-animation">
        <Lottie options={defaultOptions}/>
      </div>
      <h4 className="os-h4">Vos documents ont bien été reçus.</h4>
      <p className="success-content">Les documents seront validés par nos équipes.</p>
      <p className="success-content">Vous pouvez dès à présent investir sur les produits Openstone</p>
      <div className="success-btn">
        <OSButton link="/overview" title="Voir tous les produits" variant="primary" size="large" />
        {productUrl && <OSButton link={productUrl} title="Continuer mon investissement" variant="secondary" size="large"/>}
      </div>
    </div>
  );
};

export default Success;
