import React, {useEffect, useState} from 'react';
import {Helmet} from "react-helmet";
import Divider from "@mui/material/Divider";

import authenticationStore from "../../../stores/AuthenticationStore.ts";
import CompanyNetwork from "../../../config/Network/CompanyNetwork.ts";

import SetAmount from "../../../components/Deposit/SetAmount";
import Otp from "../../../components/Otp";
import OsModal from "../../../components/Modals";
import AccountVerification from "../../../components/accountVerification";
import MoneyHelper from "../../../Helpers/MoneyHelper";
import Signature from "../../Opportunities/Finalise/Steps/Signature";
import WithdrawForm from "../../../Forms/User/WithdrawForm";
import {Step, StepLabel, Stepper} from "@mui/material";

import './style.scss';

const withdrawalSteps = [
  {title: "Formulaire de retrait"},
  {title: "Signature bulletin de retrait"}
]

const Withdraw = () => {
  const defaultProfile = useState(JSON.parse(sessionStorage.getItem('defaultProfile')))[0];
  const [company] = useState(JSON.parse(sessionStorage.getItem('company')));
  const [object, setObject] = useState({});
  const [loading, setLoading] = useState(true);
  const [isShowingVericiation, setIsShowingVericiation] = useState(false);
  const [amount, setAmount] = useState(0);
  const [otp, setOtp] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const product = {
    id: 9,
    category_id: 5,
    category_name: "Livret P",
    name: 'Livret P.',
    rate: "Jusqu'à 6",
    horizon: 'Court terme',
    minimum_investment: '100',
    cover_picture: {
      large: 'https://mylivretp.s3.eu-west-3.amazonaws.com/uploads/picture/attachment/136/7ee6b54c-4fe5-4fa3-b3c6-89ad843d5db4.png?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIASOCHRAYVMZFG2FMS%2F20230717%2Feu-west-3%2Fs3%2Faws4_request&X-Amz-Date=20230717T132418Z&X-Amz-Expires=604800&X-Amz-SignedHeaders=host&X-Amz-Signature=af49c0dc1fe7a4a7c069a573ca63415f0ab8a7142f19b8d82954a63cac2b85d7'
    }
  }

  async function fetchCompany() {
    const response = await CompanyNetwork.getCompany(company.id);
    if (response) {
      setObject(response);
      setLoading(false);
    };
  }

  useEffect(() => {
    if (!defaultProfile) {
      fetchCompany();
      object.euro_balance < 1 && window.location.replace('/opportunites/livret-p/livretp')
    } else {
      authenticationStore.loadUserData().then(response => {
        setObject(response);
        setLoading(false);
        if (response?.kyc_verification_status !== 'verified') {
          setIsShowingVericiation(!isShowingVericiation);
        }
      });
    }
  }, [])

  const pull_data = data => { setAmount(data) };
  const checkOtp = value => { setOtp(value) };

  const checkSuccess = state => {
    state && setActiveStep(activeStep + 1);
  }

  const rollbackStep = state => {
    state && setActiveStep(activeStep - 1);
  }

  function checkStep() {
    switch (activeStep) {
      case 0:
        return (
          <div className="withdraw-container">
            <h4 className="os-h4">Retirer des fonds</h4>
            <div className="withdraw-form">
              <div className="withdraw-amount">
                <SetAmount func={pull_data} page="withdraw" user={object}/>
              </div>
              <Divider />
              <div className="withdraw-available">
                <p>Montant disponible: <span>{MoneyHelper.formatEuro(object?.withdrawable_amount, false, 4, 4)}</span></p>
                <span className="withdraw-notice">
                Les conditions de retrait des bonus de parrainage sont accessibles dans la rubrique "termes de parrainages".
              </span>
              </div>
              <Divider />
              <div className="withdraw-informations">
                <p>Veuillez renseigner vos coordonnées bancaires</p>
                <WithdrawForm object={object} amount={amount} func={checkOtp} otp={otp} defaultProfile={defaultProfile}/>
              </div>
            </div>
            <div className="withdraw-otp">
              {otp && <Otp phoneNumber={!defaultProfile ? object.user.phone_number : object.phone_number} page="withdraw" defaultProfile={defaultProfile} func={checkSuccess}/>}
            </div>
          </div>
        )
      case 1:
        return <Signature product={product} page="withdrawal" nextStep={checkSuccess} storageDefaultProfile={defaultProfile} />
      default:
        return <div>Not Found</div>;
    }
  }

  return (
    <>
      <Helmet>
        <title>Livret P. | Retrait</title>
      </Helmet>
      <div style={{marginTop: 64}}>
        <Stepper activeStep={activeStep} alternativeLabel className="stepper-kyc os-scroll-shadows">
          {withdrawalSteps.map(step => (
            <Step key={step.title}>
              <StepLabel>{step.title}</StepLabel>
            </Step>
          ))}
        </Stepper>
        {checkStep()}
      </div>
      <OsModal title="Vérifier votre idendité pour activer votre compte" isShowing={isShowingVericiation} cantClose >
        <AccountVerification profile={defaultProfile ? 'user' : 'company'}/>
      </OsModal>
    </>
  );
};

export default Withdraw;
