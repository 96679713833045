import React, {useEffect, useRef, useState} from 'react';
import {Tab, Tabs} from "@mui/material";
import Divider from "@mui/material/Divider";
import {Link, useNavigate } from "react-router-dom";

import SmallSlider from "../../../pages/Opportunities/View/smallSlider";
import {ReactComponent as ChatIcon} from "../../../pages/Opportunities/View/icons/ic_chat.svg";
import {ReactComponent as SearchIcon} from "../../../pages/Opportunities/View/icons/ic_search.svg";
import {ReactComponent as TrendingUp} from "../../../pages/Opportunities/View/icons/ic_trending_up.svg";
import {ReactComponent as FileIcon} from "../../../pages/Opportunities/View/icons/ic_file.svg";
import {ReactComponent as HelpIcon} from "../../../pages/Opportunities/View/icons/ic_help.svg";
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import BalanceIcon from '@mui/icons-material/Balance';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';

import InvestmentDirectory from "../../../pages/Opportunities/View/InvestmentDirectory";
import OpportunityCardInfos from "../../../pages/Opportunities/View/components/opportunityCardInfos";

import NumberHelper from "../../../Helpers/NumberHelper";
import OSBlocks from "../../blocks/Blocks";
import Typography from "@mui/material/Typography";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import OsTooltip from "../../Tooltips";
import OSAccordion from "../../Accordion/Accordion";
import ContactBlock from "../../Contact";
import Pdf from "../../Document/pdf";
import OsModal from "../../Modals";
import Video from "../../../pages/livretp/Video";
import Chips from "../../Chips/Chips";
import Images from "../../../assets/images";
import ProductContact from "../../Contact/ProductContact";
import AutocompleteClient from "../Cgp/AutocompleteClient/AutocompleteClient";
import OSButton from "../../Button";

const ProductPresentation = ({product, toggle, isShowingVideo}) => {
  const [tab, setTab] = useState(0);
  const myRef = useRef(null);
  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);
  const itemRef = useRef(null);
  const [value, setValue] = useState(0);
  const [investClicked, setInvestClicked] = useState(false);
  const navigate = useNavigate();
  const [currentFaq, setCurrentFaq] = useState('hiw');
  const cgp = useState(sessionStorage.getItem('CgpUserAuthToken') !== null)[0];
  const [client, setClient] = useState(null);
  const [error, setError] = useState(null);
	const isYieldProduct = product?.access_type?.toLowerCase().includes('yield');

  const executeScroll = () => {
    myRef.current.scrollIntoView();
    setInvestClicked(true);
  };

  const handleChange = (_event, newValue) => {
    setTab(newValue);
    setValue(newValue);
  };

  useEffect(() => {
    if (itemRef && itemRef.current) {
      window.scrollTo({
        top: itemRef.current.offsetTop - 24,
        behavior: "smooth"
      });
    }
  });

  const renderKeyPoints = () => (
    <>
      {product?.actual_rate && <OpportunityCardInfos label={isYieldProduct ? "TRI net cible (Part capitalisation)" : "TRI net cible"} content="Le taux de rentabilité net annuel estimé, fourni à titre indicatif" value={product?.actual_rate} />}
      {(isYieldProduct && product?.distribution_rate_text) && <OpportunityCardInfos label="Cash Yield" content="Le taux de distribution net annuel estimé, fourni à titre indicatif" value={product?.distribution_rate_text} />}
      {product?.horizon && <OpportunityCardInfos label="Durée d'investissement" content="Durée d'investissement fourni à titre indicatif" value={product.horizon}/>}
      {(product.cgp_minimum_investment || product?.minimum_investment) && <OpportunityCardInfos label="Investissement minimum" value={`${NumberHelper.numberWithSpaces(cgp ?  product.cgp_minimum_investment : product.minimum_investment)} €`}/>}
      {/*<div className="os-flex gap-16" style={{marginTop: 8}}>
        {(cgp && product.cgp_fees) && getBadge('verified', 'kyc_status', `${product.is_os && '0 à '} ${NumberHelper.formatPercentage(product.cgp_fees)} Frais d'entrée`, 'info')}
        {(cgp && product.cgp_recurring_fees) && getBadge('verified', 'kyc_status', `${NumberHelper.formatPercentage(product.cgp_recurring_fees)} Commission de gestion`, 'info')}
      </div>*/}
    </>
  );

  const mainInfos = [
    {title: "Éléments clés du fonds", type: product.key_points},
    {title: "Frais", type: product.subscriptions_infos},
    {title: "Juridique", type: product.juridical},
  ]

  function useOutsideAlerter(ref) {
    useEffect(() => {
      /**
       * Alert if clicked on outside of element
       */
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target) ) {
          setInvestClicked(false)
        }
      }
      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }

  const faqChoices = [
    {name: 'Fonctionnement', value: 'hiw'},
    {name: 'Sécurité & liquidité', value: 'security'},
    {name: 'Structure', value: 'structural'},
    {name: 'Fiscalité', value: 'fiscality'},
  ]

  function checkCurrentFaq() {
    switch (currentFaq) {
      case 'hiw':
        return product.faq?.elements?.map((e, index) => (
          <OSAccordion element={e} key={`base-${index}`}/>
        ));
      case 'security':
        return product.faq_security?.elements?.map((e, index) => (
          <OSAccordion element={e} key={`security-${index}`} />
        ));
      case 'structural':
        return product.faq_structural?.elements?.map((e, index) => (
          <OSAccordion element={e} key={`structural-${index}`} />
        ));
      case 'fiscality':
        return product.faq_fiscality?.elements?.map((e, index) => (
          <OSAccordion element={e} key={`fiscality-${index}`} />
        ));
      default:
        return product.faq?.elements?.map((e, index) => (
          <OSAccordion element={e} key={`base-${index}`} />
        ));
    }
  }
	
	const continueSubscription = () => {
		if (client === null) {
			setError('Vous devez choisir un client.')
		} else {
			navigate(`/cgp/clients/${client?.record?.id}/investissement/${product.category_slug}/${product.slug}/finalise`, {state: {record: client, clientId: client?.record?.id, clientType: client?.listable_type}})
		}
	}

  function checkSubscriptionState() {
    if (product.subscription_state === 'open' || product.subscription_state === 'waiting_list_with_info') {
      return (
        <div className="deposit-form-container">
          {cgp ? (
            <>
              <AutocompleteClient updateRecord={setClient} />
              {client !== 'empty' && (
                <>
                  <OSButton title={cgp ? 'Commencer la souscription' : 'Investir'} variant="primary" size="large" onClick={() => continueSubscription()} />
                  {error !== null && <span className="error">{error}</span>}
                </>
              )}
            </>
          ) : <OSButton title="Investir" variant="primary" size="large" onClick={() => navigate(`/opportunites/${product.category_slug}/${product.slug}/finalise`)} /> }
        </div>
      )
    } else if (product.subscription_state === 'end') {
      return (
        <div className="deposit-form-container">
          <div className={`livretp-banner livretp-banner__column livretp-banner__info`}>
            <div className="livretp-banner__column-left-content">
              <Link className={`livretp_banner-link livretp_banner-link__info`} to="/opportunites">La souscription pour ce produit est terminée</Link>
            </div>
            <OSButton variant="no_border" size="medium" title="Voir d'autres produits" link="/opportunites" fit />
          </div>
        </div>
      )
    } else if (product.subscription_state === 'close') {
      return (
        <div className="deposit-form-container">
          <div className={`livretp-banner livretp-banner__column livretp-banner__yellow`}>
            <div className="livretp-banner__column-left-content">
              <span className="warning">Ce produit n'est pas encore disponible à la souscription</span>
            </div>
            <div className="os-flex gap-8">
              <OSButton variant="no_border" size="medium" title="Voir d'autres produits" link="/opportunites" fit />
            </div>
          </div>
        </div>
      )
    }
  }

  return (
    <>
      <div className={`product-view-top-infos ${cgp && 'product-view-top-infos__cgp'} ${product.has_invested && 'product-view-top-infos__invested'}`} ref={myRef}>
        <div className="product-view-top-infos-left">
          <SmallSlider items={[product.cover_picture?.original]} opened={product.subscription_state === 'open'} product={product}/>
          <div className="os-flex__column product-view-top-infos-right">
            <div className="os-flex__column gap-8">
              {renderKeyPoints()}
            </div>
            <div className="os-flex__column">
              {checkSubscriptionState()}
              {(!cgp && product.has_invested) && (
                  <>
                    <div style={{marginTop: '16px'}} />
                    <OSButton variant="secondary" size="medium" title="Voir mon investissement" onClick={() => product.name === 'Livret P.' ? navigate(`/opportunites/${product.category_slug}/my-investment`) : navigate(`/opportunites/${product.category_slug}/${product.slug}/my-investment`, {state: {id: product.id}})} />
                  </>
              )
              }
            </div>
          </div>
        </div>
      </div>
      <ProductContact title={"Une Question ?"} text={"Nos conseillers Openstone sont là pour répondre à toutes vos questions ! "}/>
      <div className="product-view-sections-navigation" style={cgp ? {top: 0} : {top: 0}}>
        <Tabs
          value={tab}
          onChange={handleChange}
          variant="scrollable"
          scrollButtons={true}
          allowScrollButtonsMobile={true}
          aria-label="scrollable force tabs">
          <Tab style={{display: "none"}} />
          <Tab icon={<SearchIcon />} iconPosition="start" label="L'investissement" />
          <Tab icon={<TrendingUp/>} iconPosition="start" label="Chiffres clés"/>
          <Tab icon={<ChatIcon/>} iconPosition="start" label="L'analyse de l'expert"/>
          <Tab icon={<FileIcon />} iconPosition="start" label="Les documents" />
          <Tab icon={<BalanceIcon />} iconPosition="start" label="Les risques & avantages" />
          <Tab icon={<AccountBalanceIcon />} iconPosition="start" label={product.partner_title} />
          <Tab icon={<HelpIcon />} iconPosition="start" label="F.A.Q" />
        </Tabs>
      </div>
      <div className="product-tabs-container">
        {(product.synthesis !== null) && (
          <div className="opportunity-view-element-container">
            <h4 className="os-h4" ref={value === 1 ? itemRef : null}>L'investissement</h4>
            <div className="synthesis-container">
              <div className="synthesis os-body2" dangerouslySetInnerHTML={{__html: product.synthesis}} />
              <div className="synthesis-img">
                <img className="information-recap-content-img" src={product.second_cover?.attachment.url} alt=""/>
                {product.video_link && (<div className="synthesis-video-play" onClick={() => toggle('video')}><PlayArrowIcon/></div>)}
              </div>
            </div>
          </div>
        )}
        <div className="os-flex gap-32">
          {mainInfos.map((e, index) => (
            <div className="os-flex__element-one-third" key={`mainInfos-${index}`}>
              <h5 className="os-h5">{e.title}</h5>
              <OSBlocks>
                {e.type?.elements && e.type?.elements?.map(el => (
                  <div ref={value === 2 ? itemRef : null} style={{marginBottom: 16}}>
                    <div className="os-flex__column gap-8" style={{alignItems: "flex-start"}}>
                      <div className="os-flex gap-8" style={{alignItems: "center"}}>
                        <span className="os-caption">{el.title}</span>
                        {el.info && (
                          <OsTooltip title={<Typography color="inherit">{el.info}</Typography>}>
                            <div className="tooltip-btn"><InfoOutlinedIcon /></div>
                          </OsTooltip>
                        )}
                      </div>
                      <span className="os-body2" style={{fontWeight: "bold"}} dangerouslySetInnerHTML={{__html: el.description}} />
                    </div>
                  </div>
                ))}
              </OSBlocks>
            </div>
          ))}
        </div>
        {product.expert_analysis !== null && (
          <div className="opportunity-view-element-container">
            <h4 className="os-h4" ref={value === 3 ? itemRef : null}>L'analyse de nos experts</h4>
            <div className="expert-analysis-container">
              <div className="invest-in-product-card expert-card os-body2">
                <div className="expert-container">
                  <div className="expert-avatar">
                    <img src={product?.user?.avatar?.original} className="expert-avatar--large" alt=""/>
                  </div>
                  <div className="expert-infos">
                    <p className="expert-infos-names os-body2">{product.user.first_name} {product.user.last_name}</p>
                    <p className="expert-infos-content os-body2">{product.user.position}</p>
                  </div>
                </div>
                <Divider />
                <div style={{marginTop: 32}} dangerouslySetInnerHTML={{__html: product.expert_analysis}} />
              </div>
            </div>
          </div>
        )}
        <div style={{marginBottom: 48}} ref={value === 4 ? itemRef : null}>
          {product.documents.length > 0 && (
            <>
              <h4 className="os-h4">Les documents</h4>
              <div className="product-documents-container">
                {product.documents.map((document, index) => (
                  <Pdf page="opportunity" document={document} key={`documents-${index}`}/>
                ))}
              </div>
            </>
          )}
        </div>
        <h4 className="os-h4">Les risques & avantages</h4>
        <div className="os-flex gap-32" ref={value === 5 ? itemRef : null}>
          <div className="os-flex__element-half">
            {product.risks && (
              <OSBlocks>
                <p className="os-subtitle1" style={{fontWeight: "bold", marginBottom: 24}}>RISQUES</p>
                <div>
                  <div className="os-body2" dangerouslySetInnerHTML={{__html: product.risks}} />
                </div>
              </OSBlocks>
            )}
          </div>
          <div className="os-flex__element-half">
            {product.advantages && (
              <OSBlocks>
                <p className="os-subtitle1" style={{fontWeight: "bold", marginBottom: 24}}>AVANTAGES</p>
                <div>
                  <div className="os-body2" dangerouslySetInnerHTML={{__html: product.advantages}} />
                </div>
              </OSBlocks>
            )}
          </div>
        </div>
        <div className="opportunity-view-element-container" ref={value === 6 ? itemRef : null}>
          {product.partner_company !== null && (
            <>
              <h4 className="os-h4">{product.partner_title}</h4>
              <div className="os-card-border">
                  {product?.logo && <img className="partner-img" loading="lazy" src={product?.logo?.original} alt={`Logo - Openstone X ${product?.partner_title}`} />}
                <div className="os-body2" dangerouslySetInnerHTML={{__html: product.partner_company}} />
              </div>
            </>
           )}
        </div>
        <div ref={value === 7 ? itemRef : null}>
          <>
            <div className="os-flex gap-16 faq-choices" style={{alignItems:'center', marginTop: 64}}>
              <h4 className="os-h4">F.A.Q</h4>
              <div className="chips-container gap-16">
                {faqChoices.map((fc, index) => (
                  <div onClick={() => setCurrentFaq(fc.value)} key={`faqChoices-${index}`}>
                    <Chips selected={fc.value === currentFaq}  clickable >{fc.name}</Chips>
                  </div>
                  )
                )}
              </div>
            </div>
            {checkCurrentFaq()}
          </>
        </div>
        <div style={{marginTop: 32}}/>
        <ContactBlock toggle={toggle} image={Images.charles} name={"Charles-Antoine d'Openstone"} text={"Notre objectif chez Openstone est de vous aider à constituer un partrimoine diversifié"} mail={"bonjour@openstone.com"} number={"01 59 06 80 50"}/>
      </div>
      <div className="invest-in-product" ref={wrapperRef}>
        <h4 className="os-h4">Investir dans {product?.name}</h4>
        <div className="invest-in-product-card">
          {renderKeyPoints()}
          <div className="invest-in-product-card-btns">
            {product.subscription_state === 'open' && <div onClick={executeScroll} className="invest-btn">{cgp ? 'Faire souscrire un client' : 'Investir'}</div>}
          </div>
        </div>
      </div>
      <OsModal isWhite={false} title="" isShowing={isShowingVideo} hide={() => toggle('video')} >
        <Video link={product.video_link}/>
      </OsModal>
    </>
  );
};

export default ProductPresentation;
