import React, {useEffect, useRef, useState} from 'react';
import {Tab, Tabs} from "@mui/material";
import { useNavigate } from "react-router-dom";

import SmallSlider from "../../../pages/Opportunities/View/smallSlider";
import {ReactComponent as ChatIcon} from "../../../pages/Opportunities/View/icons/ic_chat.svg";
import {ReactComponent as SearchIcon} from "../../../pages/Opportunities/View/icons/ic_search.svg";
import {ReactComponent as TrendingUp} from "../../../pages/Opportunities/View/icons/ic_trending_up.svg";
import {ReactComponent as FileIcon} from "../../../pages/Opportunities/View/icons/ic_file.svg";
import {ReactComponent as HelpIcon} from "../../../pages/Opportunities/View/icons/ic_help.svg";
import BalanceIcon from '@mui/icons-material/Balance';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';

import OpportunityCardInfos from "../../../pages/Opportunities/View/components/opportunityCardInfos";

import NumberHelper from "../../../Helpers/NumberHelper";
import ContactBlock from "../../Contact";
import OsModal from "../../Modals";
import Video from "../../../pages/livretp/Video";
import Images from "../../../assets/images";
import ProductContact from "../../Contact/ProductContact";
import OSButton from "../../Button";
import NotificationsLoader from "../../Loaders/Notifications";
import YourReferralsLoader from "../../Loaders/Referral/YoursLoader";
import {useLocation} from "react-router";

const PublicProductPresentation = ({product, toggle, isShowingVideo}) => {
	const [tab, setTab] = useState(0);
	const myRef = useRef(null);
	const wrapperRef = useRef(null);
	useOutsideAlerter(wrapperRef);
	const itemRef = useRef(null);
	const [value, setValue] = useState(0);
	const [investClicked, setInvestClicked] = useState(false);
	const navigate = useNavigate();
	const location = useLocation();
	const cgp = useState(sessionStorage.getItem('CgpUserAuthToken') !== null)[0];
	const [affiliateLink, setAffiliateLink] = useState(null);

	useEffect(() => {
		const authToken = sessionStorage.getItem('UserAuthToken');
		const cgpAuthToken = sessionStorage.getItem('CgpUserAuthToken');
		let newPath = window.location.pathname.replace('/public', '');

		if (authToken || cgpAuthToken) {
			return window.location.replace(newPath);
		}
	})

	useEffect(() => {
		const searchParams = new URLSearchParams(location.search);
		const affiliateLink = searchParams.get('affiliateLink');

		affiliateLink && setAffiliateLink(affiliateLink)
	}, [location]);

	const executeScroll = () => {
		myRef.current.scrollIntoView();
		setInvestClicked(true);
	};

	const handleChange = (_event, newValue) => {
		setTab(newValue);
		setValue(newValue);
	};

	useEffect(() => {
		if (itemRef && itemRef.current) {
			window.scrollTo({
				top: itemRef.current.offsetTop - 24,
				behavior: "smooth"
			});
		}
	});

	const renderKeyPoints = () => (
		<>
			{product?.actual_rate && <OpportunityCardInfos label="TRI net cible" content="Le taux de rentabilité net annuel estimé, fourni à titre indicatif" value={product?.actual_rate} />}
			{product?.horizon && <OpportunityCardInfos label="Durée d'investissement" content="Horizon d'investissement fourni à titre indicatif" value={product.horizon}/>}
			{(product.cgp_minimum_investment || product?.minimum_investment) && <OpportunityCardInfos label="Investissement minimum" value={`${NumberHelper.numberWithSpaces(cgp ?  product.cgp_minimum_investment : product.minimum_investment)} €`}/>}
            {/*<div className="os-flex gap-16" style={{marginTop: 8}}>
				{(cgp && product.cgp_fees) && getBadge('verified', 'kyc_status', `${NumberHelper.formatPercentage(product.cgp_fees)} Frais d'entrée`, 'info')}
				{(cgp && product.cgp_recurring_fees) && getBadge('verified', 'kyc_status', `${NumberHelper.formatPercentage(product.cgp_recurring_fees)} Commission de gestion`, 'info')}
			</div>*/}
		</>
	);

	function useOutsideAlerter(ref) {
		useEffect(() => {
			function handleClickOutside(event) {
				if (ref.current && !ref.current.contains(event.target) ) {
					setInvestClicked(false)
				}
			}
			document.addEventListener("mousedown", handleClickOutside);
			return () => {
				document.removeEventListener("mousedown", handleClickOutside);
			};
		}, [ref]);
	}

	function checkSubscriptionState() {
		if (product.subscription_state === 'open') {
			return (
				<div className="deposit-form-container os-flex__column gap-16">
					<OSButton
						title="Investir"
						variant="primary"
						size="large"
						onClick={() => navigate(`/?redirectUrl=/opportunites/${product.category_slug}/${product.id}/finalise${affiliateLink ? `&affiliateLink=${affiliateLink}` : ''}`)}
					/>
					<OSButton
						title="Voir plus d'informations"
						variant="no_border-info"
						size="medium"
						onClick={() => navigate(`/?redirectUrl=/opportunites/${product.category_slug}/${product.id}${affiliateLink ? `&affiliateLink=${affiliateLink}` : ''}`)}
					/>
				</div>

			)
		} else if (product.subscription_state === 'end') {
			return (
				<div className="deposit-form-container">
					<div className={`livretp-banner livretp-banner__column livretp-banner__info`}>
						<div className="livretp-banner__column-left-content">
							<span className={`livretp_banner-link livretp_banner-link__info`}>La souscription pour ce produit est terminée</span>
						</div>
						<OSButton
							title="Voir d'autres produits"
							variant="no_border-info"
							size="medium"
							onClick={() => navigate(`/?redirectUrl=/opportunites${affiliateLink && `&affiliateLink=${affiliateLink}`}`)}
						/>
					</div>
				</div>
			)
		} else if (product.subscription_state === 'close') {
			return (
				<div className="deposit-form-container">
					<div className={`livretp-banner livretp-banner__column livretp-banner__yellow`}>
						<div className="livretp-banner__column-left-content">
							<span className="warning">Ce produit n'est pas encore disponible à la souscription</span>
						</div>
						<div className="os-flex gap-8">
							<OSButton
								title="Voir d'autres produits"
								variant="no_border-info"
								size="medium"
								onClick={() => navigate(`/?redirectUrl=/opportunites${affiliateLink && `&affiliateLink=${affiliateLink}`}`)}
							/>
						</div>
					</div>
				</div>
			)
		}
	}

	return (
		<>
			<div className={`product-view-top-infos`} ref={myRef}>
				<div className="product-view-top-infos-left">
					<SmallSlider items={[product.cover_picture?.original]} opened={product.subscription_state === 'open'} product={product}/>
					<div className="os-flex__column product-view-top-infos-right product-view-top-infos-right_public">
						<div className="os-flex__column gap-8">
							{renderKeyPoints()}
						</div>
						<div className="os-flex__column">
							{checkSubscriptionState()}
						</div>
					</div>
				</div>
			</div>
			<ProductContact title={"Une Question ?"} text={"Nos conseillers Openstone sont là pour répondre à toutes vos questions ! "}/>
			<div className="product-view-sections-navigation" style={cgp ? {top: 0} : {top: 0}}>
				<Tabs
					value={tab}
					onChange={handleChange}
					variant="scrollable"
					scrollButtons={true}
					allowScrollButtonsMobile={true}
					aria-label="scrollable force tabs">
					<Tab style={{display: "none"}} />
					<Tab icon={<SearchIcon />} iconPosition="start" label="L'investissement" />
					<Tab icon={<TrendingUp/>} iconPosition="start" label="Chiffres clés"/>
					<Tab icon={<ChatIcon/>} iconPosition="start" label="L'analyse de l'expert"/>
					<Tab icon={<FileIcon />} iconPosition="start" label="Les documents" />
					<Tab icon={<BalanceIcon />} iconPosition="start" label="Les risques & avantages" />
					<Tab icon={<AccountBalanceIcon />} iconPosition="start" label={product.partner_title} />
					<Tab icon={<HelpIcon />} iconPosition="start" label="F.A.Q" />
				</Tabs>
			</div>
			<div className="product-tabs-container">
				<div className="public-content-container">
					<div>
						<YourReferralsLoader />
						{[0,1,2,3].map(l => <NotificationsLoader />)}
					</div>
					<div className="public-card-container">
						<div className="public-card">
							<h6 className="os-h6" style={{textAlign: 'center'}}>Créez gratuitement votre compte pour accéder aux détails de cette opportunité</h6>
							<p className="os-body1" style={{textAlign: 'center'}}>Les informations du projet sont confidentielles et reservées aux membres Openstone.</p>
							<div className="os-flex__column gap-16" style={{alignItems: 'center'}}>
								<OSButton
									variant="primary"
									title="Je crée un compte gratuitement"
									fit
									size="medium"
									onClick={() => {
										navigate(`/?redirectUrl=/opportunites/${product.category_slug}/${product.id}${affiliateLink ? `&affiliateLink=${affiliateLink}` : ''}`);
										window.scrollTo(0, 0); // Déplacer la fenêtre vers le haut de la page
									}}
								/>
								<OSButton
									title="Je me connecte"
									variant="primary-full"
									size="medium"
									onClick={() => navigate('/sign-in/partners')}
									fit
								/>
							</div>
						</div>
					</div>
				</div>
				<ContactBlock toggle={toggle} image={Images.charles} name={"Charles-Antoine d'Openstone"} text={"Notre objectif chez Openstone est de vous aider à constituer un partrimoine diversifié"} mail={"bonjour@openstone.com"} number={"01 59 06 80 50"}/>
			</div>
			<div className="invest-in-product" ref={wrapperRef}>
				<h4 className="os-h4">Investir dans le produit</h4>
				<div className="invest-in-product-card">
					{renderKeyPoints()}
					<div className="invest-in-product-card-btns">
						{product.subscription_state === 'open' && <OSButton onClick={executeScroll} variant="primary" size="large" title="Investir"/>}
					</div>
				</div>
			</div>
			<OsModal isWhite={false} title="" isShowing={isShowingVideo} hide={() => toggle('video')} >
				<Video link={product.video_link}/>
			</OsModal>
		</>
	);
};

export default PublicProductPresentation;
