import React, {useEffect, useState} from 'react';
import StepsContainer from "../../StepsContainer";
import CustomTab from "../../../../../../components/CustomTab";
import CgpNetwork from "../../../../../../config/Network/CgpNetwork.ts";
import {useFormik} from "formik";
import {toast} from "react-toastify";
import TextField from "@material-ui/core/TextField";
import OSButton from "../../../../../../components/Button";
import * as yup from "yup";
import {useNavigate} from "react-router-dom";
import MoneyHelper from "../../../../../../Helpers/MoneyHelper";
import FundCallCalculator from "../../../../../../components/ChoosePaimentType/fundsCall";
import BigNumber from "bignumber.js";
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from "@mui/icons-material/Add";
import OsModal from "../../../../../../components/Modals";
import MoreUploads from "../../../../../../pages/KycSurvey/Steps/Uploads/more";
import {Save} from "@mui/icons-material";


const CgpPaiementChoice = ({object, product, clientId, clientType, nextStep, updateObject}) => {
  const [amount] = useState(sessionStorage.getItem('InvestmentAmount') || null);
	const cgpEntryFees = sessionStorage.getItem('CgpEntryFees') || null;
  const fees = amount * (cgpEntryFees / 100);
	const sgpEntryFees = sessionStorage.getItem('SgpEntryFees') || null;
	const sgpFees = amount * (sgpEntryFees / 100);
  const [activeTab, setActiveTab] = useState(1);
  const [submitting, setSubmitting] = useState(null);
  const [ibanValid, setIbanValid] = useState(object.iban ? true : false);
  const [fundCallsChoice, setFundCallsChoice] = useState(1);
	const [editIban, setEditIban] = useState(false);
	const isPrimeProduct = product?.access_type?.toLowerCase().includes('prime');
	const isYieldProduct = product?.access_type?.toLowerCase().includes('yield');
	const isPrimeOrYield = isPrimeProduct || isYieldProduct;
	const [ra, setRa] = useState("toSubmit");
	const [rib, setRib] = useState("toSubmit");
	const navigate = useNavigate();
	const [isUploadModalOpen, setIsUploadModalOpen] = useState(false);
	
	const tabs = isPrimeOrYield
		? [
			{
				id: 1,
				title: "Virement SEPA",
				description: "Un email avec les informations de virement sera envoyé au client",
				clickable: true
			}
		]
		: [
			{
				id: 1,
				title: "Virement SEPA",
				description: "Un email avec les informations de virement sera envoyé au client",
				clickable: true
			},
			{
				id: 2,
				title: "Mandat de prélèvement SEPA",
				description: "Paiement par mandat de prélèvement SEPA",
				clickable: true
			}
		];
  const fundCallsTabs = [
    {id: 1, title: "Paiement intégral", description: "Réaliser la souscription en une fois", clickable: true},
    {id: 2, title: "Appels de fonds", description: "3 appels de fonds annuels", clickable: true},
  ];
	
	
	function isValidFrenchIban(iban) {
		if (!iban.startsWith('FR') || iban.length !== 27) {
			return false;
		}
		
		const rearrangedIban = iban.slice(4) + iban.slice(0, 4);
		const numericIban = rearrangedIban
			.split('')
			.map((char) => {
				if (/[A-Z]/.test(char)) {
					return (char.charCodeAt(0) - 55).toString();
				}
				return char;
			})
			.join('');
		
		const ibanNumber = new BigNumber(numericIban);
		return ibanNumber.mod(97).eq(1);
	}
	
  const validationSchema = yup.object({
		iban: yup
			.string()
			.required("L'IBAN est obligatoire")
			.test(
				'is-valid-french-iban',
				"L'IBAN n'est pas valide",
				(value) => value && isValidFrenchIban(value)
			),
		phone: yup.string()
			.required("Un numéro de téléphone est obligatoire")
			.matches(/^\+33\d{9}$/, "Le numéro de téléphone doit être français et comporter 9 chiffres après '+33'.")
			.min(12, "Le numéro de téléphone doit comporter au moins 10 chiffres.")
			.max(14, "Le numéro de téléphone doit comporter au maximum 14 caractères.")
	})
	
	const formik = useFormik({
		initialValues: {
			iban: object.iban || '',
			phone: object.phone_number || '',
		},
		validationSchema: activeTab === 2 && validationSchema,
		onSubmit: async (values) => {
			setSubmitting(true);
			
			const updateClient = async () => {
				const params = (activeTab === 2) ? { iban: values.iban, phone_number: values.phone } : { wire_bank: values.wireBank };
				const recordType = clientType === 'Company' ? 'Company' : 'User';
				return await CgpNetwork.updateRecord(params, clientId, recordType);
			};
			
			try {
				const response = await updateClient();
				
				if (response.status === 200) {
					updateObject(true);
					if (activeTab === 2) {
						setIbanValid(true);
						setEditIban(false);
					}
					toast.success('Vos informations personnelles ont bien été enregistrées', {
						toastId: 'completeInfosSuccessToast'
					});
				} else if (response.status === 422) {
					toast.error("Ce numéro de téléphone est déjà utilisé" || 'Une erreur est survenue', {
						toastId: 'completeInfosErrorToast',
					});
				}
			} catch (error) {
				toast.error('Erreur de connexion au serveur', {
					toastId: 'completeInfosServerErrorToast',
				});
			} finally {
				setSubmitting(false);
			}
		},
	});
	
	useEffect(() => {
		if (ra === "submitted" && rib === "submitted") {
			setIsUploadModalOpen(false);
		}
	}, [ra, rib])
	
	const wireTransferTab = (
		<>
			<div>
				<h6>Documents liés à souscription</h6>
				<div className="os-grid two-grid">
					<p className="os-body1">Rapport d'adéquation: {ra === "submitted" ? <span className="success">✓ Document ajouté</span> : <span className="warning">𐄂 Document à fournir</span>}</p>
					<p className="os-body1">RIB: {rib === "submitted" ? <span className="success">✓ Document ajouté</span> : <span className="warning">𐄂 Document à fournir</span>} </p>
				</div>
				{(ra !== "submitted" || rib !== "submitted") && (
					<div className="os-flex" style={{alignItems: 'center', justifyContent: 'flex-end', marginTop: 16}}>
						<OSButton variant="primary-full" size="small" fit title="Ajouter les documents" icon={<AddIcon />} onClick={() => setIsUploadModalOpen(true)} />
					</div>
				)}
				<OsModal
					isShowing={isUploadModalOpen}
					hide={() => setIsUploadModalOpen(false)}
					title="Ajouter des documents"
				>
					<MoreUploads client={object} setRa={setRa} setRib={setRib} clientType={clientType} />
				</OsModal>
			</div>
		</>
	)

  const ibanTab = (
    <>
      {ibanValid ? (
        <>
					<div className="os-flex iban-title-container">
          	<h6 className="os-h6">Veuillez vérifier les informations bancaires fournies</h6>
						<OSButton variant="no_border" size="" onClick={() => setIbanValid(false)} title="Éditer" fit icon={< EditIcon />}/>
					</div>
          <div>
						<div className="os-flex__column">
            	<p>IBAN : {object.iban}</p>
            	<p>Numéro de téléphone : {object.phone_number}</p>
						</div>
          </div>
        </>
      ) : (
        <form className="os-user-survey-form" onSubmit={formik.handleSubmit}>
          <>
            <h6 className="os-h6">Informations bancaires</h6>
						<p className="os-body1">Pour utiliser le prélèvement SEPA lors de la souscription, veuillez fournir un IBAN français valide ainsi que votre numéro de téléphone. Ces informations sont nécessaires pour garantir la conformité et la sécurité de vos transactions bancaires.</p>
            <p className="os-caption">Conseil : Notez manuellement l'IBAN afin d'éviter toute erreur ou usurpation.</p>
            <div className="os-flex__column gap-16" style={{marginTop: 24}}>
              <TextField
                fullWidth
                id="iban"
                name="iban"
                label="IBAN"
								value={formik.values.iban?.startsWith('FR') ? formik.values.iban : `FR${formik.values.iban}`}
								onChange={(e) => {
									let ibanValue = e.target.value;
									// Ensure FR is at the start of the IBAN
									if (!ibanValue.startsWith('FR')) {
										ibanValue = `FR${ibanValue}`;
									}
									formik.setFieldValue('iban', ibanValue);
								}}
                error={formik.touched.iban && Boolean(formik.errors.iban)}
                helperText={formik.touched.iban && formik.errors.iban}
                variant={"outlined"}
              />
							<TextField
								fullWidth
								id="phone"
								name="phone"
								label="Numéro de téléphone"
								value={formik.values.phone?.startsWith('+33') ? formik.values.phone : `+33${formik.values.phone}`}
								onChange={(e) => {
									let phoneValue = e.target.value;
									// Ensure +33 is at the start of the phone number
									if (!phoneValue.startsWith('+33')) {
										phoneValue = `+33${phoneValue}`;
									}
									formik.setFieldValue('phone', phoneValue);
								}}
								error={formik.touched.phone && Boolean(formik.errors.phone)}
								helperText={formik.touched.phone && formik.errors.phone}
								variant={"outlined"}
							/>
							<div className="user-survey-btn-container">
                <button type="submit" className="user-survey-btn-back" disabled={submitting}>Enregistrer</button>
              </div>
            </div>
          </>
        </form>
      )}
    </>
  )

  const renderCurrentTab = () => {
    if (activeTab === 2) {
      return ibanTab;
    } else {
      return wireTransferTab;
		}
  }
	
	useEffect(() => {
		activeTab === 2 && setEditIban(true);
	}, [activeTab]);

  const continueCheckout = () => {
		if (amount >= 100_000 && fundCallsChoice !== 1) {
				sessionStorage.setItem('FundsCall', 'true')
		}
		sessionStorage.setItem('InvestmentType', activeTab === 2 ? 'sepaMandate' : 'wireTransfer')
		navigate(`${window.location.pathname}?transaction_type=${activeTab === 2 ? 'sepaMandate' : 'wireTransfer'}`, {
			state: { clientId: clientId, clientType: clientType }
		});
  }
	
	const checkButtonState = () => {
		if (activeTab === 2) {
			if (!editIban || ibanValid) {
				return <OSButton variant="primary" size="large" onClick={continueCheckout} fit title="Continuer à l'étape suivante"/>
			}
		} else {
			if (isPrimeOrYield && (ra === "submitted" && rib === "submitted")) {
				return <OSButton variant="primary" size="large" onClick={continueCheckout} fit title="Continuer à l'étape suivante"/>}
		}
	}
	
  return (
    <>
      <StepsContainer product={product}
                      children={
                <div>
									<h6 className="os-h6">Détail du paiement</h6>
									<div className="investor-profile-card">
										{(fees || sgpFees) ?
											<p className="info-text">Montant total à régler par le client : <strong>{MoneyHelper.formatEuro(parseFloat(amount) + parseFloat(fees) + parseFloat(sgpFees), true, 0, 0)}</strong></p>
											: <p className="amount-to-update-text os-body1">{"Le montant de la souscription est de"} {MoneyHelper.formatEuro(amount, true, 0, 0)}</p>
										}
										<p className="os-body1 os-flex__column">
											<span>Montant de l'investissement : {MoneyHelper.formatEuro(amount)}</span>
											<span>Vos droits d'entrée : {MoneyHelper.formatEuro(fees)}</span>
											{/* sgpFees > 0 && (<span>Droits d'entrée dûes à la société de gestion : {MoneyHelper.formatEuro(sgpFees)}</span>)*/}
										</p>
										<p className="os-caption">
											Pour ajuster les montants d'investissement, vous pouvez revenir à l'étape précédente en cliquant sur: "Modalités de l'investissement"
										</p>
									</div>
                    {(amount >= 100_000 && !isPrimeOrYield) && (
                        <div className="fund_calls-container">
                            <p className="os-subtitle1">Options de paiement</p>
                            <p className="os-body2">Pour toute souscription supérieure ou égale à 100 000€, vous pouvez choisir la mise en place d’appels de fonds annuels répartis sur 3 ans</p>
                            <div className="os-flex gap-16" style={{flexWrap: 'wrap', marginBottom: 16}}>
                                {fundCallsTabs.map(tab => <CustomTab key={`fundCalls${tab.id}`} tab={tab} clickable={tab.clickable} onTabClick={() => setFundCallsChoice(tab.id)}
                                                                     activetab={fundCallsChoice} selected={fundCallsChoice === tab.id}
                                                                     disabled={tab?.disabled} full />)}
                            </div>
                            {fundCallsChoice === 2 && (<FundCallCalculator balance={amount} fees={fees} />)}
                        </div>
                    )}
                    <div className="paiment-choice-container">
											{!isPrimeOrYield && (
												<>
													<p className="os-subtitle1">Méthode de versement</p>
													<p className="os-body2">Veuillez choisir la solution de versement souhaitée pour le client</p>
													<div className="os-flex gap-16" style={{flexWrap: 'wrap', marginBottom: 16}}>
															{tabs.map(tab => <CustomTab key={tab.id} tab={tab} clickable={tab.clickable} onTabClick={() => {
																												setActiveTab(tab.id)
																											}}
																											activetab={activeTab} selected={activeTab === tab.id}
																											disabled={tab?.disabled} />
															)}
													</div>
												</>
											)}
											{(activeTab !== null) && renderCurrentTab()}
                    </div>
                    <div className="os-flex" style={{justifyContent: 'flex-end'}}>
											{checkButtonState()}
                    </div>
                </div>
            }
      />
    </>
  );
};

export default CgpPaiementChoice;
