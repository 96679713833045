import React, {useEffect, useState} from 'react';
import {useLocation, useParams} from "react-router";
import {useNavigate} from "react-router-dom";

import DocumentNetwork from "../../../config/Network/DocumentNetwork.ts";
import CgpNetwork from "../../../config/Network/CgpNetwork.ts";

import {FormControl, InputLabel, MenuItem, Select} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

import Pdf from "../../../components/Document/pdf";
import DocumentLoader from "../../../components/Loaders/Document";
import EmptyContent from "../../../components/empty-content/EmptyContent";
import OSButton from "../../../components/Button";

import {ReactComponent as NoDocs} from "../../../assets/Illustrations/no-documents.svg";

import './style.scss';
import getBadge from "../../../components/Badge/getBadge";
import InstructionsContainer from "../../../components/instructionsContainer";

const Documents = ({page, cgp}) => {
  const { clientId } = useParams();
  const { state } = useLocation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [documents, setDocuments] = useState([]);
  const [selectedContractType, setSelectedContractType] = useState('all');
  const storageDefaultProfile = useState(JSON.parse(sessionStorage.getItem('defaultProfile')))[0];
  const [badges, setBadges]= useState([]);
  const defaultProfile = cgp ? (state?.clientType !== 'Company') : storageDefaultProfile

  const getDocuments = async () => {
    setLoading(true);
    const response = defaultProfile === true ? await DocumentNetwork.my_documents(selectedContractType) : await DocumentNetwork.my_company_documents(selectedContractType);
    if (response) {
      setDocuments(response);
      setLoading(false);
    }
  };

  const getclientDocuments = async () => {
    setLoading(true);
    const response = await CgpNetwork.getClientDocuments(selectedContractType, clientId, state?.clientType);
    if (response) {
      setDocuments(response);
      setLoading(false);
    }
  };

  useEffect(() => {
    setBadges([
      {number: documents.filter(doc => doc.status === 'pending').length, text: "En attente de signature", status: "pending"},
      {number: documents.filter(doc => doc.status === 'signed').length, text: "Signés", status: "signed"},
      {number: documents.filter(doc => doc.status === 'expired').length, text: "Expirés", status: "expired"}
    ])
  }, [documents])

  function renderDocuments() {
    if (loading) {
      return [1, 2, 3, 4, 5, 6].map((i, index) => <DocumentLoader key={`info_docs_${i + index * index}`}/>)
    } else if (!loading && documents !== null) {
      if (documents.length > 0) {
        const fullDocs = page === 'preview' ? documents.slice(0,9) : documents;
        return fullDocs.map((d, index) => <Pdf document={d} page="myDocuments" key={`info_docs_${index}`}/>)
      } else {
        return (
          <div style={{width: '100%',display: 'flex', justifyContent: 'center'}}>
            <EmptyContent illustration={<NoDocs/>} text="Aucun document à afficher" cta={{title: "Voir les offres", link: "/opportunites", variant: "primary-full", size: "small"}} />
          </div>
        )
      }
    }
  }

  useEffect(() => {
    cgp ? getclientDocuments() : getDocuments();
  }, [selectedContractType])

  return (
    <>
      <div className={page !== 'preview' && 'my-documents-container'}>
        {page !== 'preview' && (
          <OSButton icon={<ArrowBackIcon />} variant="primary-full" size="large" fit onClick={() => cgp ? navigate(-1) : window.location.replace('/documents')} title={cgp ? "Revenir au profil client" : "Revenir aux documents"}/>
        )}
        <div className="os-flex gap-16">
          <h4 className="os-h4">{cgp ? 'Tous les documents' : 'Mes documents Openstone'}</h4>
          <div className="os-flex gap-8 doc-badges">
            {(badges && badges.length > 0) && badges.map(b => getBadge(b.status, 'signable', `${b.number} ${b.text}`))}
          </div>
        </div>
	      <p>Vous pouvez retrouver les bulletins de souscription liés à leur souscription dans l'onglet 'Mon investissement' d'un produit</p>
        {page !== 'preview' && (
          <div className="opportunities-form">
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Type de document</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={selectedContractType || null}
                label="Opportunities"
                onChange={event => setSelectedContractType(event.target.value)}>
                <MenuItem value={'all'}>Tous les types de documents</MenuItem>
                <MenuItem value={'fic'}>Fiche d'information client</MenuItem>
                <MenuItem value={'ldm'}>Lettre de mission</MenuItem>
                <MenuItem value={'der'}>Document d'entrée en relation</MenuItem>
                <MenuItem value={'ra_pe'}>Rapport d'activité Fonds Private Equity</MenuItem>
                <MenuItem value={'ra_cd'}>Rapport d'activité Club Deals</MenuItem>
                <MenuItem value={'synthetic_information_document'}>Documents d'information synthétique</MenuItem>
              </Select>
            </FormControl>
          </div>
        )}
        <div className="documents-container">
          {renderDocuments()}
        </div>
        {(page === 'preview' && documents.length > 9) && (
          <div className="see-more">
            <OSButton size="large" variant="secondary" title="Voir plus de documents" link="/documents/generaux" fit />
          </div>
        )}
      </div>
    </>
  );
};

export default Documents;
