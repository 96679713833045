import * as React from 'react';
import { useState } from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import CssBaseline from '@mui/material/CssBaseline';

import NavItem from "../NavItem/NavItem";
import Profile from "../Profile";
// import WorkspaceSwitcher from "../../../components/WorskpaceSwitcher/index.js";
import Images from "../../../assets/images";

import CgpProfile from "../Profile/cgp";

import './style.scss';

const drawerWidth = 240;

const openedMixin = (theme) => ({
	width: drawerWidth,
	transition: theme.transitions.create('width', {
		easing: theme.transitions.easing.sharp,
		duration: theme.transitions.duration.enteringScreen,
	}),
	overflowX: 'hidden',
});

const closedMixin = (theme) => ({
	transition: theme.transitions.create('width', {
		easing: theme.transitions.easing.sharp,
		duration: theme.transitions.duration.leavingScreen,
	}),
	overflowX: 'hidden',
	width: `calc(${theme.spacing(7)} + 1px)`,
	[theme.breakpoints.up('sm')]: {
		width: `calc(${theme.spacing(8)} + 1px)`,
	},
});

const DrawerHeader = styled('div')(({ theme }) => ({
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'flex-start',
	background_color: '#F9FAFB',
	padding: theme.spacing(3, 0, 2, 2),
	...theme.mixins.toolbar,
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
	({ theme, open }) => ({
		width: drawerWidth,
		flexShrink: 0,
		whiteSpace: 'nowrap',
		boxSizing: 'border-box',
		backgroundColor: '#F9FAFB',
		...(open && {
			...openedMixin(theme),
			'& .MuiDrawer-paper': openedMixin(theme),
		}),
		...(!open && {
			...closedMixin(theme),
			'& .MuiDrawer-paper': closedMixin(theme),
		}),
	}),
);

export default function MiniDrawer({ links, generalLinks, soonLinks, cgp, user, onWorkspaceSwitch, suportLinks }) {
	const [open] = useState(true);
	
	return (
		<Box sx={{ display: 'flex' }}>
			<CssBaseline />
			<Drawer variant="permanent" open={open}>
				<DrawerHeader>
					<img
						src={Images.brand.GreenLogo}
						className="auth-os-logo auth-os-logo-drawer"
						onClick={() => window.location.replace(cgp ? '/cgp/dashboard' : '/overview')}
					/>
				</DrawerHeader>
				
				{/* Add Workspace Switcher
				{((cgp && user) && user.role !== 'cgp') && (
					<div className="workspace-switcher">
						<WorkspaceSwitcher onSwitch={onWorkspaceSwitch} />
					</div>
				)}
				*/}
				
				<List>
					<div className="os-flex__column links-container">
						<span className="drawer-section-title">Gestion</span>
						{generalLinks.map((l) => (
							<div className="sub-links" key={`desktop_subs_${l.title}`}>
								<NavItem to={l.link} key={`desktop_gestion_${l.title}`}>
									{l.icon}
									{l.title}
								</NavItem>
							</div>
						))}
						{/*cgp && (
							<div className="os-flex__column soon-container">
								<div className="coming-soon-container">
									<Badge color="info">À venir</Badge>
								</div>
								{soonLinks.map((l) => (
									<div className="sub-links" key={`soon_links${l.title}`}>
										<NavItem key={`soon_links_${l.title}`}>
											{l.icon}
											{l.title}
										</NavItem>
									</div>
								))}
							</div>
						)*/}
					</div>
				</List>
				<List className="joyride">
					<div className="os-flex__column links-container">
						<span className="drawer-section-title">Nos offres</span>
						{links.map((l) => (
							<div className="sub-links" key={`desktop_subs_${l.title}`}>
								<NavItem to={l.link}>
									{l.icon && l.icon}
									{l.title}
								</NavItem>
							</div>
						))}
					</div>
				</List>
				{/*((cgp && user) && user.role !== 'cgp') && (
					<List>
						<div className="os-flex__column links-container">
							<span className="drawer-section-title">Gestion</span>
							{suportLinks.map((l) => (
								<div className="sub-links" key={`desktop_subs_${l.title}`}>
									<NavItem to={l.link} key={`desktop_gestion_${l.title}`}>
										{l.icon}
										{l.title}
									</NavItem>
								</div>
							))}
						</div>
					</List>
				)*/}
				<div className="bottom-drawer">
					{!cgp && <Profile />}
				</div>
				<div className="bottom-drawer">
					{(cgp && user) && <CgpProfile cgp={user} />}
				</div>
			</Drawer>
		</Box>
	);
}
