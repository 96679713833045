import React from 'react';
import { FormControlLabel, Checkbox, FormHelperText } from '@material-ui/core';

const OsCheckbox = ({ formik, name, label }) => {
	const handleChange = (event) => {
		const checked = event.target.checked; // Get checkbox state
		const currentValue = formik.values[name];
		
		// Handle array values for multi-selection
		if (Array.isArray(currentValue)) {
			const updatedValues = checked
				? [...currentValue, label] // Add value
				: currentValue.filter(item => item !== label); // Remove value
			formik.setFieldValue(name, updatedValues);
		}
		// Handle boolean values for single checkboxes
		else {
			formik.setFieldValue(name, checked);
		}
		
		// Mark field as touched
		formik.setTouched({ ...formik.touched, [name]: true });
	};
	
	// Determine whether the checkbox is checked
	const isChecked = Array.isArray(formik.values[name])
		? formik.values[name]?.includes(label) // For arrays
		: Boolean(formik.values[name]); // For booleans
	
	// Check for validation errors
	const hasError = Boolean(formik.errors[name] && formik.touched[name]);
	
	// Custom label style
	const labelStyle = {
		backgroundColor: '#F2F5F8',
		padding: "12px 4px",
		borderRadius: 2,
		width: '100%',
		border: isChecked ? "1px solid #5A9496" : "1px solid #EAEDF1",
		boxSizing: "border-box",
		cursor: 'pointer',
		marginLeft: '-4px',
		marginRight: 0
	};
	
	return (
		<div style={{width: "98%"}}>
			<FormControlLabel
				control={
					<Checkbox
						checked={isChecked}
						onChange={handleChange}
						style={{ color: '#5A9496', cursor: 'pointer' }}
					/>
				}
				label={label}
				style={labelStyle}
			/>
			{hasError && (
				<FormHelperText style={{ color: 'red', marginTop: '4px' }}>
					{formik.errors[name]}
				</FormHelperText>
			)}
		</div>
	);
};

export default OsCheckbox;
