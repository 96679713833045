import React, {useEffect, useState} from 'react';
import {useNavigate} from "react-router-dom";

import { Box, Typography } from '@mui/material';
import PersonIcon from '@mui/icons-material/Person';
import BusinessIcon from '@mui/icons-material/Business';
import WorkIcon from '@mui/icons-material/Work';
import PeopleIcon from '@mui/icons-material/People';

import OSButton from "../../components/Button";

const tabs = [
	{ label: 'Personne physique', value: 'User', icon: <PersonIcon /> },
	{ label: 'Personne morale', value: 'Company', icon: <BusinessIcon /> },
];

const professionTabs = [
	{ label: 'Professionel', value: 'Professional', icon: <WorkIcon /> },
	{ label: 'Non professionel', value: 'Non-Professional', icon: <PeopleIcon /> },
];

const ClientCreation = () => {
	const [userType, setUserType] = useState('');
	const [professionType, setProfessionType] = useState('');
	const [disabled, setDisabled] = useState(true);
	const navigate = useNavigate();
	
	const handleUserTypeChange = (value) => {
		setUserType(value);
	};
	
	const handleProfessionTypeChange = (value) => {
		setProfessionType(value);
	};
	
	useEffect(() => {
	 (userType && professionType) && setDisabled(false);
	}, [userType, professionType]);
	
	const navigateToSurvey = () => {
		let path = userType === 'User'
			? '/cgp/clients/creation/personne-physique'
			: '/cgp/clients/creation/personne-morale';
		
		if (userType === 'User') {
			path += '?professionalCategory=non-professional';
		} else if (userType === 'Company') {
			if (professionType === 'Professional') {
				path += '?professionalCategory=professional';
			} else if (professionType === 'Non-Professional') {
				path += '?professionalCategory=non-professional';
			}
		}
		
		navigate(path);
	};
	
	const tabStyle = (isSelected) => ({
		width: '49%',
		minHeight: '110px',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'center',
		margin: '8px',
		cursor: 'pointer',
		borderRadius: '8px',
		border: isSelected ? '2px solid #5A9496' : '2px solid #EAEDF1',
		backgroundColor: isSelected ? '#F2F5F8' : '#FFFFFF',
		boxShadow: isSelected ? '0 4px 6px rgba(0, 0, 0, 0.1)' : 'none'
	});
	
	return (
		<>
			<div>
				<p className="os-subtitle1">Typologie du client</p>
				<p className="os-body1">Le questionnaire d'adéquation est personnalisé en fonction de votre typologie, garantissant une évaluation adaptée à votre profil.</p>
			</div>
			
			<div>
				<p className="os-subtitle2">Êtes-vous une personne physique ou morale ?</p>
				<div style={{ display: 'flex', gap: 12 }}>
					{tabs.map((tab) => (
						<Box
							key={tab.value}
							onClick={() => handleUserTypeChange(tab.value)}
							sx={tabStyle(userType === tab.value)}
						>
							{tab.icon}
							<Typography>{tab.label}</Typography>
						</Box>
					))}
				</div>
			</div>
			
			<div>
				<p className="os-subtitle2">Quelle est votre classification professionnelle ?</p>
				<div style={{ display: 'flex', gap: 12 }}>
					{professionTabs.map((tab) => (
						<Box
							key={tab.value}
							onClick={() => handleProfessionTypeChange(tab.value)}
							sx={tabStyle(professionType === tab.value)}
						>
							{tab.icon}
							<Typography>{tab.label}</Typography>
						</Box>
					))}
				</div>
			</div>
			
			<div className="os-flex" style={{ justifyContent: "flex-end", marginTop: 32}}>
				<OSButton variant="primary" size="large" fit title="Continuer" onClick={() => navigateToSurvey()} disabled={disabled} />
			</div>
		</>
	);
};

export default ClientCreation;
