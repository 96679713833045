import React, {useEffect, useState} from 'react';
import { Formik, Form } from 'formik';
import { FormControl, InputLabel, MenuItem, Select } from '@material-ui/core';

import {MyTextInput} from "../../Inputs";
import MoneyHelper from "../../../Helpers/MoneyHelper";
import NumberHelper from "../../../Helpers/NumberHelper";

import './style.scss';

const primePartOptions = [
	{ value: 'A1', label: 'Part A1 – Investissement inférieur à 500 000 €' },
	{ value: 'A2', label: 'Part A2 – Investissement supérieur ou égal à 500 000 €' },
	{ value: 'A3', label: 'Part A3 (Clean Share) – Investissement inférieur à 500 000 €' },
	{ value: 'A4', label: 'Part A4 (Clean Share) – Investissement supérieur ou égal à 500 000 €' },
	{ value: 'A5', label: 'Part A5 (Clean Share) – Réservé aux investisseurs institutionnels' },
];

const yieldPartTypes = [
	{ value: 'capitalisation', label: 'Parts de capitalisation' },
	{ value: 'distribution', label: 'Parts de distribution' },
];

const yieldCapitalisationOptions = [
	{ value: 'A1c', label: 'Part A1c - Investissement inférieur à 500 000 €' },
	{ value: 'A2c', label: 'Part A2c - Investissement supérieur ou égal à 500 000 €' },
	{ value: 'A3c', label: 'Part A3c Clean Share – Investissement inférieur à 500 000 €' },
	{ value: 'A4c', label: 'Part A4c Clean Share – Investissement supérieur ou égal à 500 000 €' }
];

const yieldDistributionOptions = [
	{ value: 'A1d', label: 'Part A1d - Investissement inférieur à 500 000 €' },
	{ value: 'A2d', label: 'Part A2d - Investissement supérieur ou égal à 500 000 €' },
	{ value: 'A3d', label: 'Part A3d Clean Share – Investissement inférieur à 500 000 €' },
	{ value: 'A4d', label: 'Part A4d Clean Share – Investissement supérieur ou égal à 500 000 €' }
];

const DepositForm = ({product, page, func, pullData, isClicked, minimumInvestment, cgp}) => {
	const [amount, setAmount] = useState(((page !== 'opportunityView' || page !== 'opportunityStep1') && sessionStorage.getItem('InvestmentAmount')) || null);
	const [cgpEntryFees, setCgpEntryFees] = useState(((page !== 'opportunityView' || page !== 'opportunityStep1') && sessionStorage.getItem('CgpEntryFees')) || product.cgp_fees_infos?.max_entry_fees);
	const [cgpManagementFees, setCgpManagementFees] = useState(((page !== 'opportunityView' || page !== 'opportunityStep1') && sessionStorage.getItem('CgpManagementFees')) || null);
	const [isLoading, setIsLoading] = useState(false);
	const [error, setError] = useState('');
	const [errorCgpEntryFees, setErrorCgpEntryFees] = useState(cgpEntryFees === null ? '' : '');
	const [errorSgpEntryFees, setErrorSgpEntryFees] = useState('');
	const [errorTotalEntryFees, setErrorTotalEntryFees] = useState('');
	const [errorCgpManagementFees, setErrorCgpManagementFees] = useState('');
	const [errorParts, setErrorParts] = useState('');
	const [total, setTotal] = useState(null);
	const isPrimeProduct = product?.access_type?.toLowerCase().includes('prime');
	const isYieldProduct = product?.access_type?.toLowerCase().includes('yield');
	const isPrimeOrYield = isPrimeProduct || isYieldProduct;
	const [sgpEntryFees, setSgpEntryFees] = useState(((page !== 'opportunityView' || page !== 'opportunityStep1') && sessionStorage.getItem('SgpEntryFees')) || 0);
	const [yieldPartOptions, setYieldPartOptions] = useState(isYieldProduct ? yieldCapitalisationOptions : []);
	let partOptions = [];
	if (isPrimeProduct) {
		partOptions = primePartOptions;
	} else if (isYieldProduct) {
		partOptions = yieldPartOptions;
	}
	
	// Types of parts
	const [partType, setPartType] = useState(isYieldProduct ? yieldPartTypes[0].value : null);
	const [selectedTypeLabel, setSelectedTypeLabel] = useState(isYieldProduct ? yieldPartTypes[0].label : null);
	// Part options
	const [selectedValue, setSelectedValue] = useState(isPrimeOrYield ? partOptions[0].value : null);
	const [selectedLabel, setSelectedLabel] = useState(isPrimeOrYield ? partOptions[0].label : null);
	
	const checkEntryFees = () => {
		const fees1 = Number(cgpEntryFees) || 0;
		const fees2 = Number(sgpEntryFees) || 0;
		return isPrimeOrYield ? fees1 + fees2 : fees1;
	}
	
	const updatePartOptions = (amount) => {
		const INVESTMENT_THRESHOLD = 500000;
		const partPrefix = amount < INVESTMENT_THRESHOLD ? 'A1' : 'A2';
		const partSuffix = partType === 'capitalisation' ? 'c' :
			partType === 'distribution' ? 'd' :
				'';
		
		const targetValue = `${partPrefix}${partSuffix}`;
		const selectedOption = partOptions.find((option) => option.value === targetValue);
		setSelectedValue(selectedOption?.value ?? null);
		setSelectedLabel(selectedOption?.label ?? '');
		setErrorParts('');
	};
	
	const handleChange = (event) => {
		const value = event.target.value;
		const selectedOption = partOptions.find(option => option.value === value);
		setSelectedValue(value);
		setSelectedLabel(selectedOption?.label || '');
		setErrorParts('');
	};
	
	const handlePartTypeChange = (event) => {
		const value = event.target.value;
		const selectedType = yieldPartTypes.find(option => option.value === value);
		setSelectedTypeLabel(selectedType?.label);
		setPartType(value);
	};
	
	const updateAmount = (value) => {
		let minimum = parseInt(minimumInvestment)
		if (value === '' || value === null || value === 0) {
			setError('Un montant doit être renseigné');
		} else if (value < minimum) {
			setError(`L'investissement doit être au minimum de ${MoneyHelper.formatEuro(minimum, false, 0, 0)}`);
		} else {
			setError('');
		}
		setAmount(value);
	};
	
	const updateCgpEntryFees = (fees) => {
		const maxFees = isPrimeOrYield ? 5 : product.cgp_fees_infos?.max_entry_fees;
		const errorMessage = `Doit être entre 0 et ${maxFees}%`;
		
		setErrorCgpEntryFees( fees > maxFees ? errorMessage : '');
		
		setCgpEntryFees(fees);
	};
	
	const updateCgpManagementFees = (fees) => {
		if (fees < 0 ) {
			setErrorCgpManagementFees(`Doit être entre 0 et 1%`)
		} else if (fees > 1) {
			setErrorCgpManagementFees(`Doit être entre 0 et 1%`)
		} else {
			setErrorCgpManagementFees('')
			setCgpManagementFees(fees)
		}
	}
	
	{/*const updateSgpEntryFees = (fees) => {
		const maxFees = product.cgp_fees_infos?.sgp_entry_fees;
		const errorMessage = `Doit être entre 0 et ${maxFees}%`;
		
		setErrorSgpEntryFees(fees < 0 || fees > maxFees ? errorMessage : '');
		
		setSgpEntryFees(fees);
	};
	
	const checkSgpFees = () => {
		if (product.cgp_fees_infos.change_sgp_entry_fees === true) {
			return (
				<div>
					<MyTextInput
						name="sgpEntryFees"
						type="number"
						placeholder={`${product.cgp_fees_infos.sgp_entry_fees}%`}
						isClicked={isClicked}
						value={sgpEntryFees || ''}
						onChange={(e) => updateSgpEntryFees(Number(e.target.value))}
					/>
					<span className="error">{errorSgpEntryFees}</span>
				</div>
			)
		} else {
			return sgpEntryFees
		}
	*/}
	
	useEffect(() => {
		if (amount !== null) {
			cgpEntryFees !== null && setTotal(amount * ((checkEntryFees() / 100) + 1));
			isPrimeOrYield && updatePartOptions(amount)
		}
	}, [amount, cgpEntryFees, isPrimeOrYield]);
	
	useEffect(() => {
		setSelectedValue(null);
		setSelectedLabel(null);
		if (partType === 'capitalisation') {
			setYieldPartOptions(yieldCapitalisationOptions)
			setErrorParts('Vous devez choisir une part de capitalisation');
		} else if (partType === 'distribution') {
			setYieldPartOptions(yieldDistributionOptions)
			setErrorParts('Vous devez choisir une part distribution');
		} else {
			setYieldPartOptions(yieldPartTypes)
		}
	}, [partType])
	
	useEffect(() => {
		if (checkEntryFees() > 5.00) {
			setErrorTotalEntryFees(`Le total des frais ne peut pas excéder 5%`)
		} else {
			setErrorTotalEntryFees('')
		}
	}, [cgpEntryFees, sgpEntryFees])
	
	return (
		<>
			<Formik
				initialValues={{
					amount: amount,
					cgpEntryFees: cgpEntryFees,
					cgpManagementFees: cgpManagementFees,
					share: selectedValue
				}}
				onSubmit={ async (values ) => {
					setIsLoading(true);
					if (page === 'opportunityView') {
						if (amount !== null) {
							sessionStorage.setItem('InvestmentAmount', amount)
							const currentUrl = new URL(window.location.href);
							currentUrl.pathname += '/finalise';
							window.location.replace(currentUrl.toString());
						} else {
							setError("Vous devez entrer un montant")
						}
					} else if (page === 'opportunityView' && product.name === 'Livret P.') {
						if (amount !== null) {
							window.location.href = `/opportunites/livret-p/deposit${`?amount=${amount}`}`
						} else {
							setError("Vous devez entrer un montant")
						}
					} else if (page === 'opportunityStep1') {
						if (cgpEntryFees !== null) {
							if (total !== null) {
								sessionStorage.setItem('InvestmentAmount', amount)
								sessionStorage.setItem('CgpEntryFees', cgpEntryFees)
								sessionStorage.setItem('SgpEntryFees', sgpEntryFees)
								cgpManagementFees && sessionStorage.setItem('CgpManagementFees', cgpManagementFees)
								selectedValue && sessionStorage.setItem('fundShare', selectedValue)
								func(true)
							}
						} else {
							setAmount(amount);
							if (amount !== null) {
								sessionStorage.setItem('InvestmentAmount', amount)
								sessionStorage.setItem('SgpEntryFees', sgpEntryFees)
								selectedValue && sessionStorage.setItem('fundShare', selectedValue)
								func(true)
							}
						}
					} else if (page === 'opportunityStep2') {
						if (cgpEntryFees !== null) {
							if (total !== null) {
								sessionStorage.setItem('InvestmentAmount', amount)
								cgp && sessionStorage.setItem('CgpEntryFees', cgpEntryFees)
								cgpManagementFees && sessionStorage.setItem('CgpManagementFees', cgpManagementFees)
								func()
							}
						} else {
							if (cgp) {
								sessionStorage.setItem('InvestmentAmount', amount)
								func()
							} else {
								setAmount(amount);
								amount !== null && sessionStorage.setItem('InvestmentAmount', amount)
								pullData(amount, true);
							}
						}
					}
					setIsLoading(false);
				}}
			>
				<Form className="complete-infos__container">
					<>
						<h5 className="os-subtitle1">Montant de la souscription</h5>
						<MyTextInput
							name="amount"
							type="number"
							placeholder={`${minimumInvestment !== undefined && NumberHelper.numberWithSpaces(minimumInvestment)} €`}
							isClicked={isClicked}
							value={amount}
							onChange={(e) => updateAmount(e.target.value)}
							onWheel={(e) => e.target.blur()}
						/>
						{error !== '' && <p className="error">{error}</p>}
					</>
					
					{(cgp && (page === 'opportunityStep1' || page === 'opportunityStep2')) && (
						<>
							<h5 className="os-subtitle1">Droits d'entrée</h5>
							<MyTextInput
								name="cgpEntryFees"
								type="text" // Changement de 'number' à 'text'
								inputMode="decimal" // Ajoute un clavier numérique sur mobile
								min={0}
								step="any"
								placeholder={`0-${isPrimeOrYield ? '5' : product.cgp_fees_infos.max_entry_fees}%`}
								value={cgpEntryFees ?? ''}
								onChange={(e) => {
									const value = e.target.value.replace(/^0+(?=\d)/, ''); // Supprime les zéros au début
									updateCgpEntryFees(Number(value));
								}}
							/>
							<span className="os-caption" style={{marginBottom: 8}}>Les droits d’entrée versés par le souscripteur s’élèvent à {isPrimeOrYield ? '5' :`${product.cgp_fees_infos.max_entry_fees}`}% maximum du montant de souscription et vous sont redistribués dans leur entièreté</span>
							{errorCgpEntryFees !== '' && <p className="error">{errorCgpEntryFees}</p>}
							
							{(product.cgp_fees_infos.management_fees === 'special' && !isPrimeOrYield) && (
								<>
									<h5 className="os-subtitle1">Frais de gestion</h5>
									<span className="os-caption" style={{marginBottom: 8}}>
										Les frais de gestion versés par le souscripteur se décomposent de la manière suivante :
										<br /><br />
										1% d’incompressible du montant de souscription pour Openstone
										<br />
										1% maximum du montant de souscription qui vous sont redistribués
									</span>
									<MyTextInput
										name="cgpManagementFees"
										type="number"
										placeholder={`0-1%`}
										isClicked={isClicked}
										value={cgpManagementFees || ''}
										onChange={(e) => updateCgpManagementFees(Number(e.target.value))}
									/>
									{errorCgpManagementFees !== '' && <p className="error">{errorCgpManagementFees}</p>}
								</>
							)}
							{total !== null && <p className="os-body1 info-text">Montant total à régler par le client (Investissement + droits d’entrée de {product.cgp_fees_infos.max_entry_fees} %*) : <strong>{MoneyHelper.formatEuro(total, false, 0, 0)}</strong></p>}
							{/* isPrimeOrYield && (<span className="os-flex gap-8 os-caption info-text" style={{marginBottom: 8, alignItems: 'center'}}>* Sont ajoutés {checkSgpFees()}% dûs à la société de gestion </span>)*/}
							{errorTotalEntryFees && (<span className="error" >{errorTotalEntryFees}</span>)}
							
							{isYieldProduct && (
								<>
									<h5 className="os-subtitle1" style={{marginBottom: -8}}>Type de parts</h5>
									<p className="os-body1">Veuillez choisir le type de parts correspondant à votre investissement</p>
									<FormControl fullWidth variant="outlined">
										<InputLabel>{selectedTypeLabel}</InputLabel>
										<Select
											value={partType}
											onChange={handlePartTypeChange}
											label={selectedTypeLabel}
											name="partType"
											style={{ color: '#264C51' }}>
											{yieldPartTypes.map(option => (
												<MenuItem key={option.value} value={option.value}>
													{option.label}
												</MenuItem>
											))}
										</Select>
									</FormControl>
								</>
							)}
							
							{isPrimeOrYield && (
								<>
									<h5 className="os-subtitle1" style={{marginBottom: -8}}>Choix de la part</h5>
									<p className="os-body1">Veuillez choisir la part correspondant à votre investissement</p>
									<FormControl fullWidth variant="outlined">
										<InputLabel>{selectedLabel}</InputLabel>
										<Select
											value={selectedValue}
											onChange={handleChange}
											label={selectedLabel}
											name="part"
											style={{ color: '#264C51' }}>
											{partOptions.map(option => (
												<MenuItem key={option.value} value={option.value}>
													{option.label}
												</MenuItem>
											))}
										</Select>
									</FormControl>
									<span className="error">{errorParts}</span>
								</>
							)}
						</>
					)}
					
					<div className="submit-container">
						<button className="submit-btn" style={{width: '100%', marginTop: 8}} type="submit" disabled={isLoading || error !== '' || errorCgpEntryFees !== '' || errorCgpManagementFees !== '' || errorParts !== '' || errorTotalEntryFees !== '' || errorSgpEntryFees !== ''}>
							Continuer la souscription
						</button>
					</div>
				</Form>
			</Formik>
		</>
	);
};
export default DepositForm;

