import React from 'react';
import { FormControl, InputLabel, MenuItem, Select } from '@material-ui/core';

const OsSelect = ({ formik, name, label, options, value, onChange, defaultValue }) => {
	// Determine the value and change handler
	const currentValue = formik ? formik.values[name] : value || defaultValue || '';
	const handleChange = (event) => {
		if (formik) {
			formik.setFieldValue(name, event.target.value); // Use formik if provided
		}
		if (onChange) {
			onChange(event); // Trigger the standalone onChange handler
		}
	};
	
	return (
		<FormControl fullWidth variant="outlined">
			<InputLabel>{label}</InputLabel>
			<Select
				value={currentValue}
				onChange={handleChange}
				label={label}
				name={name}
				style={{ color: '#264C51' }}
			>
				{options.map((option) => (
					<MenuItem key={option.value} value={option.value}>
						{option.label}
					</MenuItem>
				))}
			</Select>
		</FormControl>
	);
};

export default OsSelect;
