import React from 'react';
import TextField from '@material-ui/core/TextField';
import OsSelect from "../../Form/Fields/OsSelect";
import {workCategories} from "../../../pages/KycSurvey/Steps/choices";

const WorkInfos = ({ formik, sortedCountries }) => {
	return (
		<>
			<div className="user-survey-questions-container">
				<TextField
					fullWidth
					id="work"
					name="work"
					label="Profession"
					value={formik.values.work}
					onChange={formik.handleChange}
					error={formik.touched.work && Boolean(formik.errors.work)}
					helperText={formik.touched.work && formik.errors.work}
					variant="outlined"
				/>
				<div>
					<OsSelect
						formik={formik}
						name="workCategory"
						label="Catégorie"
						options={workCategories.map(wc => ({
							value: wc.value,
							label: wc.label,
						}))}
					/>
					<span className="error">{formik.touched.workCategory && formik.errors.workCategory}</span>
				</div>
				<TextField
					fullWidth
					id="workSector"
					name="workSector"
					label="Secteur d'activité"
					value={formik.values.workSector}
					onChange={formik.handleChange}
					error={formik.touched.work && Boolean(formik.errors.work)}
					helperText={formik.touched.work && formik.errors.work}
					variant="outlined"
				/>
			</div>
		</>
	);
};

export default WorkInfos;
