import React, {useEffect, useState} from 'react';
import {useNavigate, useSearchParams} from "react-router-dom";
import {toast} from "react-toastify";
import {CircularProgress} from "@mui/material";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import StepsContainer from "../StepsContainer";
//import MoneyHelper from "../../../../../Helpers/MoneyHelper";
import SignatureNetwork from "../../../../../config/Network/SignatureNetwork.ts";
import DocumentNetwork from "../../../../../config/Network/DocumentNetwork.ts";
import Pdf from "../../../../../components/Document/pdf";
import BannerLoader from "../../../../../components/Loaders/Banner";
import OSButton from "../../../../../components/Button";

import UserTransactionNetwork from "../../../../../config/Network/UserTransactionNetwork.ts";
import CompanyTransactionNetwork from "../../../../../config/Network/CompanyTransactionNetwork.ts";
import CgpNetwork from "../../../../../config/Network/CgpNetwork.ts";

// import DepositForm from "../../../../../Forms/Opportunities/Deposit/DepositForm";

import './style.scss';

const Signature = ({product, nextStep, storageDefaultProfile, page, cgp, clientId, clientType, minimumInvestment, object}) => {
  const defaultProfile = cgp ? (clientType !== 'Company') : storageDefaultProfile
  const [amount, setAmount] = useState(sessionStorage.getItem('InvestmentAmount') || null);
  const [cgpEntryFees, setCgpEntryFees] = useState(sessionStorage.getItem('CgpEntryFees') || null);
  const [fundsCallChoice] = useState(sessionStorage.getItem('FundsCall') || false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [pdfUrl, setPdfUrl] = useState(null);
  const [loading, setLoading] = useState(page !== 'withdrawal');
  const [pdfLoading, setPdfLoading] = useState(true);
  const [submitting, setSubmitting] = useState(false);
  const [listing, setListing] = useState(true);
  const [documentToSign, setDocumentToSign] = useState('');
  const [documentToSignTitle, setDocumentToSignTitle] = useState('');
  const [toSignDocs, setToSignDocs] = useState([]);
  const [signedDocs, setSignedDocs] = useState([]);
  const [userDocuments, setUserDocuments] = useState([]);
  const [canContinue, setCanContinue] = useState(false);
  const [validate, setValidate] = useState(true);
  const [infoMsg, setInfoMsg] = useState(false);
  const [transactionId, setTransactionId] = useState(null);
  const localStoragePromoCode = sessionStorage.getItem('promoCode');
  const [withdrawDocId, setWithdrawDocId] = useState(null);
  const [withdrawSuccess, setWithdrawSuccess] = useState(false);
  const navigate = useNavigate();
	const isPrimeProduct = product?.access_type?.toLowerCase().includes('prime');
	const isYieldProduct = product?.access_type?.toLowerCase().includes('yield');
	const isPrimeOrYield = isPrimeProduct || isYieldProduct;
  const typeOfBs = !isPrimeOrYield ? 'bs' : (defaultProfile ? 'bs' : 'bs_pm');

  function checkGender() {
    if (documentToSignTitle === "Fiche d'information client" || documentToSignTitle === "Lettre de mission") {
      return `${documentToSignTitle} signée !`
    } else {
      return `${documentToSignTitle} signé !`
    }
  }
	
	const getUserDocuments = async () => {
		setPdfLoading(true);
		
		const response = (defaultProfile || clientType === 'User')
			? await DocumentNetwork.get_documents(product.id, clientId)
			: await DocumentNetwork.get_company_documents(product.id, clientId);
		
		if (response) {
			{ /*
				Collect all signed document types
				const signedDocumentTypes = response
					.filter(doc => doc.check_document_state && doc.status === "signed")
					.map(doc => doc.document_type);
				
				 Update signedDocs state once with the collected types
				 setSignedDocs(signedDocumentTypes);
				 setSignedDocs(prevSignedDocs => Array.from(new Set([...prevSignedDocs, ...signedDocumentTypes])));
		 	*/}
			
		  // Update userDocuments state
			setUserDocuments(response);
			setPdfLoading(false);
			setLoading(false);
		} else {
			setPdfLoading(true);
		}
	};
	
	const getDocumentValues = () => ({
		amount: page === 'withdrawal' ? sessionStorage.getItem('@amount') : amount,
		cgp_entry_fees: cgp && sessionStorage.getItem('CgpEntryFees'),
		sgp_entry_fees: cgp && sessionStorage.getItem('SgpEntryFees'),
		fundsCall: fundsCallChoice,
		...(sessionStorage.getItem('cgp_management_fees') && {cgp_management_fees: sessionStorage.getItem('CgpManagementFees')}),
		...(sessionStorage.getItem('fundShare') && {fundShare: sessionStorage.getItem('fundShare')}),
	});
	
	const handleResponseError = (toastId, message) => {
		toast.error(message, {toastId});
	};
	
	const fillPdf = async (type) => {
		setPdfLoading(true);
		try {
			const documentValues = getDocumentValues();
			const response = await SignatureNetwork.fill_values(
				type,
				parseInt(product.id),
				documentValues,
				defaultProfile ? 'user' : 'company',
				clientId
			);
			
			if (response.pdf_url) {
				setPdfUrl(response.pdf_url);
				setPdfLoading(false);
			} else {
				setPdfLoading(true);
			}
		} catch (error) {
			console.error('Error filling PDF:', error);
		}
	};
	
	function loadDocument(type, title) {
		if (signedDocs.includes(type)) {
			const doc = userDocuments.find((ud) => ud.document_type === type);
			if (doc) setPdfUrl(doc.file.url);
		} else {
			fillPdf(type);
		}
		setListing(false);
		setDocumentToSign(type);
		setDocumentToSignTitle(title);
	}
	
	const signDocument = async () => {
		setSubmitting(true);
		try {
			const documentValues = getDocumentValues();
			const response = await SignatureNetwork.sign(
				documentToSign,
				parseInt(product.id),
				{title: documentToSignTitle, ...documentValues},
				defaultProfile ? 'user' : 'company',
				localStoragePromoCode
			);
			
			if (response) {
				if ([typeOfBs, defaultProfile ? 'ra_pe' : 'ra_pe_pm'].includes(response.document_type)) {
					setSignedDocs((prev) => [...prev, response.document_type]);
					setTransactionId(response.transaction_id);
				} else if (page === 'withdrawal' && response.document_type === (defaultProfile ? 'br' : 'br_pm')) {
					setSignedDocs((prev) => [...prev, response.document_type]);
					setWithdrawDocId(response.id.toString());
				}
				setListing(true);
				toast.success(checkGender(), {toastId: `signedDocument-${documentToSign}`});
			} else {
				handleResponseError(`error-signedDocument-${documentToSign}`, `Un problème est survenu lors de la signature.`);
			}
		} catch (error) {
			console.error('Error signing document:', error);
		} finally {
			setSubmitting(false);
		}
	};
	
	async function sendToClient() {
		setSubmitting(true);
		try {
			const documentsToSend = product.name !== 'Livret P.' ? checkRelatedDocumentsArray() : relatedDocumentsLivretP;
			const unsignedDocuments = documentsToSend.filter((document) => !document.signed);
			const documentValues = getDocumentValues();
			
			const response = await CgpNetwork.sendDocumentToClient(
				clientId,
				clientType,
				unsignedDocuments,
				documentValues,
				product.id,
				searchParams.get('transaction_type')
			);
			
			if (response.status === 200) {
				toast.success(response.message, {toastId: `sentDocument`});
				nextStep(true);
				navigate(`${window.location.pathname}?documents_signature=sent`, {state: {clientId, clientType}});
			} else {
				const errorMessage = response.status === 401
					? response.message
					: `Un problème est survenu lors de l'envoi.`;
				handleResponseError(`error-sentDocument`, errorMessage);
			}
		} catch (error) {
			console.error('Error sending document to client:', error);
		} finally {
			setSubmitting(false);
		}
	}

  useEffect(() => {
   page !== 'withdrawal' && getUserDocuments();
  }, [ listing ]);

  useEffect(() => {
    validate &&
			setAmount(sessionStorage.getItem('InvestmentAmount'))
	    setCgpEntryFees(sessionStorage.getItem('CgpEntryFees'))
  }, [validate])

  const isDocumentSubmitting = () => {
    if (submitting) {
      return (
        <button disabled={submitting} className="sign-document-btn">
          <CircularProgress color="inherit" />
          Signature en cours
        </button>
      )
    } else {
      return <OSButton variant="primary" size="large" onClick={() => signDocument(documentToSign)} title="Signer le document" fit submitting={submitting} />
    }
  };

  const DocumentToSign = () => {
    return (
      <>
        <OSButton size="medium" variant="primary-full" onClick={() => setListing(true)} icon={<ArrowBackIcon />} title="Revenir aux documents" fit />
        {(pdfUrl === 'null' || pdfLoading) ? (
          <div className="pdf-loading">
            <CircularProgress color="inherit" />
            <p>Génération du document en cours, veuillez patienter...</p>
          </div>
        ) : (
          <div style={{marginTop: 24}}>
            <div id="scroll-wrapper">
							<iframe
								src={pdfUrl}
								className="document-frame"
								frameBorder="0"
								height={700}
							/>
						</div>

            {!cgp && (
              <div className="sign-document-btn-container">
                {!signedDocs.includes(documentToSign) && isDocumentSubmitting()}
              </div>
            )}
          </div>
        )}
      </>
    )
  };

  function updateDocsToSignList(array) {
    let docsList = [];
    array.map(rd => docsList.push(rd.type))
    setToSignDocs(docsList);
  }
	
	const checkDocTypeAccordingToProfile = (docType) => {
		if (!docType) return null;
		
		const profilePrefix = defaultProfile ? '' : '_pm';
		const proSuffix = (object.investment_profile === "professional" && isPrimeOrYield && !defaultProfile) ? '_pro' : '';
		
		const docTypeMapping = {
			fic: `fic${profilePrefix}${proSuffix}`
		};
		
		return docTypeMapping[docType] || docType;
	};

  let relatedDocuments = [
    {title: "Fiche d'information client", type: checkDocTypeAccordingToProfile('fic'), signed: signedDocs.includes(defaultProfile ? 'fic' : 'fic_pm'), product_id: isPrimeOrYield ? product.id : null},
    {title: "Lettre de mission", type: 'ldm', signed: signedDocs.includes('ldm'), product_id: isPrimeOrYield ? product.id : null},
    {title: `Rapport d'activité ${product.category_name}`, type: (defaultProfile ? 'ra_pe' : 'ra_pe_pm'), signed: (signedDocs.includes((defaultProfile ? 'ra_pe' : 'ra_pe_pm'))), product_id: product.id},
    {title: `Bulletin de souscription ${product.name}`, type: typeOfBs, signed: signedDocs.includes(typeOfBs), product_id: product.id},
  ];

  const relatedDocumentsLivretP = [
    {title: `Bulletin de souscription ${product.name}`, type: 'bs', signed: signedDocs.includes('bs')},
  ]

  const relatedDocumentsWithdrawalLivretP = [
    {title: "Bulletin de retrait Livret P.", type: defaultProfile ? 'br' : 'br_pm', signed: defaultProfile ? signedDocs.includes('br') : signedDocs.includes('br_pm')}
  ]
	
	const checkRelatedDocumentsArray = () => {
		if (cgp && searchParams.get('transaction_type') === 'sepaMandate') {
			const sepaMandateDoc = {
				title: `Mandat de prélèvement SEPA`,
				type: 'sepa_mandate',
				signed: false,
				product_id: product.id,
			};
			
			const exists = relatedDocuments.some(doc => doc.type === sepaMandateDoc.type);
			if (!exists) {
				relatedDocuments.push(sepaMandateDoc);
			}
		} else if (isPrimeOrYield) {
			const EXCLUDED_DOC_TYPES = {
				LDM: 'ldm',
				RA_PE: 'ra_pe',
				RA_PE_PM: 'ra_pe_pm'
			};
			
			const filterExcludedDocuments = (documents) => {
				return documents.filter(doc => ![EXCLUDED_DOC_TYPES.LDM, EXCLUDED_DOC_TYPES.RA_PE, EXCLUDED_DOC_TYPES.RA_PE_PM].includes(doc.type));
			};
			
			relatedDocuments = filterExcludedDocuments(relatedDocuments);
			
			const primeProductDocs = [
				{ title: "CGU", type: 'cgu', signed: signedDocs.includes('cgu'), product_id: product.id },
				{ title: "Déclaration d'origine des fonds", type: defaultProfile ? 'source_of_funds' : 'source_of_funds_pm', signed: defaultProfile ? signedDocs.includes('source_of_funds') : signedDocs.includes('source_of_funds_pm'), product_id: product.id },
			];
			
			if (object.investment_profile !== "professional") {
				primeProductDocs.push({
					title: "Test de connaissances",
					type: 'knowledge_test',
					signed: signedDocs.includes('knowledge_test'),
					product_id: product.id,
				});
			}
			
			primeProductDocs.forEach(doc => {
				const exists = relatedDocuments.some(existingDoc => existingDoc.type === doc.type);
				if (!exists) {
					relatedDocuments.push(doc);
				}
			});
		}
		
		relatedDocuments = moveBsToEnd(relatedDocuments);
		
		return relatedDocuments;
	};
	
	const moveBsToEnd = (documents) => {
		const bsDocs = documents.filter(doc => (doc.type === 'bs') || (doc.type === 'bs_pm'));
		const otherDocs = documents.filter(doc => doc.type !== 'bs' && doc.type !== 'bs_pm');
		return [...otherDocs, ...bsDocs];
	};
	
	
	function checkRelatedDocuments() {
    if (page === 'withdrawal') {
      updateDocsToSignList(relatedDocumentsWithdrawalLivretP);
    } else {
      updateDocsToSignList(product.name !== 'Livret P.' ? relatedDocuments : relatedDocumentsLivretP);
    }
  }

  useEffect(() => {
    checkRelatedDocuments();
    const hasAllElems = toSignDocs.every(elem => signedDocs.includes(elem));
    signedDocs.length !== 0 && setCanContinue(hasAllElems)
  }, [signedDocs])
	
	
	{/*function updateValidate(amount) {
    setValidate(true);
    setInfoMsg(false);
    toast.success('Le nouveau montant a bien été validé, vous pouvez continuer la signature des documents');
  }
	
	function checkIfValidate() {
    if (!validate) {
			return (
		      <div className="update-amount-container colored-container">
		        <DepositForm product={product} page="opportunityStep2" func={updateValidate} minimumInvestment={minimumInvestment} cgp={cgp}/>
		      </div>
				)
    } else {
      return (
        <div className="update-amount-container colored-container">
	        {cgp ?
		        <p className="amount-to-update-text os-body1">Montant total à régler par le client (Investissement + frais d’entrée) : <strong>{MoneyHelper.formatEuro(amount * ((cgpEntryFees / 100) + 1), true, 0, 0)}</strong></p>
		        : <p className="amount-to-update-text os-body1">{cgp ? "Le montant de la souscription est de" : 'Vous souhaitez investir'} {MoneyHelper.formatEuro(amount, true, 0, 0)}</p>
					}
					<OSButton variant="no_border" size="small" onClick={() => setValidate(false)} title="Modifier le montant" fit icon={<EditIcon />}/>
        </div>
      )
    }
  }*/}
	
  function updateTransactionId() {
    transactionId !== undefined && setSearchParams({transaction_id: transactionId.toString()});
    product.name !== 'Livret P.' ? nextStep(true) : window.location.replace(`/opportunites/livret-p/deposit?transaction_id=${transactionId}&amount=${amount}`);
  }

  async function createWithdraw() {
    if (defaultProfile) {
      const responseWithdraw = await UserTransactionNetwork.withdraw(
        sessionStorage.getItem('@amount'),
        withdrawDocId,
        sessionStorage.getItem('@iban'),
        sessionStorage.getItem('@bic')
      );
      responseWithdraw && toast.success('La demande de retrait a bien été enregistrée');
    } else if (!defaultProfile) {
      const responseWithdraw = await CompanyTransactionNetwork.withdraw(
        parseFloat(sessionStorage.getItem('@amount')),
        withdrawDocId,
        sessionStorage.getItem('@iban'),
        sessionStorage.getItem('@bic')
      );
      responseWithdraw && toast.success('La demande de retrait a bien été enregistrée');
    }
    setWithdrawSuccess(true);
  }

  const DocumentsToDisplay = () => (
    <div className="finalise-documents-container">
      {withdrawSuccess ? (
        <>
          <h4 className="os-h4">Transfert programmé</h4>
          <p className="os-subtitle1 success">Votre demande de retrait a bien été pris en compte et arrivera dans les prochains jours.</p>
          <button className="sign-document-btn" onClick={() => window.location.replace('/overview')}>Revenir à mon portefeuille</button>
        </>
      ) :
        <>
          {infoMsg && <p className="info">Veuillez valider le nouveau montant d'investissement</p>}
          <Pdf page="signature"
               func={loadDocument}
               relatedDocuments={(page === 'withdrawal' ? relatedDocumentsWithdrawalLivretP : (product.name !== 'Livret P.' ? checkRelatedDocumentsArray() : relatedDocumentsLivretP))}
               canClick={validate}
               updateInfoMsg={setInfoMsg}
               cgp={cgp}
               cgpAction={sendToClient} submitting={submitting}
          />
          {(canContinue && signedDocs.length !== 0) && (
            <div className="sign-document-btn-container">
              {page === 'withdrawal' ?
                (
                  <button className="sign-document-btn" onClick={() => createWithdraw()}>Demander le retrait</button>
                ): (
                  <button className="sign-document-btn" onClick={() => updateTransactionId()}>Continuer et procéder au paiement</button>
                )}
            </div>
          )}
        </>}
    </div>
  )

  if (loading) {
    return <StepsContainer children={<BannerLoader />} product={product} minimumInvestment={minimumInvestment}/>
  } else if (!loading) {
    return <StepsContainer children={listing ? <DocumentsToDisplay /> : <DocumentToSign />} product={product} minimumInvestment={minimumInvestment}/>
  }
};

export default Signature;
