import React, {useState} from 'react';

import OSButton from "../../Button";
import Typography from "@mui/material/Typography";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import AddAlertIcon from '@mui/icons-material/AddAlert';
import OsTooltip from "../../Tooltips";
import CheckIcon from '@mui/icons-material/Check';
import ProductNework from "../../../config/Network/ProductNework.ts";
import {toast} from "react-toastify";
import Info from "../../info";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import MoneyHelper from "../../../Helpers/MoneyHelper";
import Images from "../../../assets/images";

const LargeOpportunityCard = ({opportunity, page, needRefresh, slider}) => {
    const [submitting, setSubmitting] = useState(false);
    const [showingPreview, setShowingPreview] = useState(false);
    const cgp = sessionStorage.getItem('CgpUserAuthToken');
    async function createInterest() {
        setSubmitting(true);
        const response = await ProductNework.createInterest(opportunity.id);
        if (response.status === 200) {
            toast.success(response.message,{
                toastId: 'createAlertSuccessToast'
            });
            showingPreview && setShowingPreview(false);
            needRefresh(true);
            setSubmitting(false);
        } else {
            toast.error(response.message,{
                toastId: 'createAlertErrorToast'
            });
            setSubmitting(false);
        }
    }

    function renderButton() {
        if (opportunity.subscription_state === 'waiting_list' || opportunity.subscription_state === 'close') {
            if (opportunity.has_interest) {
                return <span className="success os-caption os-flex gap-8" style={{alignItems: 'center'}}><CheckIcon/>Vous êtes inscrit sur la liste d'attente</span>
            } else {
                return <OSButton onClick={() => createInterest()} title="Je m'inscris sur la liste d'attente" size="large" variant="primary-full" submitting={submitting} icon={<AddAlertIcon />} fit />
            }
        } else if (opportunity.subscription_state === 'open' || opportunity.subscription_state === 'waiting_list_with_info') {
            return <OSButton link={`/opportunites/${opportunity.category_slug}/${opportunity.slug}`} title="Accéder au produit" size="large" variant="secondary-white" fit icon={<ArrowForwardIcon />} position="end"/>
        } else if (opportunity.subscription_state === 'end') {
            return <Info color="info" children="La souscription pour ce produit est terminée" />
        }
    }

    function renderCardAction() {
        if (opportunity.subscription_state === 'open') {
            return window.location.replace(`/opportunites/${opportunity.category_slug}/${opportunity.slug}`);
        } else if (opportunity.subscription_state === 'waiting_list' || opportunity.subscription_state === 'close' || opportunity.subscription_state === 'waiting_list_with_info') {
            return setShowingPreview(true);
        }
    }

    return (
			<div className="os-flex large-card" onClick={() => renderCardAction()}>
        <div className="os-flex__column large-card-top-content-container">
	        <div>
						<div className="os-flex large-card-category-content">
								<div className="os-flex gap-8 large-card-top-content">
									<img src={Images.brand.longLogo} className="large-card-os-logo"/>
									<span style={{paddingTop: '4px'}}>| Prime</span>
								</div>
						</div>
						<div className="large-card-name-container">
								<h5 className="os-h5 large-card-name">{opportunity.name}</h5>
								<p dangerouslySetInnerHTML={{__html: opportunity.content}} className="large-card-content" />
						</div>
	        </div>
          <div className="os-flex large-card-bottom-container">
              <div className="os-flex gap-16 large-card-infos-container">
                  <div className="os-flex__column blurred-card">
	                  <span className="large-card__label-value">{opportunity?.actual_rate}</span>
                    <span className="gap-8 large-card__label-name">TRI net cible
                      <OsTooltip title={<Typography color="inherit">Le taux de rentabilité net annuel estimé, fourni à titre indicatif</Typography>}>
                        <div className="tooltip-btn"><InfoOutlinedIcon /></div>
                      </OsTooltip>
                    </span>
                  </div>
                  <div className="os-flex__column blurred-card">
	                  <span className="large-card__label-value">{opportunity.horizon}</span>
                    <span className="gap-8 large-card__label-name">Horizon
                      <OsTooltip title={<Typography color="inherit">Horizon d'investissement fourni à titre indicatif</Typography>}>
                        <div className="tooltip-btn"><InfoOutlinedIcon /></div>
                      </OsTooltip>
                    </span>
                  </div>
                  <div className="os-flex__column blurred-card blurred-card__no-border">
	                  <span className="large-card__label-value">{cgp !== null ? MoneyHelper.formatEuro(opportunity.cgp_minimum_investment) : MoneyHelper.formatEuro(opportunity.minimum_investment)}</span>
                    <span className="gap-8 large-card__label-name">Investissement minimum
                    </span>
                  </div>
              </div>
	           {renderButton()}
          </div>
        </div>
			</div>
    );
};

export default LargeOpportunityCard;
