import React, { useState, useCallback, useEffect, useMemo } from "react";
import { toast } from "react-toastify";

import ShareHolderForm from "./index";
import OsCard from "../../../../../components/card/basic";
import OSButton from "../../../../../components/Button";
import { getShareHolderValidationSchema } from "../../../../../components/Survey/GeneralInformations/validationSchemas";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

import CgpNetwork from "../../../../../config/Network/CgpNetwork.ts";
import CompanyNetwork from "../../../../../config/Network/CompanyNetwork.ts";

import isEqual from "lodash/isEqual";

const ShareHolderChoice = (props) => {
	const [loading, setLoading] = useState(false);
	const [shareholders, setShareholders] = useState([]);
	const [submitting, setSubmitting] = useState(false);
	const [errors, setErrors] = useState([]);
	
	const initializeShareholders = useCallback((shareholders) => {
		if (shareholders && Array.isArray(shareholders)) {
			return shareholders.map((shareholder) => ({
				formData: shareholder || {}, // Initialisation de formData
			}));
		}
		
		return [{ formData: {} }];
	}, []);
	
	useEffect(() => {
		setLoading(true);
		setShareholders(initializeShareholders(props.company?.share_holders));
		setLoading(false);
	}, [props.company.share_holders, initializeShareholders]);
	
	const handleFormDataChange = useCallback((index, data) => {
		setShareholders((prevShareholders) => {
			const updatedShareholders = [...prevShareholders];
			if (
				JSON.stringify(updatedShareholders[index].formData) !==
				JSON.stringify(data)
			) {
				updatedShareholders[index].formData = {
					...updatedShareholders[index].formData,
					...data,
				};
				return updatedShareholders;
			}
			return prevShareholders;
		});
	}, []);
	
	// Add a new shareholder
	const addShareholder = useCallback(() => {
		setShareholders((prevShareholders) => [
			...prevShareholders,
			{ formData: {} },
		]);
	}, []);
	
	const removeShareholder = useCallback((index) => {
		setShareholders((prevShareholders) =>
			prevShareholders.filter((_, i) => i !== index)
		);
	}, []);
	
	const handleSubmit = async () => {
		let hasErrors = false;
		const newErrors = [];
		
		// Validate each shareholder form
		for (let index = 0; index < shareholders.length; index++) {
			const shareholder = shareholders[index];
			try {
				await getShareHolderValidationSchema().validate(shareholder.formData, {
					abortEarly: false,
				});
				newErrors[index] = {}; // No errors for this shareholder
			} catch (validationErrors) {
				hasErrors = true;
				const errorObject = {};
				validationErrors.inner.forEach((err) => {
					errorObject[err.path] = err.message;
				});
				newErrors[index] = errorObject; // Store errors for this shareholder
			}
		}
		
		setErrors(newErrors);
		if (hasErrors) {
			toast.error("Veuillez corriger les erreurs avant de continuer.");
			return;
		}
		
		// Prepare payload
		const formattedShareholders = shareholders.map(
			(shareholder) => shareholder.formData
		);
		const payload = { share_holders: formattedShareholders };
		
		let response;
		response = await (props.isCgp
			? CgpNetwork.updateRecord(payload, props.company.id, "Company")
			: CompanyNetwork.updateCompany(props.company.id, payload));
		
		if (response) {
			toast.success("Enregistrement réussi!");
			props.handleStep(true);
		} else {
			toast.error("Une erreur est survenue lors de l'enregistrement.");
		}
	};
	
	const memoizedShareholders = useMemo(
		() =>
			shareholders.map((shareholder) => ({
				...shareholder,
				formData: shareholder.formData || {}, // Garantit que formData est toujours un objet
			})),
		[shareholders]
	);
	
	// Render the form for each shareholder
	const renderShareholderForm = (index, shareholder) => (
		<ShareHolderForm
			key={index}
			shareHolder={shareholder}
			onFormChange={(data) => handleFormDataChange(index, data)}
			errors={errors[index] || {}}
		/>
	);
	
	return (
		<>
			<div style={{ marginBottom: 32, marginTop: 24 }}>
				<h5 className="os-h5">Bénéficiaires effectifs</h5>
				<span className="os-caption error">
          Tous les champs sont obligatoires.
        </span>
			</div>
			{memoizedShareholders.map((shareholder, index) => (
				<OsCard
					title={`Bénéficiaire ${index + 1}`}
					key={index}
					spaced="up"
					linkTitle="Supprimer"
					conditionForLink={index > 0}
					onClick={() => removeShareholder(index)}
					icon={<DeleteIcon />}
				>
					{renderShareholderForm(index, shareholder)}
				</OsCard>
			))}
			
			<div style={{ marginTop: 24 }}>
				<OSButton
					variant="primary-full"
					size="large"
					onClick={addShareholder}
					title="Ajouter un bénéficiaire"
					fit
					icon={<AddIcon />}
				/>
			</div>
			
			<div
				className="os-flex gap-16"
				style={{ marginTop: 32, justifyContent: "flex-end", alignItems: "center" }}
			>
				<OSButton
					onClick={() => props.handleStep(false)}
					variant="no_border"
					title="Revenir à l'étape précédente"
					fit
					size="medium"
					icon={<ArrowBackIcon />}
					disabled={submitting}
					submitting={submitting}
				/>
				<OSButton
					variant="primary"
					size="large"
					onClick={handleSubmit}
					title="Continuer"
					fit
					style={{ marginTop: "16px" }}
					disabled={submitting}
					submitting={submitting}
				/>
			</div>
		</>
	);
};

export default ShareHolderChoice;
