import React, { useState } from 'react';
import { useFormik, FormikProvider } from 'formik';
import * as yup from 'yup';
import { TextField } from '@mui/material';
import { toast } from 'react-toastify';

import CompanyNetwork from "../../../../config/Network/CompanyNetwork.ts";
import CgpNetwork from "../../../../config/Network/CgpNetwork.ts";

import { businessTypes, financialMarketEntities } from "../../../KycSurvey/Steps/choices";
import sortedCountries from "../../../../config/countries";
import OsCard from "../../../../components/card/basic";
import OsRadio from "../../../../components/Form/Fields/OsRadio";
import OsSelect from "../../../../components/Form/Fields/OsSelect";
import OsDate from "../../../../components/Form/Fields/OsDate";

const CompanyForm = ({ handleStep, mode, isCgp, company, isProfessional }) => {
	const [submitting, setSubmitting] = useState(false);
	const investmentProfile = isProfessional ? 'professional' : 'non-professional';
	
	const companyInitialValues = {
		legalName: company?.legal_name || "",
		businessType: company?.business_type || "",
		siren: company?.siren || "",
		nafCode: company?.naf || "",
		address: company?.address || "",
		zipCode: company?.zip_code || "",
		city: company?.city || "",
		fiscalCountry: company?.fiscal_country || "",
		createdAt: company?.creation_legal_date || "",
		qualification: company?.qualification || "",
		isStockCompany: company?.is_stock_company || "",
	};
	
	// Validation Schema
	const validationSchema = yup.object({
		legalName: yup.string().required('Requis'),
		businessType: yup.string().required('Requis'),
		siren: yup.string().required('Requis').min(9, "9 caractères").max(9, "9 caractères"),
		nafCode: yup.string().required('Requis'),
		address: yup.string().required('Requis'),
		zipCode: yup.string().required('Requis'),
		city: yup.string().required('Requis'),
		fiscalCountry: yup.string().required('Requis'),
		createdAt: yup.string().required('Requis')
			.max(new Date().setDate(new Date().getDate()), "La date doit  au maximum égale à aujourd'hui"),
		qualification: yup.string().required('Requis'),
		isStockCompany: yup.string().oneOf(['true', 'false'], 'Sélection requise').required('Requis'),
	});
	
	// Formik Initialization
	const formik = useFormik({
		initialValues: companyInitialValues,
		validationSchema: validationSchema,
		onSubmit: async (values) => {
			setSubmitting(true);
			const params = {
				legal_name: values.legalName,
				business_type: values.businessType,
				siren: values.siren,
				naf: values.nafCode,
				address: values.address,
				zip_code: values.zipCode,
				city: values.city,
				fiscal_country: values.fiscalCountry,
				creation_legal_date: values.createdAt,
				qualification: values.qualification,
				investment_profile: investmentProfile,
				is_stock_company: values.isStockCompany,
				cgp: isCgp,
			};
			
			try {
				let response;
				if (mode === 'edit') {
					response = isCgp
						? await CgpNetwork.updateRecord(params, company.id, 'Company')
						: await CompanyNetwork.updateCompany(company.id, params);
				} else {
					response = isCgp
					? await CgpNetwork.createRecord({ ...params, record_type: 'Company' })
					: await CompanyNetwork.createCompanies(params);
				}
				
				if (!response || response.errors) {
					toast.error(response.errors.message || "Une erreur est survenue. Veuillez réessayer.");
				} else if (response.message) {
					toast.error(response.message || "Une erreur est survenue. Veuillez réessayer.");
				} else {
					toast.success(mode === 'edit' ? 'Société mise à jour avec succès!' : 'Société créée avec succès!');
					sessionStorage.setItem('Company', JSON.stringify(response));
					handleStep(true, response);
				}
			} catch (error) {
				toast.error("Erreur réseau, veuillez réessayer plus tard.");
			} finally {
				setSubmitting(false);
			}
		}
	});
	
	return (
		<FormikProvider value={formik}>
			<form className="os-user-survey-form" onSubmit={formik.handleSubmit}>
				<div style={{marginBottom: 32, marginTop: 24}}>
					<h5 className="os-h5">Informations de l'entreprise</h5>
					<span className="os-caption error">Tous les champs sont obligatoires.</span>
				</div>
				<OsCard title="Informations principales" spaced="down" >
					<div className="user-survey-questions-container">
						<TextField
							fullWidth
							id="legalName"
							name="legalName"
							label="Raison sociale"
							value={formik.values.legalName}
							onChange={formik.handleChange}
							error={formik.touched.legalName && Boolean(formik.errors.legalName)}
							helperText={formik.touched.legalName && formik.errors.legalName}
							variant={"outlined"}
						/>
						<div>
							<OsSelect
								formik={formik}
								name="businessType"
								label="Forme juridique"
								options={businessTypes.sort().map(b => ({
									value: b.value,
									label: b.label,
								}))}
							/>
							<span className="error">{formik.touched.businessType && formik.errors.businessType}</span>
						</div>
						<TextField
							fullWidth
							id="siren"
							name="siren"
							label="SIREN (ou numéro d'immatriculation)"
							value={formik.values.siren}
							onChange={formik.handleChange}
							error={formik.touched.siren && Boolean(formik.errors.siren)}
							helperText={formik.touched.siren && formik.errors.siren}
							variant={"outlined"}
						/>
						<TextField
							fullWidth
							id="nafCode"
							name="nafCode"
							label="Code NAF"
							value={formik.values.nafCode}
							onChange={formik.handleChange}
							error={formik.touched.nafCode && Boolean(formik.errors.nafCode)}
							helperText={formik.touched.nafCode && formik.errors.nafCode}
							variant={"outlined"}
						/>
						<OsDate formik={formik} name="createdAt" label="Date de création" />
					</div>
				</OsCard>
				<OsCard title="Coordonnées de l'entreprise" spaced="down">
					<div className="user-survey-questions-container">
						<TextField
							fullWidth
							id="address"
							name="address"
							label="Adresse"
							value={formik.values.address}
							onChange={formik.handleChange}
							error={formik.touched.address && Boolean(formik.errors.address)}
							helperText={formik.touched.address && formik.errors.address}
							variant={"outlined"}
							className="full-input"
						/>
						<TextField
							fullWidth
							id="zipCode"
							name="zipCode"
							type="number"
							label="Code postal"
							value={formik.values.zipCode}
							onChange={formik.handleChange}
							error={formik.touched.zipCode && Boolean(formik.errors.zipCode)}
							helperText={formik.touched.zipCode && formik.errors.zipCode}
							variant={"outlined"}
							InputProps={{
								onWheel: (e) => e.target.blur()
							}}
						/>
						<TextField
							fullWidth
							id="city"
							name="city"
							label="Ville"
							value={formik.values.city}
							onChange={formik.handleChange}
							error={formik.touched.city && Boolean(formik.errors.city)}
							helperText={formik.touched.city && formik.errors.city}
							variant={"outlined"}
						/>
						<div>
							<OsSelect
								formik={formik}
								name="fiscalCountry"
								label="Pays"
								options={sortedCountries.sort().map(country => ({
									value: country.name,
									label: country.name,
								}))}
							/>
							<span className="error">{formik.touched.fiscalCountry && formik.errors.fiscalCountry}</span>
						</div>
					</div>
				</OsCard>
				
				<OsCard title="Qualification">
					<div className="os-flex__column gap-16">
						<div>
							<p className="os-body2">La personne morale est:</p>
							<div className="full-input radio-inputs">
								<div className="user-survey-questions-container one-grid">
									{financialMarketEntities.map((entity, index) => (
										<OsRadio
											key={index}
											formik={formik}
											name="qualification"
											value={entity.value}
											label={
												<>
													{entity.value}
													{entity.subCriteria && (
														<ul className="sub-criteria">
															{entity.subCriteria.map((criteria, i) => (
																<li key={i}>{criteria}</li>
															))}
														</ul>
													)}
												</>
											}
										/>
									))}
								</div>
								<span className="error">{formik.touched.qualification && formik.errors.qualification}</span>
							</div>
						</div>
						
						<div>
							<p className="os-body2">La personne morale est-elle une société cotée ?</p>
							<div className="full-input radio-inputs">
								<div className="user-survey-questions-container">
									<OsRadio formik={formik} name="isStockCompany" value="true" label="Oui" />
									<OsRadio formik={formik} name="isStockCompany" value="false" label="Non" />
								</div>
								<span className="error">{formik.touched.isStockCompany && formik.errors.isStockCompany}</span>
							</div>
						</div>
					</div>
				</OsCard>
				
				<div className="user-survey-btn-container">
					{Object.keys(formik.errors).length !== 0 && <span className="error">Vous devez répondre à toutes les questions pour pouvoir continuer</span>}
					<button type="submit" className="user-survey-btn" disabled={submitting}>Continuer</button>
				</div>
			</form>
		</FormikProvider>
	);
};

export default CompanyForm;
