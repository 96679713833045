import React, {useEffect, useState} from 'react';
import ImageUpload from "../../../../components/ImageUpload";
import {CircularProgress} from "@mui/material";
import OSButton from "../../../../components/Button";
import OsCard from "../../../../components/card/basic";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Divider from "@mui/material/Divider";

const Uploads = ({user, handleStep}) => {
  const [submitting, setSubmitting] = useState(false);
  const [upload, setUpload] = useState(false);
  const [nextStep, setNextStep] = useState(false);
  const [iDCount, setIDCount] = useState(0);
  const [residentialCount, setResidentialCount] = useState(0);
  const [ribCount, setRibCount] = useState(0);

  useEffect(() => {
    if (nextStep) {
      handleStep(true);
      window.scrollTo(0, 0);
    }
  }, [nextStep])

  useEffect(() => {
    setIDCount(user.kyc_verification_status === 'verified' && 1);
  }, [user])

  function validUpload() {
    (iDCount > 0 && residentialCount > 0 && ribCount > 0) && setUpload(true);
  }

  function renderButton() {
    if (iDCount === 0 && residentialCount === 0) {
      return <button disabled={true} className="submit-btn">Envoyer les documents</button>
    } else if (submitting) {
      return <button disabled={submitting} className="submit-btn">
        <CircularProgress color="inherit" />
        Documents en cours d'envoi
      </button>
    } else {
      return <button disabled={!(iDCount > 0 && residentialCount > 0 && ribCount > 0)} className="submit-btn" onClick={() => validUpload()}>Envoyer les documents</button>
    }
  }

  return (
		<>
			<div style={{marginBottom: 32, marginTop: 24}}>
				<h5 className="os-h5">Justificatifs</h5>
			</div>
			<OsCard title="" spaced="up">
				<div className="os-grid two-grid gap-24" style={{alignItems: "flex-start"}}>
					{user.kyc_verification_status !== 'verified' && (
						<div>
							<p className="os-subtitle2">Document d'identité</p>
							<ImageUpload userSurveyCheck={setIDCount} upload={upload} submitting={submitting} setSubmitting={setSubmitting} func={setNextStep} type="userSurveyIdentity" documentType="identity" record={user}/>
						</div>
					)}
					<div>
						<p className="os-subtitle2">Justificatif de domicile de moins de 3 mois</p>
						<ImageUpload userSurveyCheck={setResidentialCount} upload={upload} submitting={submitting} setSubmitting={setSubmitting} func={setNextStep} documentType="residency" record={user}/>
					</div>
					<div>
						<p className="os-subtitle2">RIB - Relevé d'identité bancaire</p>
						<ImageUpload userSurveyCheck={setRibCount} upload={upload} submitting={submitting} setSubmitting={setSubmitting} func={setNextStep} documentType="rib" record={user}/>
					</div>
				</div>
				
				<Divider />
				
				<div className="info-callout" style={{marginTop: 24}}>
					<h6 className="os-h6">
						Documents complémentaires
					</h6>
				</div>
				<div className="os-grid two-grid gap-24" style={{alignItems: "flex-start"}}>
					<div>
						<p className="os-subtitle1">Document supplémentaire (optionnel)</p>
						<ImageUpload upload={upload} submitting={submitting} setSubmitting={setSubmitting} func={setNextStep} documentType="other" record={user}/>
					</div>
				</div>
				
				<div className="user-survey-btn-container">
					<OSButton onClick={() => handleStep(false)} variant="no_border" title="Revenir à l'étape précédente" fit size="medium" icon={<ArrowBackIcon />}/>
					{renderButton()}
				</div>
			</OsCard>
		</>
  );
};

export default Uploads;
