import React, {useEffect, useState} from 'react';
import { toast } from "react-toastify";
import { useFormik } from 'formik';
import * as yup from 'yup';

import AuthenticationNetwork from "../../../../config/Network/AuthenticationNetwork.ts";
import CompanyNetwork from "../../../../config/Network/CompanyNetwork.ts";
import CgpNetwork from "../../../../config/Network/CgpNetwork.ts";
import OSButton from "../../../../components/Button";
import OsCheckbox from "../../../../components/Form/Fields/OsCheckbox";
import OsCard from "../../../../components/card/basic";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

const Statement = ({user, handleStep, page, isCgp, company}) => {
	const [submitting, setSubmitting] = useState(false);
	
	const validationSchema = yup.object({
		certify_on_honor: yup.boolean().required("Requis").isTrue("Requis"),
		informed_on_risks: yup.boolean().required("Requis").isTrue("Requis"),
		full_responsability: yup.boolean().required("Requis").isTrue("Requis"),
		change_of_situation: yup.boolean().required("Requis").isTrue("Requis"),
	});
	
	const formik = useFormik({
		initialValues: {
			certify_on_honor: page === 'companyForm' ? company.statements?.certify_on_honor : user.statements?.certify_on_honor || false,
			informed_on_risks: page === 'companyForm' ? company.statements?.informed_on_risks : user.statements?.informed_on_risks || false,
			full_responsability: page === 'companyForm' ? company.statements?.full_responsability : user.statements?.full_responsability || false,
			change_of_situation: page === 'companyForm' ? company.statements?.change_of_situation : user.statements?.change_of_situation || false,
		},
		validationSchema: validationSchema,
		onSubmit: async (values) => {
			setSubmitting(true);
			
			// Prepare data for submission
			const dataToSubmit = {
				statements: {
					certify_on_honor: values.certify_on_honor,
					informed_on_risks: values.informed_on_risks,
					full_responsability: values.full_responsability,
					change_of_situation: values.change_of_situation,
				},
				completed: true // Mark as completed in the same request
			};
			
			try {
				// Choose the network request based on conditions
				const response = isCgp
					? (page === 'companyForm'
						? await CgpNetwork.updateRecord(dataToSubmit, company.id, 'Company')
						: await CgpNetwork.updateRecord(dataToSubmit, user.id, 'User'))
					: (page === 'companyForm'
						? await CompanyNetwork.updateCompany(company.id, dataToSubmit)
						: await AuthenticationNetwork.editUser(dataToSubmit));
				
				if (response) {
					toast.success('Vos informations ont bien été enregistrées', {
						toastId: 'completeInfosSuccessToast',
					});
					handleStep(true); // Move to the next step
				} else {
					throw new Error();
				}
			} catch (error) {
				toast.error("Une erreur est survenue lors de l'enregistrement de vos informations", {
					toastId: 'completeInfosErrorToast',
				});
			} finally {
				setSubmitting(false);
			}
		},
	});
	
	return (
		<div style={{marginTop: 32}}>
			<form className="os-user-survey-form" onSubmit={formik.handleSubmit}>
				<div style={{marginBottom: 32, marginTop: 24}}>
					<h5 className="os-h5">Déclarations</h5>
					<span className="os-caption error">Tous les champs sont obligatoires.</span>
				</div>
				<OsCard title="Vérifier les points suivants" spaced="down" >
					<div className="user-survey-questions-container one-grid">
						<div className="full-input radio-inputs">
							<OsCheckbox
								formik={formik}
								name="certify_on_honor"
								label="J’atteste de l’exactitude des informations ci-dessus et je certifie sur l’honneur qu’elles sont conformes à ma situation notamment vis-à-vis de ma (mes) nationalité(s) et/ou résidence(s) fiscale(s). Je certifie que je n’ai pas d’autre(s) résidence(s) fiscale(s) que celle(s) que j’ai déclarée(s) dans le présent document. Je reconnais avoir été informé(e) que le recueil de ces informations est obligatoire."
							/>
						</div>
					</div>
					
					<div className="user-survey-questions-container one-grid">
						<div className="full-input radio-inputs">
							<OsCheckbox
								formik={formik}
								name="informed_on_risks"
								label="Je déclare être pleinement informé(e) que les Entreprises d’Investissement sont tenues de transmettre aux administrations fiscales des pays de l’OCDE via l’administration fiscale française des informations relatives aux résidents fiscaux des pays signataires de la norme d’échange mise au point par l’OCDE."
							/>
						</div>
					</div>
					
					<div className="user-survey-questions-container one-grid">
						<div className="full-input radio-inputs">
							<OsCheckbox
								formik={formik}
								name="full_responsability"
								label="Je déclare assumer l’entière responsabilité des réponses que je viens de fournir."
							/>
						</div>
					</div>
					<div className="user-survey-questions-container one-grid">
						<div className="full-input radio-inputs">
							<OsCheckbox
								formik={formik}
								name="change_of_situation"
								label="Je m’engage à informer Openstone dans les plus brefs délais en cas de changement de situation pouvant avoir un impact sur les informations fournies dans le présent document."
							/>
						</div>
					</div>
				</OsCard>
				<div className="user-survey-btn-container">
					<OSButton onClick={() => handleStep(false)} variant="no_border" title="Revenir à l'étape précédente" fit size="medium" icon={<ArrowBackIcon />}/>
					<button type="submit" className="user-survey-btn" disabled={submitting}>Terminer</button>
				</div>
			</form>
		</div>
	);
};

export default Statement;
