import React, {useState} from 'react';

import './style.scss';

const MobileNav = ({links}) => {
  const currentPath = window.location.pathname;
  const defaultProfile = useState(JSON.parse(sessionStorage.getItem('defaultProfile')))[0];

  const RenderLink = ({link}) => (
    <a href={link.link} className={`link ${currentPath === link.link && 'link__active'}`}>
      <span>{link.icon}</span>
      <span>{link.title}</span>
    </a>
  )
  return (
    <div className="mobile-nav-container">
      {links.map(l => (l.title === 'Parrainage' && ! defaultProfile) ? null : (<RenderLink link={l} key={`mobile_${l.title}`}/>))}
    </div>
  );
};

export default MobileNav;
