import React, {useState} from "react";
// @mui
import {
	Card,
	Table,
	TableRow,
	TableBody,
	TableCell,
	TableContainer, IconButton, MenuItem, Menu
} from '@mui/material';
//import Menu from '@mui/material/Menu';
//import MenuItem from '@mui/material/MenuItem';
//import IconButton from '@mui/material/IconButton';
//import MoreVertIcon from '@mui/icons-material/MoreVert';
//import VisibilityIcon from '@mui/icons-material/Visibility';

import Scrollbar from '../../scrollbar';
import { TableHeadCustom } from '../index.js';
import MoneyHelper from "../../../Helpers/MoneyHelper";

import {Link, useNavigate} from "react-router-dom";
import getBadge from "../../Badge/getBadge";
import OSButton from "../../Button";
import EditIcon from "@mui/icons-material/Edit";
import PersonIcon from '@mui/icons-material/Person';


import '../myTable.scss';
import MoreVertIcon from "@mui/icons-material/MoreVert";

// ----------------------------------------------------------------------
export default function ClientsTable({ title, subheader, tableData, tableLabels, ...other }) {
  return (
    <Card {...other}>
      <TableContainer sx={{ overflow: 'unset' }}>
        <Scrollbar>
          <Table sx={{ minWidth: 720 }}>
            <TableHeadCustom headLabel={tableLabels} />

            <TableBody>
              {tableData.map((row, index) => <ClientsTableRow key={`tableCell${index}-${row.record.created_at}`} row={row} workspace={other.workspace} /> )}
            </TableBody>
          </Table>
        </Scrollbar>
      </TableContainer>
    </Card>
  );
}

// ----------------------------------------------------------------------

function ClientsTableRow({ row, workspace }) {
	const [anchorEl, setAnchorEl] = useState(null);
	const open = Boolean(anchorEl);
	const navigate = useNavigate();
	const record = row.record;
	
	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};
	
	const handleClose = () => {
		setAnchorEl(null);
	};
	
	const seeAction = () => {
		if (row.listable_type === 'User') {
			sessionStorage.setItem('User', JSON.stringify(row.record.id));
		} else {
			sessionStorage.setItem('Company', JSON.stringify(row.record.id));
		}
		navigate(`${window.location.pathname}/${row.record.id}`, { state: { clientId: row.record.id, listableType: row.listable_type } });
		handleClose();
	};
	
	const editAction = () => {
		if (row.listable_type === 'User') {
			navigate(`${window.location.pathname}/${row.record.id}/personne-physique/edition`, { state: { clientId: row.record.id } });
			sessionStorage.setItem('User', JSON.stringify(row.record.id));
		} else {
			navigate(`${window.location.pathname}/${row.record.id}/personne-morale/edition`, { state: { clientId: row.record.id } });
			sessionStorage.setItem('Company', JSON.stringify(row.record.id));
		}
		handleClose();
	};
	
	return (
		<>
			<TableRow>
				<TableCell>
					<div>
						{record.name}
					</div>
				</TableCell>
				{workspace !== 'cgp' && (
					<TableCell>
						<Link className="table-link" to={`/cgp/cabinets/collaborateurs/${record.cgp_user.id}`}>
							{(record.cgp_user.full_name || 'N/A')}
						</Link>
					</TableCell>
				)}
				<TableCell>
					<div>
						{MoneyHelper.formatEuro(record.fund_shares.total)}
					</div>
				</TableCell>
				<TableCell>
					<div>{row.listable_type === 'Company' ? 'Personne morale' : 'Personne physique'}</div>
				</TableCell>
				<TableCell>
					{getBadge(record.kyc_status, 'kyc_status')}
				</TableCell>
				<TableCell>
					{getBadge(record.investment_profile, 'investorType')}
				</TableCell>
				<TableCell>
					<IconButton
						aria-label="more"
						aria-controls="long-menu"
						aria-haspopup="true"
						onClick={handleClick}>
						<MoreVertIcon />
					</IconButton>
					<Menu
						id="long-cgp-menu"
						anchorEl={anchorEl}
						keepMounted
						open={open}
						onClose={handleClose}>
						<MenuItem onClick={editAction}>
							<div className="os-flex gap-8" style={{alignItems: "center"}}>
								<EditIcon fontSize={"small"} /> Éditer
							</div>
						</MenuItem>
						<MenuItem onClick={seeAction}>
							<div className="os-flex gap-8" style={{alignItems: "center"}}>
								<PersonIcon /> Accéder
							</div>
						</MenuItem>
					</Menu>
				</TableCell>
			</TableRow>
		</>
	);
}
