import React from 'react';
import Images from "../../../assets/images";
import {ReactComponent as ChatIcon} from "../../../pages/Opportunities/View/icons/ic_chat.svg";
import {ReactComponent as PhoneIcon} from "../../../pages/Opportunities/View/icons/ic_call.svg";
import OSButton from "../../Button";
import {Avatar, AvatarGroup} from "@mui/material";

/**
 * A simple Button component.
 *
 * @param {Object} props - The component's props.
 * @param {string} text - The text to display in the component.
 * @param {string} title - the title do display in the component.
 */

const ProductContact = ({text, title}) => {

  return (
    <div className="product-view-contact-us">
      <div className="product-view-contact-us-left">
				<AvatarGroup max={3}>
					<Avatar alt="Charles" src={Images.charles} />
					<Avatar alt="Sébastien" src={Images.sebastien} />
					<Avatar alt="Alexandre" src="/static/images/avatar/3.jpg" />
					<Avatar alt="Patrick" src="/static/images/avatar/4.jpg" />
					<Avatar alt="Nadim" src="/static/images/avatar/5.jpg" />
				</AvatarGroup>
        <div className="product-view-contact-us-content">
            <p className="os-subtitle1">{title}</p>
            <p className="os-body1">{text}</p>
        </div>
      </div>
      <div className="os-flex product-view-contact-us-btns">
				<button
					className="write-us-btn"
					onClick={() => window.location.href = 'mailto:souscriptions@openstone.com'}>
					Nous écrire <ChatIcon />
				</button>
				
				<OSButton title="Être rappelé(e)" fit variant="primary-warning" size="small" link="https://meetings-eu1.hubspot.com/charles-antoine-lamoureux/openstone" icon={<PhoneIcon/>} target/>
      </div>
    </div>
  );
};

export default ProductContact;
