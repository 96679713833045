import React, {useEffect, useState} from 'react';
import {Helmet} from "react-helmet";

import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import InfoIcon from '@mui/icons-material/Info';
import FolderIcon from '@mui/icons-material/Folder';
import EditIcon from '@mui/icons-material/Edit';

import AuthenticationNetwork from "../../config/Network/AuthenticationNetwork.ts";

import SignupForm from "../../Forms/User/CompleteInfos";

import OsModal from "../../components/Modals";
import {ReactComponent as ChatIcon} from "../Opportunities/View/icons/ic_chat.svg";
import InstructionsContainer from "../../components/instructionsContainer";

import './style.scss';

const Profile = () => {
  const [user, setUser] = useState({});
  const [isEditing, setIsEditing] = useState(false);
  const defaultProfile = useState(JSON.parse(sessionStorage.getItem('defaultProfile')))[0];
  const [company, setCompany] = useState(JSON.parse(sessionStorage.getItem('company')));

  const toggle = type => {
    (type === 'completeInfos') && setIsEditing(!isEditing);
  }

  const getUser = async () => {
    const response = await AuthenticationNetwork.loadUserData();
    response && setUser(response)
  }

  useEffect(() => {
    getUser();
  }, [])

  useEffect(() => {
    user.phone_number === null && window.location.replace('/sign-up/verify');
  }, [user])

  return (
    <>
      <Helmet>
        <title>Openstone | Mon profil</title>
      </Helmet>
      <div className="account-container">
        <div className="profile-container-content">
          <div className="os-flex section-title light-border" style={{ marginBottom: 24 }}>
            <h4 className="os-h4">
              {defaultProfile ? "Mon profil" : "Ma société"}
            </h4>
            <div className="user-infos-top-container">
              {user.kyc_verification_status !== 'verified' && (
                <button className="user-infos-top-container__button" onClick={() => toggle('completeInfos')}>
                  <EditIcon style={{width: 16}}/>
                  Éditer
                </button>
              )}
            </div>
          </div>

          <div className="user-infos-names">
            {defaultProfile ? (
                <div className="user-infos-names">
                  <p><span className="field-title">Nom :</span> {user.first_name}</p>
                  <p><span className="field-title">Prénom :</span> {user.last_name}</p>
                  <p><span className="field-title">Email :</span> {user.email === '' || user.email === null ? user.unverified_email : user.email}</p>
                  <p><span className="field-title">Adresse :</span> {user.address_line_1}</p>
                  <div className="phone-field">
                    <p><span className="field-title">Téléphone :</span> {user.phone_number}</p>
                  </div>
                </div>
            ) : (
                <div className="user-infos-names">
                  {/* Section "Entreprise" (ou ce que vous souhaitez afficher) */}
                  <p><span className="field-title">Votre fonction :</span> {company?.user_function}</p>
                  <p><span className="field-title">Secteur d'activité :</span> {company?.activity_sector}</p>
                  <p><span className="field-title">Siren :</span> {company?.siren}</p>
                  <p><span className="field-title">Email :</span> {user?.email === '' || user.email === null ? user.unverified_email : user.email}</p>
                  <p><span className="field-title">Adresse :</span> {company.address}</p>
                  <div className="phone-field">
                    <p><span className="field-title">Téléphone :</span> {user.phone_number}</p>
                  </div>
                </div>
            )}
          </div>
          {user.kyc_verification_status === 'verified' && (
            <InstructionsContainer
              content={"Votre compte étant maintenant vérifié, vous ne pouvez plus mettre à jour vos informations. Pour toute demande de modification, vous pouvez nous écrire et notre équipe du service client s’occupera de vous !"}
              extraChildren={ <button id="open_os_intercom" className="user-infos-top-container__button" style={{marginTop: 16}}>
                <ChatIcon style={{width: 16}}/>
                Nous contacter
              </button>}
            />
          )}
        </div>
        <div className="profile-container">
          <div className="profile-container-content">
            <h4 className="os-h4">Informations générales</h4>
            <div className="profile-fields">
              <div className="legal-fields">
                <a className="profile-link" href="https://www.openstone.com/" target="_blank" rel="noreferrer"><HelpOutlineIcon />F.A.Q</a>
                <a className="profile-link" href="https://www.openstone.com/les-risques" target="_blank" rel="noreferrer">
                  <InfoIcon />
                  Rappel des risques
                </a>
              </div>
            </div>
            <h4 className="os-h4">Documents légaux</h4>
            <div className="profile-fields">
              <div className="legal-fields">
                <a className="profile-link" href="/documents" rel="noreferrer">
                  <FolderIcon/>
                  Documents
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <OsModal title="Édition de mes informations" isShowing={isEditing} hide={() => toggle('completeInfos')} >
        <SignupForm user={user} closeModal={toggle} />
      </OsModal>
    </>
  );
};

export default Profile;
