import * as yup from 'yup';

export const LocationInfosSchema = (page) => yup.object({
	address: yup.string().required('Requis'),
	zipCode: yup.string().required('Requis'),
	city: yup.string().required('Requis'),
	fiscalCountry: yup.string().required('Requis'),
	nifCode: page !== 'companySurvey'
		? yup.string().required('Requis')
		: yup.string().notRequired(),
});
