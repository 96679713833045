import React, {useEffect, useState} from 'react';
import {useLocation} from "react-router-dom";
import {toast} from 'react-toastify';

import InvestmentsGraph from "./graphs/InvestmentGraph";
import ProductInvestmentsGraph from "./graphs/ProductInvestmentsGraph";

import StatisticsNetwork from "../../../config/Network/StatisticsNetwork.ts";

import RenderInvestmentTable from "./Tables/InvestmentTable";
import TextField from "@material-ui/core/TextField";
import {MenuItem} from "@mui/material";

import './style.scss';
import OsCard from "../../../components/card/basic";

const Statistics = ({ cgpUser, workspace, page }) => {
	const { state } = useLocation();
	const [topUpData, setTopUpData] = useState(null);
	const [productsData, setProductsData] = useState(null);
	const [cgpFeesData, setCgpFeesData] = useState(null);
	const [productsCgpFeesData, setProductsCgpFeesData] = useState(null);
	const [loading, setLoading] = useState(false);
	const [selectedCgpUserId, setSelectedCgpUserId] = useState(state?.cgpUserId || 'all');
	
	useEffect(() => {
		setLoading(true);
		const fetchData = async () => {
			try {
				const filters = (workspace === 'cgp' || selectedCgpUserId !== 'all')
					? { cgpUserId: selectedCgpUserId === 'all' ? cgpUser?.id : selectedCgpUserId }
					: { cgpCompanyId: cgpUser?.cgp_company?.id };
				
				const response = await StatisticsNetwork.getInvestments(filters);
				
				if (response) {
					setTopUpData(response.top_ups);
					setProductsData(response.product_investments);
					setCgpFeesData(response.cgp_fees);
					setProductsCgpFeesData(response.product_cgp_fees);
				} else {
					setTopUpData([]);
					setProductsData([]);
					setProductsCgpFeesData([]);
				}
			} catch (error) {
				toast.error("Une erreur est survenue lors du chargement des données, veuillez nous contacter si le problème persiste.");
			}
			setLoading(false);
		};
		
		fetchData();
	}, [cgpUser, workspace, selectedCgpUserId]);  // Include selectedCgpUserId in dependencies to refetch data when it changes
	
	// Handler for cgp_user_id selection change
	const handleCgpUserChange = (event) => {
		setSelectedCgpUserId(event.target.value);
	};
	
	return (
		<div className={`${page !== 'cgpProfile' && 'graph-page-container'}`}>
			<h4 className="os-h4">Statistiques de collecte</h4>
			
			{/* cgp_user_id Filter */}
			{workspace !== 'cgp' && (
				<div className="filter-container" style={{marginBottom: 24, marginTop: 24}}>
					<TextField
						select
						label="Conseiller"
						value={selectedCgpUserId}
						onChange={handleCgpUserChange}
						variant="outlined"
						className="stats-filter-input">
						<MenuItem value="all">Tous les conseillers</MenuItem>
						{cgpUser?.cgp_company?.cgp_users?.map((user) => (
							<MenuItem key={user.id} value={user.id}>
								{user.full_name}
							</MenuItem>
						))}
					</TextField>
				</div>
			)}
			
			<div className="graphs_container">
				<InvestmentsGraph loading={loading} graphData={topUpData} title="Montant investi total en €"/>
				<ProductInvestmentsGraph loading={loading} productData={productsData} title="Montant investi par produit" />
			</div>
			<OsCard title="Tableau des investissements" spaced="up">
				<p className="os-body1">Retrouvez l'intégralité des souscriptions</p>
				<RenderInvestmentTable loading={loading} data={topUpData} />
			</OsCard>
			
			<h4 className="os-h4">Statistiques de vos frais</h4>
			<div className="graphs_container">
				<InvestmentsGraph loading={loading} graphData={cgpFeesData} title="Droits d'entrée"/>
				<ProductInvestmentsGraph loading={loading} productData={productsCgpFeesData} title="Droits d'entrée collectés par produit" />
			</div>
		</div>
	);
};

export default Statistics;
