import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { IntercomProvider } from "react-use-intercom";
// import reportWebVitals from './reportWebVitals';

import OSRoutes from "./config/routes/routes";
import PreventBackButton from "./components/PreventBackButton";

import 'react-toastify/dist/ReactToastify.css';
import './styles/application.scss';

const INTERCOM_APP_ID = process.env.intercomAppID;

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
	<React.StrictMode>
		<IntercomProvider appId={INTERCOM_APP_ID}>
			<BrowserRouter>
				<OSRoutes />
				<ToastContainer />
			</BrowserRouter>
		</IntercomProvider>
	</React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
