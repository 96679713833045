import React, { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import ScrollToTop from "../../utils/scrollToTop";
import authenticationStore from "../../stores/AuthenticationStore.ts";
import MiniDrawer from "../../components/Navbar/Drawer";
import IntegratedSignature from "../../components/IntegratedSignature";
import MobileNav from "../../components/Navbar/Mobile";
import { generalCgpLinks, soonCgpLinks, mobileCgpLinks, cabinetLinks, supportLinks } from "./Links/cgpLinks";

import './style.scss';

const CgpRoutes = ({ children }) => {
	const navigate = useNavigate();
	const authToken = sessionStorage.getItem('CgpUserAuthToken');
	const [user, setUser] = useState(null);
	const [loading, setLoading] = useState(true);
	const [availableProducts, setAvailableProducts] = useState([]); // New state for available products
	const currentPath = window.location.href;
	const [signatureModal, setSignatureModal] = useState(false);
	const [workspace, setWorkspace] = useState(sessionStorage.getItem('activeWorkspace') || 'cgp');
	
	const handleWorkspaceSwitch = (newWorkspace) => {
		setWorkspace(newWorkspace);
		sessionStorage.setItem('activeWorkspace', newWorkspace);
		navigate('/cgp/dashboard');
		toast.success(`Vous êtes désormais sur l'espace ${newWorkspace === 'cgp' ? 'conseiller' : 'cabinet'}`);
	};
	
	useEffect(() => {
		const fetchUser = async () => {
			try {
				const response = await authenticationStore.loadCgpUserData();
				setUser(response);
				setAvailableProducts(Array.isArray(response.company_available_products) ? response.company_available_products : []);
				setWorkspace(response.role === 'owner' ? 'cabinet' : 'cgp');
				setLoading(false);
			} catch (error) {
				setLoading(false);
				toast.error('Une erreur est survenue');
			}
		};
		fetchUser();
	}, [authToken]);

	
	useEffect(() => {
		if (!authToken) {
			window.location.replace('/sign-in/cgp');
		} else if (!loading && user) {
			if (user.has_uploaded && user.kyc_verification_status !== 'verified') {
				window.location.replace('/cgp/verification');
			} else if (!user.has_uploaded && user.kyc_verification_status !== 'verified') {
				window.location.replace('/sign-up/cgp/documents');
			}
		}
	}, [authToken, user, loading]);
	
	useEffect(() => {
		if (!currentPath.includes('/finalise')) {
			['InvestmentAmount', 'CgpEntryFees', 'SgpEntryFees', 'CgpManagementFees', 'FundsCall', 'InvestmentType', 'product', 'productUrl', 'fundShare'].forEach(item => {
				sessionStorage.removeItem(item);
			});
		}
	}, [currentPath]);
	
	useEffect(() => {
		if (currentPath.includes('/cabinets') && workspace === 'cgp') {
			handleWorkspaceSwitch('companyWorkspace');
		}
	}, [currentPath, workspace]);
	
	useEffect(() => {
		if (user?.kyc_verification_status === 'verified' && user?.master_ct.status !== 'signed') {
			process.env.NODE_ENV !== 'development' && setSignatureModal(true);
		}
	}, [user]);
	
	const updateShow = (bool) => {
		setSignatureModal(bool)
	}
	
	// Dynamic links including available products
	const dynamicLinks = [
		{ title: 'Toutes les offres', link: '/opportunites', icon: null },
		...(Array.isArray(availableProducts)
			? availableProducts
				.sort((a, b) => {
					// Mettre les produits commençant par "OS" en premier
					const aStartsWithOS = a.abreviation_name.startsWith('OS');
					const bStartsWithOS = b.abreviation_name.startsWith('OS');
					
					if (aStartsWithOS && !bStartsWithOS) return -1;
					if (!aStartsWithOS && bStartsWithOS) return 1;
					return a.abreviation_name.localeCompare(b.abreviation_name);
				})
				.map((product) => ({
					title: product.abreviation_name,
					link: `/opportunites/${product.category_slug}/${product.slug}?userType=cgp`,
					icon: null,
				}))
			: [])
	];
	
	return (
		<>
			<ScrollToTop />
			<div className="layout-container">
				<div className="drawer">
					<MiniDrawer
						links={dynamicLinks}
						generalLinks={workspace === 'cgp' ? generalCgpLinks : cabinetLinks}
						soonLinks={soonCgpLinks}
						suportLinks={supportLinks}
						cgp
						user={user}
						onWorkspaceSwitch={handleWorkspaceSwitch}
					/>
				</div>
				<div className="page-container">
					{!loading && user && React.cloneElement(children, { cgpUser: user, workspace })}
					<div className="mobile-nav">
						<MobileNav links={mobileCgpLinks} />
					</div>
				</div>
			</div>
			{signatureModal && (
				<IntegratedSignature
					show={signatureModal}
					updateShow={updateShow}
					signatureLink={user?.master_ct.file}
					body="Ici vous pourrez consulter et signer le contrat de partenariat Openstone, si celui-ci n'apparait pas veuillez nous contacter: partenaires@openstone.com"
					title="Signature du contrat de partenariat - Openstone"
				/>
			)}
		</>
	);
};

export default CgpRoutes;
