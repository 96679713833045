import React, {useEffect, useState} from 'react';
import Phone from "../../../components/Phone";
import {Helmet} from "react-helmet";
import {ReactComponent as LogoWhite} from "../../../components/Navbar/logoWhite.svg";
import authenticationStore from "../../../stores/AuthenticationStore.ts";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import OSButton from "../../../components/Button";

const Verify = () => {
  const authToken = sessionStorage.getItem('UserAuthToken');
  const [user, setUser] = useState(authenticationStore.user);
  const [loading, setLoading] = useState(true);

  async function fetchUser() {
    await authenticationStore.loadUserData().then(response => {
      setUser(response);
      setLoading(false)
    });
  }

  useEffect(() => {
    authToken === null && window.location.replace('/')
    fetchUser();
  }, [authToken])

  useEffect(() => {
    if (!loading && Object.keys(user).length > 0) {
      if (user.phone_valid && user.phone_number !== null) {
        window.location.replace('/overview');
      }
    }
  }, [user, loading])

  function goBackToAuth() {
    localStorage.clear();
    window.location.replace('/');
  }

  return (
    <>
      <Helmet>
        <title>Vérification Téléphone</title>
      </Helmet>
      <div className="auth-container">
        <LogoWhite className="auth-os-logo" onClick={() => window.location.replace('/')} />
        <div className="auth-phone-container">
          <OSButton onClick={() => goBackToAuth()} variant="primary-full" size="large" title="Revenir à la page d'accueil" icon={<ArrowBackIcon /> } fit/>
          <h4 className="auth-title os-h4">Vérification de votre numéro de téléphone</h4>
          <p className="os-body2">Afin de sécuriser l'accès à votre compte Openstone, veuillez renseigner votre numéro de téléphone</p>
          <Phone user={user} page="signUp"/>
        </div>
      </div>
    </>
  );
}

export default Verify;
