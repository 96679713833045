import React, {useEffect, useState} from 'react';
import {useLocation, useParams} from "react-router";
import {useNavigate} from "react-router-dom";

import InvestmentNetwork from "../../../config/Network/InvestmentNetwork.ts";
import ProductNework from "../../../config/Network/ProductNework.ts";
import CgpNetwork from "../../../config/Network/CgpNetwork.ts";

import MoneyHelper from "../../../Helpers/MoneyHelper";
import OsModal from "../../Modals";
import Video from "../../../pages/livretp/Video";
import ColoredCard from "../../card/ColoredCard";
import LivretPViewLoader from "../../Loaders/LivretP";
import LivrePViewLoaderMobile from "../../Loaders/LivretP/Mobile";

import OpportunityHelper from "../../../Helpers/OpportunityHelper";
import OSButton from "../../Button";

import AddIcon from '@mui/icons-material/Add';

import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import LivretP from "../../../pages/livretp";
import renderTransactionsTable from "../../User/renderTransactionsTable";
import OsCard from "../../card/basic";
import {ChevronRight} from "@mui/icons-material";
import EmptyContent from "../../empty-content/EmptyContent";
import {ReactComponent as NoDocs} from "../../../assets/Illustrations/no-documents.svg";
import DescriptionIcon from "@mui/icons-material/Description";

import './style.scss';

const ProductInvestment = ({handlePageTab, cgp, page, clientViewProduct, clientType}) => {
  let { clientId} = useParams();
  const {state} = useLocation();
  const storageDefaultProfile = useState(JSON.parse(sessionStorage.getItem('defaultProfile')))[0];
  const defaultProfile = cgp || page === 'clientView' ? (page === 'clientView' ? clientType !== 'Company' : state?.clientType !== 'Company') : storageDefaultProfile
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState({});
  const [relatedDocs, setRelatedDocs] = useState([]);
  const [loadingRelatedDocs, setLoadingRelatedDocs] = useState(false);
  const [product, setProduct] = useState(null);
  const [loadingProduct, setLoadingProduct] = useState(true);
  const [isShowingTransactions, setIsShowTransactions] = useState(false);
  const [isShowing, setIsShowing] = useState(false);
  const navigate = useNavigate();
  const toggle = type => {
    if (type === 'transactions') {
      setIsShowTransactions(!isShowingTransactions);
    } else if (type === 'video') {
      setIsShowing(!isShowing);
    }
  };
  const fetchOpportunity = async () => {
    const response = page === 'clientView' ? await ProductNework.getProduct(clientViewProduct.id, clientId, clientType) : await ProductNework.getProduct(state?.id, clientId, state?.clientType);
    if (response) {
      setProduct(response);
      setLoadingProduct(false);
    }
  }
  const fetchInvestments = async () => {
    const response = await InvestmentNetwork.list(
      clientId,
      defaultProfile ? 'User' : 'Company',
      product.id,
      product.name,
      'all'
    );
    if (response) {
      setData(response);
      setLoading(false);
    }
  }
  const getRelatedDocuments = async () => {
    setLoadingRelatedDocs(true);
    const response = await CgpNetwork.getProductRelatedDocuments(product.id, clientId, state?.clientType ? state.clientType : clientType);
    if (response) {
      setRelatedDocs(response);
      setLoadingRelatedDocs(false);
    }
  };

  useEffect(() => {
    if (state) {
      if (state.id !== 'livretp_transaction_recap'){
        fetchOpportunity();
      }
    } else {
      window.location.replace('/cgp/clients');
    }
  }, []);

  useEffect(() => {
    if (product !== 'null' && !loadingProduct) {
      fetchInvestments();
      getRelatedDocuments();
    }
  }, [product])


  const clientDocuments = () => {
    if (loadingRelatedDocs && relatedDocs.length === 0) {
      return 'Chargement...'
    } else if (!loadingRelatedDocs && relatedDocs.length > 0) {
      return (
        <div className="os-flex client-documents">
          {relatedDocs.slice(0, 6).map((doc) => (
            <a className="product-document" href={doc?.url || doc?.file?.url} target="_blank" rel="noreferrer" key={doc?.title}>
	            <div className="pdf-logo-container">
		            <DescriptionIcon />
	            </div>
	            <div className="os-flex product-document-name-container">
		            <div>
	                <p className="product-document-name os-body2">{doc?.title}</p>
	                <span className={doc?.status === 'signed' ? "success" : "warning"}>{doc?.status === "signed" ? `Signé le ${doc?.signed_at}` : "Signature en attente"}</span>
		            </div>
                <ChevronRight/>
              </div>
            </a>
          ) )}
        </div>
      )
    } else if (!loadingRelatedDocs && relatedDocs.length === 0) {
      return <EmptyContent illustration={<NoDocs/>} text="Aucun document lié à ce produit" />
    }
  }

  function renderPage() {
    const renderDividende = (product) => {
      if (product.category_name !== 'Club Deals') {
        return (
          <div>
            <div className="investment-card">
              <div className="investment-card__infos os-flex" style={{justifyContent: "space-between", alignItems: "center"}}>
                <p className="os-subtitle1">Dividendes versés</p>
              </div>
              <h6 className="os-h6">{MoneyHelper.formatEuro(0)}</h6>
            </div>
          </div>
        )
      }
    }
    if (loading || data === {})  {
      return (
        <div className="loader-display">
          <div className="desktop-display">
            <LivretPViewLoader />
          </div>
          <div className="mobile-display">
            <LivrePViewLoaderMobile />
          </div>
        </div>)
    } else if (!loading && data !== {}) {
      return (
        <>
          <div className={page !== 'clientView' && "investment-container"}>
            {page !== 'clientView' && (
              <>
                {cgp ? (
                  <div className="os-flex my-investment-btns">
										<OSButton title="Revenir au profil client" fit size="large" variant="primary-full" onClick={() =>  navigate(`/cgp/clients/${clientId}`, {state: {clientId: clientId, listableType: state?.clientType}})} icon={<ArrowBackIcon />}/>
										{product?.is_os && (
											<div className="os-flex gap-16">
												<OSButton title="Voir la page produit" size="large" variant="primary-full" fit onClick={() => navigate(`/opportunites/${product.category_slug}/${product.slug}`)}/>
												<OSButton title="Souscrire" size="large" variant="primary" icon={<AddIcon />} fit onClick={() => navigate(`${window.location.pathname}/finalise`, {state: {clientId: clientId, clientType: state?.clientType}})}/>
											</div>
										)}
									</div>
                ) : (
                  <div className="os-flex my-investment-btns">
                    <OSButton title="Revenir" fit size="large" variant="no_border-info" onClick={() =>  navigate(-1)} icon={<ArrowBackIcon />}/>
										{product?.is_os && (
											<div className="os-flex gap-16">
												<OSButton title="Voir la page produit" size="large" variant="primary-full" fit onClick={() => navigate(`/opportunites/${product.category_slug}/${product.slug}`)}/>
												<OSButton title="Souscrire" size="large" variant="primary" icon={<AddIcon />} fit onClick={() => navigate(`/opportunites/${product.category_slug}/${product.slug}/finalise`)}/>
											</div>
										)}
                  </div>
                )}
                <div className="investment-top-container">
                  <div className="left-container" style={cgp && {width: '100%'}}>
                    <ColoredCard productInfos={{logo: OpportunityHelper.renderCategory(product).logo}} product={product}>
                      <div className="os-flex base-interest">
                        <p className="base-interest-title">Solde actuel</p>
                        <span className="base-interest-percentage">{MoneyHelper.formatEuro(data.active_balance)}</span>
                      </div>
                      <div className="os-flex base-interest">
                        <p className="base-interest-title">Taux de rentabilité interne</p>
                        <span className="base-interest-percentage">{product.actual_rate}</span>
                      </div>
                      <div className="os-flex base-interest">
                        <p className="base-interest-title">Durée d'investissement</p>
                        <span className="base-interest-percentage">{product.horizon}</span>
                      </div>
                    </ColoredCard>
                  </div>
                </div>
                  {!cgp && (
                    <>
                      <div className="investment-cards-container">
                        <div className="investment-card">
                          <p className="os-subtitle1">Investissement total</p>
                          <h6 className="os-h6">{MoneyHelper.formatEuro(data.total_investment)}</h6>
                        </div>
                        {renderDividende(product)}
                        <div className="investment-card">
                          <p className="os-subtitle1">Remboursement reçus</p>
                          <h6 className="os-h6">{MoneyHelper.formatEuro(data.withdrawals)}</h6>
                        </div>
                      </div>
                    </>
                    )
                  }
              </>
            )}
            <div style={{marginBottom: 32}}>
              <OsCard
                title={`Transactions liées au produit - ${product.name}`}
                onClick={() => navigate(cgp ? `${window.location.pathname}/transactions` : `/opportunites/${product.category_slug}/${product.slug}/my-investment/transactions`, {state: {id: product.id, name: product.name, cgp: cgp, clientId: clientId, clientType: clientType}})}
                linkTitle="Voir plus de transactions"
                conditionForLink={data?.investments.length > 6}>
                  <div className="os-flex investment-card-title">
                    {page === 'clientView' && (
                      <OSButton title="Voir plus de détail sur l'investissement" size="" variant="no_border-info" fit={true}
                                onClick={() => {
                                  product.name === 'Livret P' ? navigate(`${window.location.pathname}/investissement/${product.category_slug}/${product.category_slug}`, {state: {id: product.id, clientId: clientId, clientType: clientType}}) :
                                  navigate(`${window.location.pathname}/investissement/${product.category_slug}/${product.slug}`, {state: {id: product.id, clientId: clientId, clientType: clientType}})}}
                      />
                    )}
                  </div>
                  {renderTransactionsTable(loading, data?.investments, cgp && 'cgp', product)}
              </OsCard>
            </div>
            {cgp && <OsCard title="Documents liés à ce produit" children={clientDocuments()}
                            badges={[
                              {number: relatedDocs.filter(doc => doc.status === 'pending').length, text: "En attente de signature", status: "pending"},
                              {number: relatedDocs.filter(doc => doc.status === 'signed').length, text: "Signés", status: "signed"},
                              {number: relatedDocs.filter(doc => doc.status === 'expired').length, text: "Expirés", status: "expired"}
                            ]}
                            conditionForBadges={relatedDocs.length > 0}
            />
            }
          </div>
          {/* ---------- MODALS -------------*/}
          <OsModal isWhite={false} title="" isShowing={isShowing} hide={() => toggle('video')} >
            <Video link={product.video_link} />
          </OsModal>
        </>
      )
    }
  }
  return ((page !== 'clientView' && state?.id === 'livretp_transaction_recap') ? <LivretP cgp={cgp} clientId={clientId} clientType={state?.clientType}/> : renderPage());
};

export default ProductInvestment;
