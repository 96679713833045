import React, { useState} from 'react';
import {useFormik} from 'formik';
import * as yup from 'yup';
import TextField from '@material-ui/core/TextField';
import {InputAdornment} from "@mui/material";
import {toast} from "react-toastify";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";

import AuthenticationNetwork from "../../../../config/Network/AuthenticationNetwork.ts";
import CompanyNetwork from "../../../../config/Network/CompanyNetwork.ts";
import CgpNetwork from "../../../../config/Network/CgpNetwork.ts";

import OSButton from "../../../../components/Button";
import OsCard from "../../../../components/card/basic";

const Revenus = ({user, handleStep, page, isCgp, company}) => {
  const [submitting, setSubmitting] = useState(false);
  const financials = page === 'companyForm' ? company?.financial_situation : user?.financial_situation;

  const validationSchema = yup.object({
		houseRevenuesAmount: yup.string().required('Requis'),
		realEstateWealthAmount: yup.string().required('Requis'),
		nonRealEstateWealthAmount: yup.string().required('Requis'),
		annualSavingsCapacityAmount: yup.string().required('Requis'),
  })
	
  const formik = useFormik({
    initialValues: {
			houseRevenuesAmount: financials.houseRevenuesAmount || '',
			realEstateWealthAmount: financials.realEstateWealthAmount || '',
			nonRealEstateWealthAmount: financials.nonRealEstateWealthAmount || '',
			annualSavingsCapacityAmount: financials.annualSavingsCapacityAmount || '',
		},
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      setSubmitting(true);
      const datasToSubmit = {
				financial_situation : {
					houseRevenuesAmount: values.houseRevenuesAmount,
					realEstateWealthAmount: values.realEstateWealthAmount,
					nonRealEstateWealthAmount: values.nonRealEstateWealthAmount,
					annualSavingsCapacityAmount: values.annualSavingsCapacityAmount
				}
      };
      async function handleRequest() {
        if (isCgp) {
          page === 'companyForm' ? await CgpNetwork.updateRecord(datasToSubmit, company.id, 'Company') : await CgpNetwork.updateRecord(datasToSubmit, user.id, 'User');
        } else {
          page === 'companyForm' ? await CompanyNetwork.updateCompany(company.id, datasToSubmit) : await AuthenticationNetwork.editUser(datasToSubmit);
        }
      }
      const response = handleRequest();
      if (response) {
        setSubmitting(false);
        toast.success('Votre situation financière a bien été enregistrée', {
          toastId: 'completeInfosSuccessToast'
        });
        window.scrollTo(0, 0);
        handleStep(true);
      } else {
        toast.error("Une erreur est survenue lors de l'enregistrement de vos informations",{
          toastId: 'completeInfosErrorToast'
        });
      }
    },
  });
		
  return (
    <div>
      <form className="os-user-survey-form" onSubmit={formik.handleSubmit}>
				<div style={{marginBottom: 32, marginTop: 24}}>
					<h5 className="os-h5">Situation financière</h5>
					<span className="os-caption error">Tous les champs sont obligatoires.</span>
				</div>
				<OsCard title="Revenus">
					<div className="user-survey-questions-container two-grid">
						<div>
							<p className="user-survey-question ny">Quels sont les revenus brut annuels de votre foyer ?</p>
							<TextField
								fullWidth
								id="houseRevenuesAmount"
								name="houseRevenuesAmount"
								label="Montant :"
								value={formik.values.houseRevenuesAmount}
								onChange={formik.handleChange}
								error={formik.touched.houseRevenuesAmount && Boolean(formik.errors.houseRevenuesAmount)}
								helperText={formik.touched.houseRevenuesAmount && formik.errors.houseRevenuesAmount}
								variant={"outlined"}
								type="number"
								InputProps={{
									endAdornment: <InputAdornment position="end">€</InputAdornment>,
									onWheel: (e) => e.target.blur()
								}}
							/>
						</div>
						<div>
							<p className="user-survey-question">Quelle est la capacité d’épargne annuelle de votre foyer ?</p>
							<TextField
								fullWidth
								id="annualSavingsCapacityAmount"
								name="annualSavingsCapacityAmount"
								label="Montant :"
								value={formik.values.annualSavingsCapacityAmount}
								onChange={formik.handleChange}
								error={formik.touched.annualSavingsCapacityAmount && Boolean(formik.errors.annualSavingsCapacityAmount)}
								helperText={formik.touched.annualSavingsCapacityAmount && formik.errors.annualSavingsCapacityAmount}
								variant={"outlined"}
								type="number"
								InputProps={{
									endAdornment: <InputAdornment position="end">€</InputAdornment>,
									onWheel: (e) => e.target.blur()
								}}
							/>
						</div>
						
						<div>
							<p className="user-survey-question">Quel est le patrimoine immobilier de votre foyer (net d’endettement) ?</p>
							<TextField
								fullWidth
								id="realEstateWealthAmount"
								name="realEstateWealthAmount"
								label="Montant :"
								value={formik.values.realEstateWealthAmount}
								onChange={formik.handleChange}
								error={formik.touched.realEstateWealthAmount && Boolean(formik.errors.realEstateWealthAmount)}
								helperText={formik.touched.realEstateWealthAmount && formik.errors.realEstateWealthAmount}
								variant={"outlined"}
								type="number"
								InputProps={{
									endAdornment: <InputAdornment position="end">€</InputAdornment>,
									onWheel: (e) => e.target.blur()
								}}
							/>
						</div>
						<div className="os-flex__column" style={{justifyContent: "flex-end"}}>
							<p className="user-survey-question">Quelle est la tranche de patrimoine hors immobilier de votre foyer (net d’endettement) ?</p>
							<TextField
								fullWidth
								id="nonRealEstateWealthAmount"
								name="nonRealEstateWealthAmount"
								label="Montant :"
								value={formik.values.nonRealEstateWealthAmount}
								onChange={formik.handleChange}
								error={formik.touched.nonRealEstateWealthAmount && Boolean(formik.errors.nonRealEstateWealthAmount)}
								helperText={formik.touched.nonRealEstateWealthAmount && formik.errors.nonRealEstateWealthAmount}
								variant={"outlined"}
								type="number"
								InputProps={{
									endAdornment: <InputAdornment position="end">€</InputAdornment>,
									onWheel: (e) => e.target.blur()
								}}
							/>
						</div>
					</div>
				</OsCard>
	      
	      <div className="user-survey-btn-container" style={{marginTop: 32}}>
		      <OSButton onClick={() => handleStep(false)} variant="no_border" title="Revenir à l'étape précédente" fit size="medium" icon={<ArrowBackIcon />}/>
		      <button type="submit" className="user-survey-btn" disabled={submitting}>Continuer</button>
	      </div>
	      <div className="user-survey-btn-container">
		      {Object.keys(formik.errors).length !== 0 && <span className="error">Vous devez répondre à toutes les questions pour pouvoir continuer</span>}
	      </div>
      </form>
    </div>
  );
};

export default Revenus;
