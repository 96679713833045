import React, { useEffect } from 'react';
import { useFormik, FormikProvider } from 'formik';
import { TextField, MenuItem } from '@mui/material';

import { businessTypes } from "../../../../KycSurvey/Steps/choices";
import OsDate from "../../../../../components/Form/Fields/OsDate";

const CompanyOwnerPmForm = ({ owner, onFormChange, errors = {}}) => {
	// Ensure the initial values are populated dynamically with up-to-date owner data
	const data = owner.formData || {};
	const companyInitialValues = {
		legalName: data?.legalName || "",
		businessType: data?.businessType || "",
		siren: data?.siren || "",
		createdAt: data?.createdAt || "",
		quality: data?.quality || ""
	};
	
	
	// Formik Initialization
	const formik = useFormik({
		initialValues: companyInitialValues,
		enableReinitialize: true, // Permet la réinitialisation auto
		onSubmit: (values) => {
			console.log('Formulaire soumis avec les valeurs :', values);
		},
	});

	// Sync changes with parent component
	useEffect(() => {
		if (onFormChange) {
			onFormChange(formik.values);
		}
	}, [formik.values, onFormChange]);
	
	useEffect(() => {
		if (Object.keys(errors).length > 0) {
			formik.setErrors(errors);
		}
	}, [errors]);
	
	return (
		<FormikProvider value={formik}>
			<form className="os-user-survey-form" onSubmit={formik.handleSubmit}>
				<h6 className="os-h6">Informations principales</h6>
				<div className="user-survey-questions-container">
					<TextField
						fullWidth
						id="legalName"
						name="legalName"
						label="Raison sociale"
						value={formik.values.legalName}
						onChange={formik.handleChange}
						error={errors.legalName}
						helperText={errors.legalName}
						variant={"outlined"}
					/>
					<TextField
						fullWidth
						id="businessType"
						name="businessType"
						select
						label="Forme juridique"
						value={formik.values.businessType}
						onChange={formik.handleChange}
						error={errors.businessType}
						helperText={errors.businessType}
						variant={"outlined"}
					>
						{businessTypes.map(b => (
							<MenuItem key={`countryBirth-${b.label}`} value={b.value}>{b.label}</MenuItem>
						))}
					</TextField>
					<TextField
						fullWidth
						id="siren"
						name="siren"
						label="SIREN (ou numéro d'immatriculation)"
						value={formik.values.siren}
						onChange={formik.handleChange}
						error={errors.siren}
						helperText={errors.siren}
						variant={"outlined"}
					/>

					<OsDate formik={formik} name="createdAt" label="Date de création" />
					
					<TextField
						fullWidth
						id="quality"
						name="quality"
						label="Qualité au sein de cette société"
						value={formik.values.quality}
						onChange={formik.handleChange}
						error={errors.quality}
						helperText={errors.quality}
						variant="outlined"
					/>
				</div>
			</form>
		</FormikProvider>
	);
};

export default CompanyOwnerPmForm;
