import React, {useEffect, useState} from 'react';
import { useFormik } from 'formik';
import {toast} from "react-toastify";
import * as yup from 'yup';

import {Slider} from "@mui/material";

import { environmentPercentage, investmentFrequencies, investmentMethods} from "../choices";
import AuthenticationNetwork from "../../../../config/Network/AuthenticationNetwork.ts";
import CompanyNetwork from "../../../../config/Network/CompanyNetwork.ts";
import CgpNetwork from "../../../../config/Network/CgpNetwork.ts";
import OsSelect from "../../../../components/Form/Fields/OsSelect";
import OSButton from "../../../../components/Button";
import OsRadio from "../../../../components/Form/Fields/OsRadio";
import OsCard from "../../../../components/card/basic";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

const Knowledge = ({user, handleStep, page, isCgp, company}) => {
	const knowledge = page === 'companyForm' ? company?.knowledge : user?.knowledge;
	const [submitting, setSubmitting] = useState(false);
	const [durabilityScore, setDurabilityScore] = useState(knowledge?.durabilityScore || 1);

	const BOinitialValues = {
		stockInvestments: knowledge?.investments?.stockInvestments || '',
		privateEquityInvestments: knowledge?.investments?.privateEquityInvestments || '',
		capitalInvestment: knowledge?.investments?.capitalInvestment || '',
		reit: knowledge?.investments?.reit || '', // RealEstateInvestmentThrust
		opcvm: knowledge?.investments?.opcvm || '',
		simpleObligationInvestment: knowledge?.investments?.simpleObligationInvestment || '',
		complexObligationInvestment: knowledge?.investments?.complexObligationInvestment || '',
		actedAs: knowledge?.actedAs || '',
		twoYearInvestments: knowledge?.twoYearInvestments || '',
		fiveYearInvestments: knowledge?.fiveYearInvestments || '',
		liquidityRefund: knowledge?.fpci?.liquidityRefund || '',
		stockPerformance: knowledge?.fpci?.stockPerformance || '', // Society of Management Evaluation Risk
		IRA: knowledge?.fpci?.IRA || '', // Investment Risk Awareness
		environmentPercentage: knowledge?.environmentPercentage || '',
		durabilityPercentage: knowledge?.durabilityPercentage || '',
		durabilityScore: knowledge?.durabilityScore || ''
	}
	
	const validationSchema = yup.object({
		stockInvestments: yup.string().required('Requis'),
		privateEquityInvestments: yup.string().required('Requis'),
		capitalInvestment: yup.string().required('Requis'),
		reit: yup.string().required('Requis'),
		opcvm: yup.string().required('Requis'),
		simpleObligationInvestment: yup.string().required('Requis'),
		complexObligationInvestment: yup.string().required('Requis'),
		actedAs: yup.string().required('Requis'),
		twoYearInvestments: yup.string().required('Requis'),
		fiveYearInvestments: yup.string().required('Requis'),
		liquidityRefund: yup.string().required('Requis'),
		stockPerformance: yup.string().required('Requis'),
		IRA: yup.string().required('Requis'),
		environmentPercentage: yup.string().required('Requis'),
		durabilityPercentage: yup.string().required('Requis'),
	});
	
	const formik = useFormik({
		initialValues: BOinitialValues,
		validationSchema: validationSchema,
		onSubmit: async (values) => {
			setSubmitting(true);
			const datasToSubmit = {
				knowledge: {
					investments: {
						stockInvestments: values.stockInvestments,
						privateEquityInvestments: values.privateEquityInvestments,
						capitalInvestment: values.capitalInvestment,
						reit: values.reit, // RealEstateInvestmentThrust
						opcvm: values.opcvm,
						simpleObligationInvestment: values.simpleObligationInvestment,
						complexObligationInvestment: values.complexObligationInvestment,
					},
					actedAs: values.actedAs,
					twoYearInvestments: values.twoYearInvestments,
					fiveYearInvestments: values.fiveYearInvestments,
					fpci: {
						liquidityRefund: values.liquidityRefund,
						stockPerformance: values.stockPerformance,
						IRA: values.IRA,
					},
					environmentPercentage: values.environmentPercentage,
					durabilityPercentage: values.durabilityPercentage,
					durabilityScore: durabilityScore
				}
			};
			async function handleRequest() {
				if (isCgp) {
					page === 'companyForm' ? await CgpNetwork.updateRecord(datasToSubmit, company.id, 'Company') : await CgpNetwork.updateRecord(datasToSubmit, user.id, 'User');
				} else {
					page === 'companyForm' ? await CompanyNetwork.updateCompany(company.id,(datasToSubmit)) : await AuthenticationNetwork.editUser(datasToSubmit);
				}
			}
			const response = handleRequest();
			if (response) {
				setSubmitting(false);
				toast.success('Vos connaissances et objectifs ont bien été enregistrés', {
					toastId: 'completeInfosSuccessToast'
				});
				window.scrollTo(0, 0);
				handleStep(true);
			} else {
				toast.error("Une erreur est survenue lors de l'enregistrement de vos informations",{
					toastId: 'completeInfosErrorToast'
				});
			}
		},
	});
	
	useEffect(() => {
		knowledge &&	formik.setValues(BOinitialValues);
	}, [knowledge]);
	
	return (
		<>
			<form className="os-user-survey-form" onSubmit={formik.handleSubmit}>
				<div style={{marginBottom: 32, marginTop: 24}}>
					<h5 className="os-h5">Connaissances et objectifs</h5>
					<span className="os-caption error">Tous les champs sont obligatoires.</span>
				</div>
				<OsCard title={page === 'companyForm'
					? 'Sur les 24 derniers mois, la personne morale a-t-elle déjà effectué les opérations suivantes :'
					: 'Sur les 24 derniers mois, avez-vous déjà effectué les opérations suivantes :'}>
					
					<div className="user-survey-questions-container">
						<div className="os-flex__column">
							<p className="user-survey-question os-caption">Investissement au capital de sociétés cotées :</p>
							<OsSelect
								formik={formik}
								name="stockInvestments"
								options={investmentFrequencies.map(i => ({
									value: i.value,
									label: i.value
								}))}
							/>
							<span className="error"> {formik.touched.stockInvestments && formik.errors.stockInvestments}</span>
						</div>
						
						<div className="os-flex__column">
							<p className="user-survey-question os-caption">Investissement au capital de sociétés non cotées :</p>
							<OsSelect
								formik={formik}
								name="privateEquityInvestments"
								options={investmentFrequencies.map(i => ({
									value: i.value,
									label: i.value
								}))}
							/>
							<span className="error"> {formik.touched.privateEquityInvestments && formik.errors.privateEquityInvestments}</span>
						</div>
						
						<div className="os-flex__column">
							<p className="user-survey-question os-caption">Capital investissement immobilier :</p>
							<OsSelect
								formik={formik}
								name="capitalInvestment"
								options={investmentFrequencies.map(i => ({
									value: i.value,
									label: i.value
								}))}
							/>
							<span className="error"> {formik.touched.capitalInvestment && formik.errors.capitalInvestment}</span>
						</div>
						
						<div className="os-flex__column">
							<p className="user-survey-question os-caption">Immobilier papier (ex : OPCI, SCPI) :</p>
							<OsSelect
								formik={formik}
								name="reit"
								options={investmentFrequencies.map(i => ({
									value: i.value,
									label: i.value
								}))}
							/>
							<span className="error"> {formik.touched.reit && formik.errors.reit}</span>
						</div>
						
						<div className="os-flex__column">
							<p className="user-survey-question os-caption">OPCVM :</p>
							<OsSelect
								formik={formik}
								name="opcvm"
								options={investmentFrequencies.map(i => ({
									value: i.value,
									label: i.value
								}))}
							/>
							<span className="error"> {formik.touched.opcvm && formik.errors.opcvm}</span>
						</div>
						
						<div className="os-flex__column">
							<p className="user-survey-question os-caption">Investissement en obligations simples de sociétés non cotées :</p>
							<OsSelect
								formik={formik}
								name="simpleObligationInvestment"
								options={investmentFrequencies.map(i => ({
									value: i.value,
									label: i.value
								}))}
							/>
							<span className="error"> {formik.touched.simpleObligationInvestment && formik.errors.simpleObligationInvestment}</span>
						</div>
						
						<div className="os-flex__column">
							<p className="user-survey-question os-caption">Investissement en obligations complexes de sociétés non cotées :</p>
							<OsSelect
								formik={formik}
								name="complexObligationInvestment"
								options={investmentFrequencies.map(i => ({
									value: i.value,
									label: i.value
								}))}
							/>
							<span className="error"> {formik.touched.complexObligationInvestment && formik.errors.complexObligationInvestment}</span>
						</div>
					</div>
				</OsCard>
				
				<OsCard title="Expérience d'investissement" spaced="up">
					<div className="os-flex__column">
						<p className="user-survey-question os-caption">
							Vous avez agi :
							<span className="error"> {formik.touched.actedAs && formik.errors.actedAs}</span>
						</p>
						<div className="user-survey-questions-container">
							{investmentMethods.map(i => (
								<OsRadio
									formik={formik}
									name="actedAs"
									value={i.value}
									label={i.value} />
							))}
						</div>
						
						<p className="user-survey-question os-caption">
							Au cours des 2 dernières années, avez-vous investi plus de 10 000€ en produits d’épargne/financiers ?
							<span className="error"> {formik.touched.twoYearInvestments && formik.errors.twoYearInvestments}</span>
						</p>
						<div className="user-survey-questions-container">
							<OsRadio
								formik={formik}
								name="twoYearInvestments"
								value="Oui"
								label="Oui"
							/>
							<OsRadio
								formik={formik}
								name="twoYearInvestments"
								value="Non"
								label="Non"
							/>
						</div>
						
						<p className="user-survey-question os-caption">
							Au cours des 5 dernières années, avez-vous investi plus de 50 000€ en produits d’épargne/financiers ?
							<span className="error"> {formik.touched.fiveYearInvestments && formik.errors.fiveYearInvestments}</span>
						</p>
						<div className="user-survey-questions-container">
							<OsRadio
								formik={formik}
								name="fiveYearInvestments"
								value="Oui"
								label="Oui"
							/>
							<OsRadio
								formik={formik}
								name="fiveYearInvestments"
								value="Non"
								label="Non"
							/>
						</div>
					</div>
				</OsCard>
				
				<OsCard title="Compréhension des risques" spaced="up">
					<div className="os-flex__column">
						<p className="user-survey-question os-caption">
							Comprenez-vous que l'investissement dans un fonds fermé comporte un
							risque de perte en capital, pouvant potentiellement aboutir à la perte totale de
							votre investissement initial ?
							<span className="error"> {formik.touched.IRA && formik.errors.IRA}</span>
						</p>
						<div className="user-survey-questions-container">
							<OsRadio
								formik={formik}
								name="IRA"
								value="Oui"
								label="Oui"
							/>
							<OsRadio
								formik={formik}
								name="IRA"
								value="Non"
								label="Non"
							/>
						</div>
						
						<p className="user-survey-question os-caption">
							En investissant dans un fonds non coté, mon investissement est-il liquide et
							me sera-t-il remboursé immédiatement ?
							<span className="error"> {formik.touched.liquidityRefund && formik.errors.liquidityRefund}</span>
						</p>
						<div className="user-survey-questions-container">
							<OsRadio
								formik={formik}
								name="liquidityRefund"
								value="Oui"
								label="Oui"
							/>
							<OsRadio
								formik={formik}
								name="liquidityRefund"
								value="Non"
								label="Non"
							/>
						</div>
						
						<p className="user-survey-question os-caption">
							Un fonds non coté aura chaque année des performances financières identiques ?
							<span className="error"> {formik.touched.stockPerformance && formik.errors.stockPerformance}</span>
						</p>
						<div className="user-survey-questions-container">
							<OsRadio
								formik={formik}
								name="stockPerformance"
								value="Oui"
								label="Oui"
							/>
							<OsRadio
								formik={formik}
								name="stockPerformance"
								value="Non"
								label="Non"
							/>
						</div>
					</div>
				</OsCard>
				
				<OsCard title="Durabilité & ESG" spaced="up">
					<span className="error"> {formik.touched.environmentPercentage && formik.errors.environmentPercentage}</span>
					<p className="os-caption">Sur une échelle de 1 à 10, où 1 représente le moins important et 10 le plus important.<br /><br />
						Comment évalueriez-vous l'importance des critères environnementaux, sociaux et de gouvernance (ESG) dans vos décisions d'investissement:</p>
					<p style={{textAlign: 'right'}}>{durabilityScore}/10</p>
					<Slider defaultValue={durabilityScore} min={1} max={10} marks aria-label="Default" valueLabelDisplay="auto" onChange={(e, val) => setDurabilityScore(val)}/>
					<p className="user-survey-question os-caption" style={{marginTop: 40}}>Quel pourcentage de votre investissement souhaitez-vous consacrer dans des activités considérées comme durables sur le plan environnemental par la classification européenne appelée « Taxonomie »:</p>
					<div className="user-survey-questions-container">
						<OsSelect
							formik={formik}
							name="environmentPercentage"
							options={environmentPercentage.map(e => ({
								value: e.value,
								label: e.value
							}))}
						/>
					</div>
					<p className="user-survey-question os-caption">Quel pourcentage de votre investissement souhaitez-vous consacrer dans des « investissements durables » au sens du Règlement européen sur la publication d'informations en matière de durabilité dans le secteur des services financiers:</p>
					<div className="user-survey-questions-container">
						<OsSelect
							formik={formik}
							name="durabilityPercentage"
							options={environmentPercentage.map(e => ({
								value: e.value,
								label: e.value
							}))}
						/>
					</div>
				</OsCard>
				
				<div className="user-survey-btn-container" style={{marginTop: 32}}>
					<OSButton onClick={() => handleStep(false)} variant="no_border" title="Revenir à l'étape précédente" fit size="medium" icon={<ArrowBackIcon />}/>
					<button type="submit" className="user-survey-btn" disabled={submitting}>Continuer</button>
				</div>
				<div className="user-survey-btn-container">
					{Object.keys(formik.errors).length !== 0 && <span className="error">Vous devez répondre à toutes les questions pour pouvoir continuer</span>}
				</div>
			</form>
		</>
	);
};

export default Knowledge;
