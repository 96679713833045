import React, { useState } from "react";
import OsCard from "../../../components/card/basic";
import OSButton from "../../../components/Button";
import Images from "../../../assets/images";

import "./style.scss";

const CgpGuides = () => {
    const ressourceLinks = [
        {id: 1, image: Images.cgp.ressources.first, title: "Maîtrisez Openstone : Le Guide Essentiel pour les CGP", content: "Ce guide vous fournira toutes les informations nécessaires pour naviguer facilement sur la plateforme Openstone et tirer le meilleur parti de ses fonctionnalités pour optimiser la gestion de vos clients.", buttonText: "Consulter", buttonLink: "https://openstone.com/blog/maitrisez-openstone-le-guide-essentiel-pour-les-cgp"},
        {id: 2, image: Images.cgp.ressources.second,title: "Openstone : Votre Portail Vers la Gestion Client Simplifié", content: "Des fonds ultra-performants, généralement ouverts aux investisseurs institutionnels, sont désormais accessibles dès 100 000 euros pour vos clients.", buttonText: "Consulter", buttonLink: "https://openstone.com/blog/openstone-votre-portail-vers-la-gestion-client-simplifie"},
		]
    const [showAll, setShowAll] = useState(false);
    const visibleRessourceLinks = showAll ? ressourceLinks : ressourceLinks.slice(0, 4);
    const hiddenRessourceLinks = ressourceLinks.slice(4);
	
    return (
        <div className="guides-container">
            <p className="os-subtitle1" style={{ marginTop: 48 }}>Nos ressources pour vous </p>
            <div className="os-flex gap-32 ressources-container">
                {visibleRessourceLinks.map((cl, index) => (
                    <OsCard key={index}>
                        <img className="ressources-img" src={cl.image} alt={cl.title} />
                        <p className="os-caption">{cl.content}</p>
                        <div className="os-flex" style={{ justifyContent: "flex-end" }}>
                            <OSButton title={cl.buttonText} link={cl.buttonLink} fit size="small" variant="no_border" target/>
                        </div>
                    </OsCard>
                ))}
            </div>
            {!showAll && ressourceLinks.length > 4 && (
                <div className="os-flex" style={{ justifyContent: "center", marginTop: 16 }}>
                    <OSButton size="large" variant="no_border" title="Voir tous les guides" title="Afficher tout" onClick={() => setShowAll(true)}  />
                </div>
            )}
            {showAll && hiddenRessourceLinks.length > 0 && (
                <div className="os-flex gap-32 ressources-container">
                    {hiddenRessourceLinks.map((cl, index) => (
                        <OsCard key={index}>
                            <img className="ressources-img" src={cl.image} alt={cl.title} />
                            <p className="os-caption">{cl.content}</p>
                            <div className="os-flex" style={{ justifyContent: "flex-end" }}>
                                <OSButton title={cl.buttonText} link={cl.buttonLink} fit size="small" variant="no_border" target />
                            </div>
                        </OsCard>
                    ))}
                </div>
            )}
        </div>
    );
}

export default CgpGuides;
