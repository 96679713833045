import React from "react";

import DashboardIcon from '@mui/icons-material/Dashboard';
import GroupOutlinedIcon from '@mui/icons-material/GroupOutlined';
import FolderIcon from '@mui/icons-material/Folder';

import {ReactComponent as Binoculars} from '../icons/ic_binoculars.svg';
import {ReactComponent as More} from '../icons/ic_more.svg';
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
//import LoyaltyIcon from '@mui/icons-material/Loyalty';


const generalLinks = [
  {
    title: 'Portefeuille',
    link: '/overview',
    icon: <DashboardIcon />
  },
  {
    title: 'Documents',
    link: '/documents',
    icon: <FolderIcon />
  },
  {
    title: 'Parrainage',
    link: '/parrainage',
    icon: <GroupOutlinedIcon />
  }
];

const desktopLinks = [
	{
		title: 'Livret P.',
		link: '/opportunites/livret-p/livretp',
		icon: null
	},
];

const mobileLinks = [
  {
    title: 'Dashboard',
    link: '/overview',
    icon: <DashboardIcon />
  },
  {
    title: 'Documents',
    link: '/documents',
    icon: <FolderIcon />
  },
  {
    title: 'Offres',
    link: '/opportunites',
    icon: <Binoculars />
  },
  {
    title: 'Menu',
    link: '/more',
    icon: <More />
  }
];

export {generalLinks, desktopLinks, mobileLinks};
