import React, { useState, useCallback, useEffect, useMemo } from 'react';
import { Box, Typography } from '@mui/material';
import PersonIcon from '@mui/icons-material/Person';
import BusinessIcon from '@mui/icons-material/Business';
import { toast } from 'react-toastify';

import CompanyOwnerForm from './Owner/CompanyOwnerForm';
import CompanyOwnerPmForm from './Owner/CompanyOwnerPmForm';
import OsCard from '../../../../components/card/basic';
import OSButton from '../../../../components/Button';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import CgpNetwork from '../../../../config/Network/CgpNetwork.ts';
import CompanyNetwork from '../../../../config/Network/CompanyNetwork.ts';
import { getPPValidationSchema, getPMValidationSchema } from '../../../../components/Survey/GeneralInformations/validationSchemas';

const tabs = [
	{ label: 'Personne physique', value: 'User', icon: <PersonIcon /> },
	{ label: 'Personne morale', value: 'Company', icon: <BusinessIcon /> },
];

const OwnerChoice = (props) => {
	const [owners, setOwners] = useState([]);
	const [errors, setErrors] = useState([]);
	const [submitting, setSubmitting] = useState(false);
	const [isLoading, setIsLoading] = useState(true);
	
	// Fonction pour initialiser les propriétaires depuis les données du backend
	const initializeOwners = useCallback((companyOwners) => {
		if (companyOwners && Array.isArray(companyOwners)) {
			return companyOwners.map((owner) => ({
				type: owner.type || 'User', // Ajout d'un type par défaut
				formData: owner || {},      // Initialisation de formData
			}));
		}
		// Retour par défaut si aucun propriétaire
		return [{ type: 'User', formData: {} }];
	}, []);
	
	// Chargement des données initiales
	useEffect(() => {
		setIsLoading(true);
		setOwners(initializeOwners(props.company?.owners));
		setIsLoading(false);
	}, [props.company?.owners, initializeOwners]);
	
	// Mise à jour du type de propriétaire (User/Company)
	const handleUserTypeChange = useCallback((index, value) => {
		setOwners((prevOwners) => {
			const updatedOwners = [...prevOwners];
			updatedOwners[index].type = value;
			return updatedOwners;
		});
	}, []);
	
	// Mise à jour des données de formulaire pour un propriétaire spécifique
	const handleFormDataChange = useCallback((index, data) => {
		setOwners((prevOwners) => {
			const updatedOwners = [...prevOwners];
			if (JSON.stringify(updatedOwners[index].formData) !== JSON.stringify(data)) {
				updatedOwners[index].formData = { ...updatedOwners[index].formData, ...data };
				return updatedOwners;
			}
			return prevOwners;
		});
	}, []);
	
	// Ajouter un nouveau propriétaire
	const addOwner = useCallback(() => {
		setOwners((prevOwners) => [...prevOwners, { type: 'User', formData: {} }]);
	}, []);
	
	// Supprimer un propriétaire existant
	const removeOwner = useCallback((index) => {
		setOwners((prevOwners) => prevOwners.filter((_, i) => i !== index));
	}, []);
	
	// Validation des formulaires avant soumission
	const validateOwnerForms = async (owners) => {
		let hasErrors = false;
		const errorList = [];
		
		for (let index = 0; index < owners.length; index++) {
			const owner = owners[index];
			const schema = owner.type === 'User'
				? getPPValidationSchema()
				: getPMValidationSchema();
			
			try {
				await schema.validate(owner.formData, { abortEarly: false });
				errorList[index] = {};
			} catch (validationErrors) {
				hasErrors = true;
				errorList[index] = validationErrors.inner.reduce((acc, err) => {
					acc[err.path] = err.message;
					return acc;
				}, {});
			}
		}
		
		return { hasErrors, errorList };
	};
	
	// Soumission des données
	const handleSubmit = async () => {
		setSubmitting(true);
		const { hasErrors, errorList } = await validateOwnerForms(owners);
		
		// Gestion des erreurs
		if (hasErrors) {
			setErrors(errorList);
			toast.error('Veuillez corriger les erreurs avant de continuer.');
			setSubmitting(false);
			return;
		}
		
		// Formatage des données pour le backend
		const formattedOwners = owners.map((owner) => ({
			...owner.formData,
			type: owner.type,
		}));
		const payload = { owners: formattedOwners };
		
		// Appel API pour enregistrer les données
		const response = await (props.isCgp
			? CgpNetwork.updateRecord(payload, props.company.id, 'Company')
			: CompanyNetwork.updateCompany(props.company.id, payload));
		
		setSubmitting(false);
		
		if (response) {
			toast.success('Enregistrement réussi!');
			props.handleStep(true);
		} else {
			toast.error('Une erreur est survenue lors de l’enregistrement.');
		}
	};
	
	const tabStyle = (isSelected) => ({
		width: '49%',
		minHeight: '110px',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'center',
		margin: '8px',
		cursor: 'pointer',
		borderRadius: '8px',
		border: isSelected ? '2px solid #5A9496' : '2px solid #EAEDF1',
		backgroundColor: isSelected ? '#F2F5F8' : '#FFFFFF',
		boxShadow: isSelected ? '0 4px 6px rgba(0, 0, 0, 0.1)' : 'none',
	});
	
	const memoizedOwners = useMemo(
		() =>
			owners.map((owner) => ({
				...owner,
				formData: owner.formData || {},
			})),
		[owners]
	);

	if (isLoading) {
		return <Typography>Chargement des données...</Typography>;
	}
	
	return (
		<>
			<div style={{ marginBottom: 32, marginTop: 24 }}>
				<h5 className="os-h5">Dirigeants</h5>
				<p className="os-body1">Ajouter les représentants de la société, physiques et/ou moraux.</p>
				<span className="os-caption error">Tous les champs sont obligatoires.</span>
			</div>
			{memoizedOwners.map((owner, index) => (
				<OsCard
					title={`Dirigeant ${index + 1}`}
					key={index}
					spaced="up"
					linkTitle="Supprimer"
					conditionForLink={index > 0}
					onClick={() => removeOwner(index)}
					icon={<DeleteIcon />}
				>
					<div style={{ display: 'flex', gap: 12 }}>
						{tabs.map((tab) => (
							<Box
								key={tab.value}
								onClick={() => handleUserTypeChange(index, tab.value)}
								sx={tabStyle(owner.type === tab.value)}
							>
								{tab.icon}
								<Typography>{tab.label}</Typography>
							</Box>
						))}
					</div>
					
					{owner.type === 'User' ? (
						<CompanyOwnerForm
							owner={owner}
							onFormChange={(data) => handleFormDataChange(index, data)}
							errors={errors[index] || {}}
						/>
					) : (
						<CompanyOwnerPmForm
							owner={owner}
							onFormChange={(data) => handleFormDataChange(index, data)}
							errors={errors[index] || {}}
						/>
					)}
				</OsCard>
			))}
			
			<div style={{ marginTop: 24 }}>
				<OSButton
					variant="primary-full"
					size="large"
					onClick={addOwner}
					title="Ajouter un dirigeant"
					fit
					icon={<AddIcon />}
				/>
			</div>
			
			<div
				className="os-flex gap-16"
				style={{ marginTop: 32, justifyContent: 'flex-end', alignItems: 'center' }}
			>
				<OSButton
					onClick={() => props.handleStep(false)}
					variant="no_border"
					title="Revenir à l'étape précédente"
					fit
					size="medium"
					icon={<ArrowBackIcon />}
					disabled={submitting}
					submitting={submitting}
				/>
				<OSButton
					variant="primary"
					size="large"
					onClick={handleSubmit}
					title="Continuer"
					fit
					style={{ marginTop: '16px' }}
					disabled={submitting}
					submitting={submitting}
				/>
			</div>
		</>
	);
};

export default OwnerChoice;
